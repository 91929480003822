import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { hashParams, modalValues } from "routes";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import DeferredPromise from "utilities/DeferredPromise";
import {
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  PercentageCellData,
  cellType,
  getGridDataFromPortfolio
} from "components/grid/GridDataModel";
import {
  updateCustodian,
  getUuid,
  insertCustodian,
  insertSection,
  updateSection,
  updateSheet,
  fetchPortfolioPendingSelector,
  lastDashboardUpdateTsSelector,
  dashboardEntitesToUpdateSelector,
  currentPortfolioSelector,
  archiveCustodian,
  bulkChangeCustodianStarStatus,
  bulkChangeCustodianUpdatedStatus,
  bulkActionSource,
  archiveSection,
  archiveSheet,
  insertSheet,
  unlinkAccountWithCustodian,
  unlinkAllConnectedCustodians,
  ApiClient,
  userPreferencesSelector,
  updateUserPreferences,
  showToastTip,
  hasUserLinkedAnyAccounts,
  moveCustodian,
  deleteCustodian,
  deleteCustodianAction,
  getTickerUsingId,
  tickerTypes,
  insertCustodianAtEndOfSection,
  accountLinkingService,
  userCountryNameSelector,
  getHashParams,
  canShowWhatsNewTip,
  latestWhatsNewTipVersion,
  latestWhatsNewTip,
  isInitialTipShown,
  getCustodianLastUpdateDetails,
  shouldShowLinkingErrorForCustodian,
  custodiansLinkedToSameAccount,
  hasUserMadeAnyManualEntries,
  accountCurrentTsSelector,
  accountEndTsSelector,
  accountGraceTsSelector,
  accountStartTsSelector,
  accountSubscriptionStatusSelector,
  accountClientCurrentTsSelector,
  accountSubscriptionIsActiveSelector,
  store,
  dismissToastAction,
  shouldShowHoldingsExpansionTipForCustodian,
  fetchCustodianTickerDetails,
  connectedLinkTypeSelector,
  setLinkType,
  isAppInViewMode,
  isAppInWhiteLabelMode,
  isMobile,
  isReadOnlyWlClient,
  currentPortfolioCurrencySelector,
  custodianLinkingHintWaitTime,
  siteConfigSelector,
  irrTypes,
  calcCustodianOwnershipValue,
  getExchangeRate,
  custodianSelector,
  fetchPortfolioChangeData,
  currentPortfolioChangeDataSelector,
  isCryptoLinkingService,
  custodiansWithSameParentIdSelector,
  getOwnershipValueForCustodian,
  tickerSubTypes,
  getUnfundedCommitmentForCustodian,
  accountLinkingContainers,
  getSortKeyBetween,
  getTickerUsingShortName,
  userMaskAllValuesSelector,
  isCustodianAddedToday,
  connectivityCenterDataForPortfolioSelector,
  unlinkAndManuallyTrackAllConnectedCustodians,
  getConnectivityCenterData,
  sectionSelector,
  sheetSelector,
  isInternalUserSelector,
  updateCustodianLocal,
  blockDetailsLoading,
  unblockDetailsLoading,
  REPORTING_TAB_RELEASE_TS,
  userSelector
} from "@kubera/common";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import LinkAccountComponentExports, { linkAccountMode } from "components/link_account/LinkAccountComponentExports";
import ArchivedComponent from "components/archived/ArchivedComponent";
import {
  contextMenuItemType,
  getLinkContextMenuItem,
  getUpdateValuesContextMenuItem,
  getTickerDescriptionContextMenuItem,
  getManagedAssetContextMenuItem,
  getLinkedAccountDescriptionMenuItem,
  getStartOverContextMenuItem
} from "components/contextmenu/ContextMenu";
import { downloadFile } from "utilities/FileUtils";
import EmptyGridComponent from "components/grid/EmptyGridComponent";
import SheetUpdateFrequencyDialog from "components/grid/SheetUpdateFrequencyDialog";
import ToolTip, { toolTipAlignment } from "components/tooltip/ToolTip";
import PickLinkAccountCountry from "components/link_account/PickLinkAccountCountry";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import LinkAccountCommonExports from "components/link_account/LinkAccountCommonExports";
import LinkAccountFirstTimeUserDialog from "components/link_account/LinkAccountFirstTimeUserDialog";
import { category } from "components/dashboard/DashboardComponentExports";
import CustodianDetailsWrapperComponent from "components/custodian_details/CustodianDetailsWrapperComponent";
import MobileAssetsWrapperComponent from "./MobileAssetsWrapperComponent";
import EmptyGridViewAssetsComponent from "./EmptyGridViewAssetsComponent";
import CurrencyLabel from "components/labels/CurrencyLabel";
import { detailsTabs } from "components/custodian_details/CustodianDetailsComponentExports";
import PercentageLabel from "components/labels/PercentageLabel";
import GridPageHandleSheetIdHash from "components/grid/GridPageHandleSheetIdHash";
import withInitialFetchHandle from "utilities/withInitialFetchHandle";
import AddModal from "./AddModal";
import MoveItemDialog, { moveItemType } from "components/grid/MoveItemDialog";
import PVSTConnectModal from "components/link_account/PVSTConnectModal";

const isMobileDevice = isMobile();

const Container = styled.div`
  width: 100%;
  padding-bottom: 44px;
`;

const Grid = styled(GridComponentWrapper)`
  width: 100%;
`;

const EmptyGridMessage = styled(EmptyGridComponent)`
  margin-top: 50px;
`;

const FirstTimeLinkingDialog = styled(LinkAccountFirstTimeUserDialog)`
  width: 611px;
`;

const IrrSummaryCell = styled.div`
  display: flex;
  align-items: center;
  z-index: 100;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  text-align: right;
  font-feature-settings: "ss01" on;
  cursor: pointer;
`;

const IrrCellLabel = styled.div`
  display: flex;
  margin-left: ${props => (props.applyMargin === true ? "5px" : "")};
  color: ${props => props.theme.irrCellLabelCLR};
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
`;

const IrrCellValue = styled(CurrencyLabel)`
  display: flex;
  color: ${props => props.theme.irrCellValueCLR};
  margin-left: 5px;
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
`;

const FooterCostLabel = styled(IrrCellLabel)`
  color: white;
  font-style: normal;
`;

const FooterCostCell = styled(IrrSummaryCell)`
  margin-right: -5px;
  cursor: text;
`;

const FooterCostValue = styled(IrrCellValue)`
  color: white;
  margin-right: 13px;
  font-style: normal;
`;

const FooterPercentageChange = styled(PercentageLabel)`
  min-width: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

class AssetsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gridData: this.getGridData(props.portfolio),
      hideEmptyState: false,
      showUnlinkAllConnectionsDialog: false,
      unlinkDialogRow: null,
      showFirstTimeLinkAccountDialog: false,
      connectToolTipTargetId: null,
      reconnectDialogRow: null,
      showReconnectDialog: false,
      showDisconnectAndTrackManuallyDialog: false,
      rowToDisconnectAndTrackManually: null,
      isRemoveFlow: false,
      moveDialogForData: null
    };
    this.whatsNewToolTipRef = React.createRef();
    this.connectAccountToolTipRef = React.createRef();
    this.accountNameToolTipRef = React.createRef();
    this.addNewToolTipRef = React.createRef();

    this.handleInitialTipClick = this.handleInitialTipClick.bind(this);
    this.getEmptyRow = this.getEmptyRow.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);
    this.handleAddNewSection = this.handleAddNewSection.bind(this);
    this.handleSectionUpdate = this.handleSectionUpdate.bind(this);
    this.handleSheetUpdate = this.handleSheetUpdate.bind(this);
    this.handleDetailsClick = this.handleDetailsClick.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.handleToggleSectionStarStatus = this.handleToggleSectionStarStatus.bind(this);
    this.handleToggleSectionUpdatedStatus = this.handleToggleSectionUpdatedStatus.bind(this);
    this.handleToggleSheetUpdatedStatus = this.handleToggleSheetUpdatedStatus.bind(this);
    this.handleToggleSheetStarStatus = this.handleToggleSheetStarStatus.bind(this);
    this.handleRemoveSection = this.handleRemoveSection.bind(this);
    this.handleRemoveSheet = this.handleRemoveSheet.bind(this);
    this.getEmptySheet = this.getEmptySheet.bind(this);
    this.handleAddNewSheet = this.handleAddNewSheet.bind(this);
    this.handleUserLeavingCellEmpty = this.handleUserLeavingCellEmpty.bind(this);
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
    this.handleGridContextMenuSelection = this.handleGridContextMenuSelection.bind(this);
    this.handleSheetContextMenuSelection = this.handleSheetContextMenuSelection.bind(this);
    this.handleSectionContextMenuSelection = this.handleSectionContextMenuSelection.bind(this);
    this.handleReorderRowsForSections = this.handleReorderRowsForSections.bind(this);
    this.handleOptionsClick = this.handleOptionsClick.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.handleSheetSelection = this.handleSheetSelection.bind(this);
    this.handleSectionMove = this.handleSectionMove.bind(this);
    this.handleAddNewFooterButtonClick = this.handleAddNewFooterButtonClick.bind(this);
    this.handleUnlinkAllConnectionsDialogPositiveButtonClick = this.handleUnlinkAllConnectionsDialogPositiveButtonClick.bind(
      this
    );
    this.handleUnlinkAllConnectionsDialogOnDismiss = this.handleUnlinkAllConnectionsDialogOnDismiss.bind(this);
    this.handleReconnectDialogPositiveButtonClick = this.handleReconnectDialogPositiveButtonClick.bind(this);
    this.handleReconnectDialogNegativeButtonClick = this.handleReconnectDialogNegativeButtonClick.bind(this);
    this.handleReconnectDialogDismissButtonClick = this.handleReconnectDialogDismissButtonClick.bind(this);
    this.getPlainGridData = this.getPlainGridData.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleFirstTimeLinkingDialogButton = this.handleFirstTimeLinkingDialogButton.bind(this);
    this.handleLinkErrorClick = this.handleLinkErrorClick.bind(this);
    this.dismissStockUpdateToolTip = this.dismissStockUpdateToolTip.bind(this);
    this.showConnectToolTip = this.showConnectToolTip.bind(this);
    this.handleConnectPositiveClick = this.handleConnectPositiveClick.bind(this);
    this.handleIrrCellClick = this.handleIrrCellClick.bind(this);
    this.handleDisconnectAndTrackManuallyDialogPositiveButtonClick = this.handleDisconnectAndTrackManuallyDialogPositiveButtonClick.bind(
      this
    );
    this.handleDisconnectAndTrackManuallyDialogOnDismiss = this.handleDisconnectAndTrackManuallyDialogOnDismiss.bind(
      this
    );
    this.handleMoveDialogDismiss = this.handleMoveDialogDismiss.bind(this);

    this.custodianRefreshTsMap = {};
    this.initialTipsRowIndex = 0;
    this.CustodianDetailsWrapperRef = React.createRef();
    this.connectToolTipRef = React.createRef();
    this.connectedLinkTypeToolTipRef = React.createRef();
    this.connectedLinkTypeOpenRef = React.createRef();
    this.addModalRef = React.createRef();
    this.gridDataRef = React.createRef();
    this.isComingFromEmptyScreenRef = React.createRef(false);
    this.deleteConfirmationPromise = new DeferredPromise();
    this.updateTimeoutId = null;
  }

  isReadOnly() {
    return isAppInViewMode() === true || this.props.portfolio.write === 0;
  }

  componentDidMount() {
    this.showLinkAccountTipIfNeeded();

    setTimeout(() => {
      this.showAccountNameTipIfNeeded();
      if (this.props.fetchPortfolioPending === false) {
        this.showLatestWhatsNewTipIfNeeded();
      }
    }, 500);
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.portfolio.details !== this.props.portfolio.details ||
      oldProps.portfolioChangeData !== this.props.portfolioChangeData
    ) {
      this.setState({ gridData: this.getGridData(this.props.portfolio), hideEmptyState: false });
    }

    if (this.state.gridData.currency !== this.props.portfolio.currency) {
      this.showMultiCurrencyTipIfNeeded();
    }

    if (
      this.state.gridData.currency !== this.props.portfolio.currency ||
      oldProps.accountClientCurrentTs !== this.props.accountClientCurrentTs ||
      oldProps.userPreferences.isFundScheduleTipShown !== this.props.userPreferences.isFundScheduleTipShown ||
      oldProps.userPreferences.isReportingTabTipShown !== this.props.userPreferences.isReportingTabTipShown
    ) {
      this.setState({ gridData: this.getGridData(this.props.portfolio) });
    }

    if (oldProps.lastDashboardUpdateTs !== this.props.lastDashboardUpdateTs) {
      const entitiesToUpdate = this.props.dashboardEntitesToUpdate;
      const newGridData = this.getGridData(this.props.portfolio, this.state.gridData, entitiesToUpdate);
      if (oldProps.portfolio.id !== this.props.portfolio.id) {
        newGridData.currentSheetIndex = 0;
      }
      newGridData.id = this.state.gridData.id;

      this.showConnectToolTip();

      if (newGridData.isEmpty() === false && newGridData.numberOfRowsWithLinkingInProgress() === 0) {
        const userPreferences = this.props.userPreferences;
        if (userPreferences.isAssetInitialTipShownForPortfolio.includes(this.props.portfolio.id) === false) {
          this.props.updateUserPreferences({
            isAssetInitialTipShownForPortfolio: [
              ...userPreferences.isAssetInitialTipShownForPortfolio,
              this.props.portfolio.id
            ]
          });
        }
      }

      if (this.state.showFirstTimeLinkAccountDialog === true && newGridData.numberOfRowsWithLinkingInProgress() === 0) {
        this.setState({
          gridData: newGridData,
          showFirstTimeLinkAccountDialog: false,
          hideEmptyState: newGridData.isEmpty() === false
        });
      } else {
        this.setState({ gridData: newGridData, hideEmptyState: newGridData.isEmpty() === false });
      }
    }

    if (oldProps.fetchPortfolioPending === true && this.props.fetchPortfolioPending === false) {
      this.showLatestWhatsNewTipIfNeeded();
    }

    // If the user is coming from link account flow
    // check whether we need to show the onboarding tips
    const previousModal = getHashParams(oldProps.location) ? getHashParams(oldProps.location)[hashParams.MODAL] : null;
    const currentModal = getHashParams(this.props.location)
      ? getHashParams(this.props.location)[hashParams.MODAL]
      : null;
    const userPreferences = this.props.userPreferences;

    if (
      previousModal &&
      (previousModal === modalValues.LINK_ACCOUNT ||
        previousModal === modalValues.CONNECT_STOCK ||
        previousModal === modalValues.CONNECT_CRYPTO ||
        previousModal === modalValues.CONNECT_METAL ||
        previousModal === modalValues.CONNECT_EXTRAS ||
        previousModal === modalValues.CONNECT_HOMES ||
        previousModal === modalValues.CONNECT_CARS ||
        previousModal === modalValues.CONNECT_DOMAINS ||
        previousModal === modalValues.CONNECT_PORTFOLIOS ||
        previousModal === modalValues.QTY_AND_PRICE_CONNECT ||
        previousModal === modalValues.PICK_LINK_COUNTRY) &&
      !currentModal === true
    ) {
      setTimeout(() => {
        if (this.state.gridData.isEmpty() === false) {
          if (
            previousModal === modalValues.LINK_ACCOUNT &&
            this.state.gridData.numberOfRowsWithLinkingInProgress() > 0 &&
            userPreferences.canShowFirstTimeLinkingDialog === true &&
            userPreferences.isFirstTimeLinkingDialogShown === false
          ) {
            this.setState({ showFirstTimeLinkAccountDialog: true });
          }

          this.showAddNewTipIfNeeded();
        } else {
          this.setState({ hideEmptyState: false });
          setTimeout(() => {
            this.showAccountNameTipIfNeeded();
          }, 500);
        }
      }, 10);
    }
  }

  get unlinkProviderName() {
    const { linkProviderName, linkType } = this.state.unlinkDialogRow;

    if (!linkProviderName) {
      switch (true) {
        case linkType === 6:
          return "zillow";
        default:
          return "";
      }
    }

    return linkProviderName;
  }

  getPlainGridData(category) {
    return getGridDataFromPortfolio(
      this.props.portfolio,
      category,
      this.props.portfolio.currency,
      (sheet, sections) => {
        const currentSheet = this.getEmptySheet(sheet.sortKey);
        currentSheet.id = sheet.id;
        currentSheet.name = sheet.name;
        currentSheet.sections = sections;
        currentSheet.category = sheet.category;
        return currentSheet;
      },
      (index, section, rows) => {
        const currentSection = this.getEmptySection(index, section.sortKey, this.isReadOnly());
        currentSection.id = section.id;
        currentSection.name = section.name;
        if (rows.length > 0) {
          currentSection.rows = rows;
        }
        return currentSection;
      },
      custodian => {
        const currentRow = this.getEmptyRow(custodian.sortKey, this.isReadOnly());
        return currentRow;
      }
    );
  }

  getGridData(portfolio, currentGridData = null, entitiesToUpdate = []) {
    const gridData = getGridDataFromPortfolio(
      portfolio,
      category.ASSET,
      portfolio.currency,
      (sheet, sections) => {
        const currentSheet = this.getEmptySheet(sheet.sortKey);
        currentSheet.id = sheet.id;
        currentSheet.name = sheet.name;
        currentSheet.sections = sections;
        currentSheet.category = sheet.category;
        currentSheet.irr = sheet.irr;
        currentSheet.showCost = sheet.showCost;
        return currentSheet;
      },
      (index, section, rows) => {
        const currentSection = this.getEmptySection(index, section.sortKey, this.isReadOnly());
        currentSection.id = section.id;
        currentSection.name = section.name;
        currentSection.columnSortKey = section.columnSortKey;
        currentSection.columnSortOrder = section.columnSortOrder;
        currentSection.irr = section.irr;
        currentSection.showCost = section.showCost;
        if (rows.length > 0) {
          currentSection.rows = rows;
        }
        return currentSection;
      },
      custodian => {
        const currentRow = this.getEmptyRow(custodian.sortKey, this.isReadOnly());
        currentRow.id = custodian.id;
        currentRow.isLinked =
          (this.props.accountSubscriptionIsActive || this.isReadOnly()) && !custodian.linkType === false;
        currentRow.isVerified = custodian.isVerified === 1 && this.props.siteConfig.showVerified === "show";
        currentRow.linkType = custodian.linkType;
        currentRow.linkedHoldingsCount = custodian.holdingsCount;
        currentRow.linkStatus = custodian.status;
        currentRow.linkStatusInfo = custodian.statusInfo;
        currentRow.linkContainer = custodian.linkContainer;
        currentRow.linkProviderName = custodian.linkProviderName;
        currentRow.linkAccountName = custodian.linkAccountName;
        currentRow.linkAccountMask = custodian.linkAccountMask;
        currentRow.isLinking = this.props.accountSubscriptionIsActive && custodian.isLinking;
        currentRow.linkingFailed = custodian.linkingFailed;
        currentRow.linkingAccountsData = custodian.linkingAccountsData;
        currentRow.valueTickerId = custodian.valueTickerId;
        currentRow.isInvestable = this.props.isReadOnlyWlClient || (custodian.type === 0 || custodian.type === 2);
        currentRow.isManaged = this.props.isReadOnlyWlClient || !custodian.aum === false;
        currentRow.linkAccountId = custodian.linkAccountId;
        currentRow.tsStart = custodian.tsStart;
        currentRow.linkProviderAccountId = custodian.linkProviderAccountId;
        currentRow.availableCredit = custodian.availableCredit;
        currentRow.irrType = custodian.irrType;
        currentRow.irr = custodian.irr;
        currentRow.actualValue = custodian.value;
        currentRow.linkProviderId = custodian.linkProviderId;

        const linkedAccountDescription = currentRow.getLinkedAccountDescription();
        currentRow.cells[1].value = custodian.name;
        currentRow.cells[1].toolTip = linkedAccountDescription ? `Connected: ${linkedAccountDescription}` : null;

        currentRow.cells[1].description = custodian.description;
        currentRow.cells[1].url = custodian.url;
        currentRow.cells[1].linkType = custodian.linkType;
        currentRow.cells[1].subType = custodian.subType;
        currentRow.cells[1].currencyCellValue = calcCustodianOwnershipValue(custodian.value, custodian.ownership);
        currentRow.cells[1].relatedId = custodian.relatedId;
        currentRow.cells[1].unfundedCapital = this.props.getUnfundedCommitmentForCustodian(custodian);
        if (custodian.isLinking && custodian.linkType === accountLinkingService.ZERION) {
          currentRow.cells[1].description = i18n.t("connecting");
        } else if (this.props.accountSubscriptionIsActive && custodian.isRefreshing === true) {
          clearTimeout(this.updateTimeoutId);
          this.updateTimeoutId = setTimeout(() => {
            this.isUpdateClicked = false;
          }, 300);
          if (this.isUpdateClicked && shouldShowLinkingErrorForCustodian(custodian) === false) {
            currentRow.cells[4] = new GridCellData(cellType.LOADER, "", null);
          }
        } else if (this.props.accountSubscriptionIsActive && custodian.isLinking === true) {
          if (!custodian.linkingAccountsData === false) {
            currentRow.cells[1].description = i18n.t("connected");
          } else if (custodian.linkingTs && new Date().getTime() - custodian.linkingTs > custodianLinkingHintWaitTime) {
            currentRow.cells[1].description = i18n.t("connectingStill");
          } else {
            currentRow.cells[1].description = !custodian.isUnlinkFlow ? i18n.t("connecting") : "";
          }
        }

        currentRow.cells[3].value = custodian.value;
        currentRow.cells[3].allowPVST = true;
        currentRow.cells[3].ownership = this.props.getOwnershipValueForCustodian(custodian.id);
        currentRow.cells[3].invalidInputText = custodian.valueInvalidInputText;
        currentRow.cells[3].tickerInfo = custodian.valueTickerInfo;
        currentRow.cells[3].valueTickerId = custodian.valueTickerId;
        currentRow.cells[3].rate = custodian.rate;
        currentRow.cells[3].isEditable =
          (currentRow.isLinked === false ||
            [accountLinkingService.ZILLOW, accountLinkingService.DOMAINS, accountLinkingService.CARS].includes(
              custodian.linkType
            ) ||
            (custodian.parentId !== null &&
              [
                accountLinkingService.ZERION,
                accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
                accountLinkingService.IN_HOUSE_CRYPTO_API
              ].includes(custodian.linkType) &&
              custodian.linkContainer === accountLinkingContainers.NFT)) &&
          this.props.maskAllValues === false;

        if (custodian.linkType === accountLinkingService.ZILLOW) {
          currentRow.cells[3].loading = custodian.isInitialRefresh;
        } else {
          currentRow.cells[3].loading = custodian.isLinking === true || custodian.fetchingValueTickerInfo === true;
        }

        currentRow.cells[3].pastValueDetails = custodian.pastValue;
        currentRow.cells[3].canShowDayChange = currentRow.linkType !== accountLinkingService.ZILLOW;

        if (custodian.valueTickerId) {
          currentRow.cells[1].currency = this.props.getTickerUsingId(custodian.valueTickerId).shortName;
          currentRow.cells[3].currency = this.props.getTickerUsingId(custodian.valueTickerId).shortName;
        }
        if (custodian.valueExchangeRate) {
          currentRow.cells[3].exchangeRateDetails = custodian.valueExchangeRate;
        }

        if (custodian.rate && custodian.valueTickerId === 171) {
          const rateParsed = JSON.parse(custodian.rate);
          currentRow.cells[3].useRateFromExchangeRateDetails = true;
          currentRow.cells[3].exchangeRateDetails = `{"tickerId":${rateParsed.t},"rate":${rateParsed.p}}`;
        }

        if (custodian.costTickerId) {
          currentRow.cells[3].costTickerId = custodian.costTickerId;
        }
        if (custodian.costExchangeRate) {
          currentRow.cells[3].costExchangeRate = custodian.costExchangeRate;
        }
        if (
          !(
            custodian.irrType === irrTypes.COSTBASIS &&
            custodian.costType === "auto" &&
            currentRow.cells[3].ownership !== 100
          )
        ) {
          currentRow.cells[3].cost = custodian.cost;
          currentRow.cells[2].value = currentRow.getIrr();
        }

        if (custodian.isLinking === true) {
          var linkingCell = new GridCellData(cellType.LOADER, "", null);
          if (custodian.linkingFailed === true) {
            linkingCell = new GridCellData(cellType.LINKING_FAILURE, "", null);
          }

          currentRow.cells[4] = linkingCell;
        } else if (currentRow.isLinked === true && shouldShowLinkingErrorForCustodian(custodian) === true) {
          const linkErrorCell = new GridCellData(cellType.LINK_ERROR, "", null);
          linkErrorCell.toolTip = i18n.t("gridCell.linkErrorToolTip");
          currentRow.cells[5] = linkErrorCell;
          currentRow.cells[5].isReadOnly = this.isReadOnly();
        } else if (!custodian.valueTickerInfo === false) {
          const tickerErrorCell = new GridCellData(cellType.TICKER_ERROR, "", null);
          currentRow.cells[4] = tickerErrorCell;
        } else if (currentRow.isLinked === true) {
          if (this.isReadOnly() === true) {
            currentRow.cells[4].showHint = false;
          } else if (shouldShowHoldingsExpansionTipForCustodian(store.getState(), custodian.id) === true) {
            currentRow.cells[4].showHint = true;
          } else if (
            this.props.userPreferences.showRevertHoldingsTipForCustodianId === custodian.id &&
            this.props.userPreferences.isRevertHoldingsTipShown === false
          ) {
            currentRow.cells[4].showHint = true;
          }
        }

        if (this.props.userPreferences.isFundScheduleTipShown === false && !custodian.cmtdCap === false) {
          currentRow.cells[4].showHint = true;
        }
        if (
          this.props.userPreferences.isReportingTabTipShown === false &&
          (custodian.isManual === true || custodian.valueTickerId === 171) &&
          this.props.user &&
          this.props.user.tsCreated * 1000 < REPORTING_TAB_RELEASE_TS
        ) {
          currentRow.cells[4].showHint = true;
        }
        return currentRow;
      },
      currentGridData,
      entitiesToUpdate
    );

    if (!currentGridData === false) {
      gridData.currentSheetIndex = currentGridData.currentSheetIndex;
    }

    gridData.isEditable = this.isReadOnly() === false && this.props.maskAllValues === false;
    gridData.forceShowArchivedItems = this.props.isReadOnlyWlClient;
    gridData.onTotalChange = () => {
      this.props.fetchPortfolioChangeData(this.props.portfolio.id);
    };
    return gridData;
  }

  getEmptySheet(sortKey) {
    const sheetData = new GridSheetData(getUuid(), sortKey, null, []);

    sheetData.getNewActionContextMenuItems = () => {
      return [
        [contextMenuItemType.NEW_ACTION_SECTION],
        [
          {
            ...contextMenuItemType.NEW_ACTION_SECTION_ROW,
            label: contextMenuItemType.NEW_ACTION_SECTION_ROW.label.replace(/%s%/, category.ASSET)
          }
        ]
      ];
    };

    return sheetData;
  }

  getEmptySection = (forIndex, sortKey, isViewOnlyMode = false) => {
    const padColumn = new GridColumnData(null, false, false, true);
    const nameColumn = new GridColumnData("Asset", true, isViewOnlyMode === false, false);
    const irrColumn = new GridColumnData("IRR", true, false, false);
    irrColumn.hideTitleIfEmpty = true;
    irrColumn.getFooterAccessoryView = (sheetIndex, sectionIndex, columnIndex) => {
      const section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];

      const irrDetails = section.rows.length === 1 ? section.rows[0].getIrrDetails() : section.getIrrDetails();
      if (irrDetails) {
        const cashTicker = this.props.getTickerUsingId(irrDetails.all.cashTickerId);
        const cashExchangeRate = getExchangeRate(cashTicker.shortName, this.props.portfolio.currency);
        const totalCashIn = irrDetails.all.cashIn * cashExchangeRate;
        const totalCashOut = irrDetails.all.cashOut * cashExchangeRate;

        if (!totalCashIn === true && !totalCashOut === true) {
          return null;
        }
        return (
          <FooterCostCell>
            {!totalCashIn === false && (
              <>
                <FooterCostLabel>{section.showCost ? i18n.t("cost") : i18n.t("in")}</FooterCostLabel>
                <FooterCostValue
                  value={totalCashIn}
                  currency={this.props.portfolio.currency}
                  roundDown={true}
                  maxLongFormatValue={999999}
                />
              </>
            )}
            {!totalCashOut === false && (
              <>
                <FooterCostLabel>{i18n.t("out")}</FooterCostLabel>
                <FooterCostValue
                  value={totalCashOut}
                  currency={this.props.portfolio.currency}
                  roundDown={true}
                  maxLongFormatValue={999999}
                />
              </>
            )}
            <FooterPercentageChange
              value={
                irrDetails.all.value > -1 && irrDetails.all.value < 1
                  ? irrDetails.all.value.toFixed(2)
                  : Math.kuberaFloor(irrDetails.all.value)
              }
              darkBackground={true}
            />
          </FooterCostCell>
        );
      }
    };

    const valueColumn = new GridColumnData("Value", true, isViewOnlyMode === false, false);
    const detailsColumn = new GridColumnData(null, true, false, true);
    const optionsColumn = new GridColumnData(null, true, false, true);

    var columns = [padColumn, nameColumn, irrColumn, valueColumn, detailsColumn, optionsColumn];
    if (isViewOnlyMode === true) {
      columns = [padColumn, nameColumn, irrColumn, valueColumn, detailsColumn, padColumn];
    }

    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [
        this.getEmptyRow("1", this.isReadOnly()),
        this.getEmptyRow("2", this.isReadOnly()),
        this.getEmptyRow("3", this.isReadOnly())
      ],
      columns,
      undefined,
      3,
      false
    );
    sectionData.getContextMenuItems = section => {
      return [[contextMenuItemType.RENAME, contextMenuItemType.MOVE_SECTION, contextMenuItemType.REMOVE_SECTION]];
    };
    sectionData.getFooterContextMenuItems = () => {
      return null;
    };
    return sectionData;
  };

  getEmptyRow(sortKey, isViewOnlyMode = false) {
    const detailsCell = new GridCellData(cellType.DETAILS, "", null);
    detailsCell.showHint = false;
    detailsCell.toolTip = i18n.t("gridCell.detailsButtonToolTip");

    const nameCell = new GridCellData(cellType.TEXT, category.ASSET, null);
    const irrCell = new PercentageCellData(cellType.PERCENTAGE, "IRR", null, undefined, undefined);
    irrCell.onClick = (e, sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      this.handleIrrCellClick(e, sheetIndex, sectionIndex, rowIndex);
    };
    irrCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];

      if (row.irrType === irrTypes.COSTBASIS) {
        const cost = row.cells[3].getCostInCurrency(this.props.portfolio.currency);
        if (!cost === true) {
          return null;
        }
        return (
          <IrrSummaryCell>
            <IrrCellLabel updated={row.isUpdated}>{i18n.t("cost")}</IrrCellLabel>
            <IrrCellValue
              updated={row.isUpdated}
              value={cost}
              currency={this.props.portfolio.currency}
              roundDown={true}
              maxLongFormatValue={999999}
            />
          </IrrSummaryCell>
        );
      } else if (row.irrType === irrTypes.CASHFLOW || row.irrType === irrTypes.HOLDING) {
        const irrDetails = row.getIrrDetails();
        if (!irrDetails === true) {
          return null;
        }

        const cashTicker = this.props.getTickerUsingId(irrDetails.all.cashTickerId);
        const cashExchangeRate = getExchangeRate(cashTicker.shortName, this.props.portfolio.currency);
        const totalCashIn = irrDetails.all.cashIn * cashExchangeRate;
        const totalCashOut = irrDetails.all.cashOut * cashExchangeRate;

        if (!totalCashIn === true && !totalCashOut === true) {
          return null;
        }
        return (
          <IrrSummaryCell>
            {!totalCashIn === false && (
              <>
                <IrrCellLabel updated={row.isUpdated}>{i18n.t("in")}</IrrCellLabel>
                <IrrCellValue
                  updated={row.isUpdated}
                  value={totalCashIn}
                  currency={this.props.portfolio.currency}
                  roundDown={true}
                  maxLongFormatValue={999999}
                />
              </>
            )}
            {!totalCashOut === false && (
              <>
                <IrrCellLabel updated={row.isUpdated} applyMargin={!totalCashIn === false}>
                  {i18n.t("out")}
                </IrrCellLabel>
                <IrrCellValue
                  updated={row.isUpdated}
                  value={totalCashOut}
                  currency={this.props.portfolio.currency}
                  roundDown={true}
                  maxLongFormatValue={999999}
                />
              </>
            )}
          </IrrSummaryCell>
        );
      }
    };

    const valueCell = new CurrencyCellData(cellType.CURRENCY, "Value", null, this.props.portfolio.currency);
    valueCell.supportedTickerTypes = [
      tickerTypes.FIAT,
      tickerTypes.CRYPTO,
      tickerTypes.STOCK,
      tickerTypes.FUND,
      tickerTypes.BOND,
      tickerTypes.DERIVATIVE,
      tickerTypes.INDEX
    ];
    const padCell = new GridCellData(cellType.PADDING, "", null);
    padCell.toolTip = i18n.t("gridCell.starButtonToolTip");
    padCell.padding = "0 14px 0 0";
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    optionsCell.toolTip = i18n.t("gridCell.optionsButtonToolTip");

    var cells = [padCell, nameCell, irrCell, valueCell, detailsCell, optionsCell];
    if (isViewOnlyMode === true || this.props.maskAllValues === true) {
      const rightPadCell = new GridCellData(cellType.PADDING, "", null);
      rightPadCell.padding = "0 14px 0 0";
      cells = [padCell, nameCell, irrCell, valueCell, detailsCell, rightPadCell];
    }

    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => {
      const name = cells[1].value;
      const value = cells[3].value;

      if (name && name.length > 0 && (value !== null && value !== undefined)) {
        return true;
      }
      return false;
    });

    rowData.getContextMenuItems = row => {
      if (isViewOnlyMode === true || this.props.maskAllValues === true) {
        return [];
      }

      this.dismissStockUpdateToolTip();

      let removeOption = contextMenuItemType.REMOVE;
      if (!isCustodianAddedToday(row.id)) {
        removeOption = contextMenuItemType.ARCHIVE;
      }
      removeOption = [{ ...removeOption, description: row.getLinkedAccountRemoveDescription() }];

      const isErrorCellShown = row.cells[5].type === cellType.LINK_ERROR;

      if (!row.isComplete()) {
        const items = [[contextMenuItemType.INSERT_ABOVE, ...removeOption]];
        if (!row.isLinking === true) {
          if (this.props.isReadOnlyWlClient) {
            items.push([
              contextMenuItemType.LINK_CRYPTO,
              contextMenuItemType.CONNECT_STOCK,
              contextMenuItemType.CONNECT_CRYPTO,
              contextMenuItemType.CONNECT_METAL,
              contextMenuItemType.CONNECT_EXTRAS
            ]);
          } else {
            items.push([
              getLinkContextMenuItem(category.ASSET),
              contextMenuItemType.LINK_CRYPTO,
              contextMenuItemType.CONNECT_STOCK,
              contextMenuItemType.CONNECT_CRYPTO,
              contextMenuItemType.CONNECT_METAL,
              contextMenuItemType.CONNECT_EXTRAS,
              contextMenuItemType.CONNECT_PORTFOLIOS,
              contextMenuItemType.ENTER_QTY_PRICE
            ]);
          }
        }
        return items;
      } else if (row.isLinked === true) {
        const connectivityCenterDataForCurrentPortfolio = this.props.connectivityCenterDataForPortfolio;
        const connectivityData =
          connectivityCenterDataForCurrentPortfolio &&
          connectivityCenterDataForCurrentPortfolio.find(
            data => data.providerAccountId === rowData.linkProviderAccountId
          );
        const items = [[contextMenuItemType.INSERT_ABOVE, contextMenuItemType.MOVE_ROW, ...removeOption]];

        if (row.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
          items.push([
            {
              ...contextMenuItemType.UNLINK,
              label: i18n.t("unlinkPortfolio"),
              description: row.linkAccountName
            }
          ]);
          return items;
        }

        if (
          row.linkStatus === 1 &&
          ["ITEM_LOGIN_REQUIRED", "REAL_TIME_MFA_REQUIRED", "ADDL_AUTHENTICATION_REQUIRED"].includes(row.linkStatusInfo)
        ) {
          if (connectivityData && (connectivityData.reconnectInfo || connectivityData.disconnect)) {
            if (!connectivityData.reconnectInfo) {
              // show Reconnect if there is not active recommendation to reconnect already
              items.unshift([contextMenuItemType.RECONNECT]);
            }
            if (!connectivityData.disconnect && !row.parentId) {
              items.unshift([contextMenuItemType.TRACK_MANUALLY]);
            }
            items.unshift([getStartOverContextMenuItem(rowData.linkProviderAccountId, connectivityData, true)]);
          } else {
            if (!row.parentId) {
              items.unshift([contextMenuItemType.TRACK_MANUALLY]);
            }
            items.unshift([contextMenuItemType.RECONNECT]);
          }

          items.unshift([
            getUpdateValuesContextMenuItem(
              getCustodianLastUpdateDetails(row.id),
              { is2FAError: true },
              connectivityData
            )
          ]);

          items.push([
            {
              ...contextMenuItemType.UNLINK,
              label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
              description: row.getLinkedAccountDescription()
            }
          ]);
          if (isAppInWhiteLabelMode() === true) {
            items.splice(1, 0, [getManagedAssetContextMenuItem(row.isManaged)]);
          }
          return items;
        }

        if (isErrorCellShown) {
          const badConnectionContextMenuItems = [
            {
              ...contextMenuItemType.BAD_CONNECTION,
              descriptionText: row.getLinkedAccountDescription(),
              lastUpdatedDetails: getCustodianLastUpdateDetails(row.id)
            }
          ];
          const activeRecommendationContextMenuItem = [];
          const passiveRecommendationReconnectContextMenuItem = [];
          const passiveRecommendationTrackManuallyContextMenuItem = [];
          if (connectivityData && (connectivityData.reconnectInfo || connectivityData.disconnect)) {
            // Active Recommendation
            if (!isCryptoLinkingService(row.linkType)) {
              activeRecommendationContextMenuItem.push(
                getStartOverContextMenuItem(rowData.linkProviderAccountId, connectivityData, false)
              );
              if (!connectivityData.reconnectInfo) {
                // show Reconnect if there is not active recommendation to reconnect already
                passiveRecommendationReconnectContextMenuItem.push({
                  ...contextMenuItemType.RECONNECT,
                  doNotShowReconnectOptionsDialog: true
                });
              }
            }
            // show Track Manually if there is not active recommendation to disconnect already
            if (!connectivityData.disconnect && !row.parentId) {
              passiveRecommendationTrackManuallyContextMenuItem.push(contextMenuItemType.TRACK_MANUALLY);
            }
          } else {
            // passive Recommendation when no active recommendation is present
            if (!isCryptoLinkingService(row.linkType)) {
              passiveRecommendationReconnectContextMenuItem.push({
                ...contextMenuItemType.RECONNECT,
                doNotShowReconnectOptionsDialog: true
              });
            }
            if (!row.parentId) {
              passiveRecommendationTrackManuallyContextMenuItem.push(contextMenuItemType.TRACK_MANUALLY);
            }
          }
          items.unshift(
            badConnectionContextMenuItems,
            activeRecommendationContextMenuItem,
            passiveRecommendationReconnectContextMenuItem,
            passiveRecommendationTrackManuallyContextMenuItem
          );
          items.push([
            {
              ...contextMenuItemType.UNLINK,
              label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
              description: row.getLinkedAccountDescription()
            }
          ]);
          return items;
        }

        if (row.isInvestable === true && isAppInWhiteLabelMode() === true) {
          items.unshift([getManagedAssetContextMenuItem(row.isManaged)]);
        }

        if (this.props.isReadOnlyWlClient) {
          items.shift();
        }

        if (
          !row.isLinking === true &&
          [
            accountLinkingService.ZERION,
            accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
            accountLinkingService.IN_HOUSE_CRYPTO_API,
            accountLinkingService.IN_HOUSE_OAUTH
          ].includes(row.linkType)
        ) {
          items.push(
            !row.parentId
              ? [
                  getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(row.id)),
                  contextMenuItemType.TRACK_MANUALLY,
                  {
                    ...contextMenuItemType.UNLINK_NON_ACCOUNTS,
                    label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
                    description: row.getLinkedAccountDescription()
                  }
                ]
              : [
                  getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(row.id)),
                  {
                    ...contextMenuItemType.UNLINK_NON_ACCOUNTS,
                    label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
                    description: row.getLinkedAccountDescription()
                  }
                ]
          );
        } else if (!row.isLinking === true && [accountLinkingService.ZILLOW].includes(row.linkType)) {
          const updateValueContextMenuItem = getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(row.id));
          items.push([
            { ...updateValueContextMenuItem, label: "Sync Value" },
            {
              ...contextMenuItemType.UNLINK_NON_ACCOUNTS,
              label: "Track Manually",
              description: row.getLinkedAccountDescription()
            }
          ]);
        } else if (!row.isLinking === true && [accountLinkingService.CARS].includes(row.linkType)) {
          const updateValueContextMenuItem = getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(row.id));
          items.push([
            { ...updateValueContextMenuItem, label: "Sync Value" },
            {
              ...contextMenuItemType.UNLINK_NON_ACCOUNTS,
              label: "Track Manually",
              description: row.getLinkedAccountDescription()
            }
          ]);
        } else if (!row.isLinking === true && [accountLinkingService.DOMAINS].includes(row.linkType)) {
          const updateValueContextMenuItem = getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(row.id));
          items.push([
            { ...updateValueContextMenuItem, label: "Sync Value" },
            {
              ...contextMenuItemType.UNLINK_NON_ACCOUNTS,
              label: "Track Manually",
              description: row.getLinkedAccountDescription()
            }
          ]);
        } else if (
          !row.isLinking === true &&
          [
            accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
            accountLinkingService.IN_HOUSE_CRYPTO_API,
            accountLinkingService.IN_HOUSE_OAUTH
          ].includes(row.linkType)
        ) {
          items.push([
            getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(row.id)),
            {
              ...contextMenuItemType.UNLINK,
              label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
              description: row.getLinkedAccountDescription()
            }
          ]);
        } else if (!row.isLinking === true) {
          items.push([getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(row.id))]);
          if (!row.parentId) {
            items.push([contextMenuItemType.RECONNECT], [contextMenuItemType.TRACK_MANUALLY]);
          } else {
            items.push([contextMenuItemType.RECONNECT]);
          }
          items.push([
            {
              ...contextMenuItemType.UNLINK,
              label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
              description: row.getLinkedAccountDescription()
            },
            getLinkedAccountDescriptionMenuItem(row.linkType)
          ]);
        }
        return items;
      } else if (row.isLinking === true) {
        if (!row.linkingAccountsData === true) {
          return [[contextMenuItemType.INSERT_ABOVE], [...removeOption]];
        } else {
          return [
            [contextMenuItemType.INSERT_ABOVE],
            [
              {
                ...contextMenuItemType.UNLINK,
                label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove"
              }
            ]
          ];
        }
      } else if (row.isLinked === false) {
        const ticker = this.props.getTickerUsingId(row.valueTickerId);
        let basicOptions;
        if (ticker.type === "fiat" && ticker.subType !== tickerSubTypes.PRECIOUS_METALS) {
          basicOptions = [
            [
              contextMenuItemType.INSERT_ABOVE,
              row.isUpdated === true ? contextMenuItemType.MARK_UNUPDATED : contextMenuItemType.MARK_UPDATED,
              contextMenuItemType.MOVE_ROW,
              ...removeOption
            ]
          ];
        } else {
          basicOptions = [[contextMenuItemType.INSERT_ABOVE, contextMenuItemType.MOVE_ROW, ...removeOption]];
        }

        if (row.isInvestable === true && isAppInWhiteLabelMode() === true) {
          basicOptions.unshift([getManagedAssetContextMenuItem(row.isManaged)]);
        }

        if (this.props.isReadOnlyWlClient) {
          basicOptions.shift();
        }

        const items = basicOptions;
        if (
          ticker.type !== tickerTypes.FUND &&
          ticker.type !== tickerTypes.STOCK &&
          ticker.type !== tickerTypes.CRYPTO &&
          ticker.type !== tickerTypes.BOND &&
          ticker.type !== tickerTypes.DERIVATIVE &&
          ticker.type !== tickerTypes.INDEX &&
          ticker.subType !== tickerSubTypes.PRECIOUS_METALS
        ) {
          if (this.props.isReadOnlyWlClient) {
            items.push([
              contextMenuItemType.CONNECT_STOCK,
              contextMenuItemType.CONNECT_CRYPTO,
              contextMenuItemType.CONNECT_METAL,
              contextMenuItemType.CONNECT_EXTRAS
            ]);
          } else {
            items.push([
              contextMenuItemType.CONNECT_STOCK,
              contextMenuItemType.CONNECT_CRYPTO,
              contextMenuItemType.CONNECT_METAL,
              contextMenuItemType.CONNECT_EXTRAS,
              contextMenuItemType.CONNECT_PORTFOLIOS,
              ...(row.valueTickerId === 171
                ? [contextMenuItemType.ENTER_MANUALLY]
                : [contextMenuItemType.ENTER_QTY_PRICE])
            ]);
          }
        }
        const rowDescriptionItem = getTickerDescriptionContextMenuItem(ticker);
        if (!rowDescriptionItem === false) {
          items.push([rowDescriptionItem]);
        }
        return items;
      }
    };
    rowData.isDragEnabled = isViewOnlyMode === false;
    rowData.defaultCellIndexToSelect = 1;
    if (this.props.isReadOnlyWlClient) {
      rowData.isManaged = true;
      rowData.isInvestable = true;
    }
    return rowData;
  }

  handleChange = newGridData => {
    this.setState({ ...this.state, gridData: newGridData });
  };

  handleConnectPositiveClick() {
    if (!this.connectedLinkTypeOpenRef.current) {
      return;
    }

    const preferenceProp = `${this.connectedLinkTypeOpenRef.current}ConnectTipShown`;

    this.props.updateUserPreferences({ [preferenceProp]: true });
    this.connectToolTipRef.current.dismiss();
  }

  showConnectToolTip() {
    if (!this.props.connectedLinkType) {
      return;
    }

    const connectToolTipElem = document.getElementById(this.state.connectToolTipTargetId);

    const preferenceProp = `${this.props.connectedLinkType}ConnectTipShown`;
    this.connectedLinkTypeToolTipRef.current = this.props.connectedLinkType;

    if (
      this.props.connectedLinkType &&
      !this.props.userPreferences[preferenceProp] &&
      connectToolTipElem &&
      connectToolTipElem.value
    ) {
      this.connectedLinkTypeOpenRef.current = this.props.connectedLinkType;
      this.props.setLinkType(null);

      const toolTipText = i18n.t(`linkAccount.${this.props.connectedLinkType}UpdateToolTip`);
      this.connectToolTipRef.current.show(toolTipText, 0, 0, () => {
        this.connectToolTipRef.current.dismiss();
      });
    }
  }

  showLatestWhatsNewTipIfNeeded() {
    if (this.props.canShowWhatsNewTip === false || !latestWhatsNewTip === true) {
      return;
    }

    setTimeout(() => {
      this.props.showToastTip(
        "TIP",
        latestWhatsNewTip,
        didShow => {
          if (didShow === true) {
            this.props.updateUserPreferences({
              lastShownWhatsNewTipVersion: latestWhatsNewTipVersion
            });
          }
        },
        -1,
        i18n.t("knowMore"),
        () => {
          window.kuberaOpen("https://www.kubera.com/blog/portfolio-manager-to-track-fiat-currency-crypto-portfolios");
        }
      );
    }, 500);
  }

  dismissStockUpdateToolTip() {
    if (this.connectToolTipRef.current && this.connectToolTipRef.current.isVisible() === true) {
      this.connectToolTipRef.current.dismiss();
    }
  }

  showLinkAccountTipIfNeeded() {
    setTimeout(() => {
      if (
        this.props.userPreferences.canShowLinkAccountTip === false ||
        this.props.userPreferences.isLinkAccountTipShown === true
      ) {
        return;
      }

      if (this.props.hasUserLinkedAnyAccounts === true) {
        return;
      }

      setTimeout(() => {
        const gridData = this.state.gridData;
        if (
          !this.connectAccountToolTipRef === true ||
          !this.connectAccountToolTipRef.current === true ||
          !gridData === true
        ) {
          return;
        }
        this.connectAccountToolTipRef.current.show(i18n.t("linkAccountTip"), 0, 0, () => {
          this.props.updateUserPreferences({ isLinkAccountTipShown: true });
        });
      }, 500);
    }, 500);
  }

  showAccountNameTipIfNeeded() {
    if (
      !this.accountNameToolTipRef === true ||
      !this.accountNameToolTipRef.current === true ||
      !this.state.gridData === true
    ) {
      return;
    }
    if (
      this.props.userPreferences.isAccoutNameTipShown === true ||
      this.props.userPreferences.canShowAccountNameTip === false
    ) {
      return;
    }
    if (hasUserMadeAnyManualEntries(store.getState()) === true) {
      return;
    }

    this.accountNameToolTipRef.current.show(i18n.t("accoutNameTip"), 0, 0, () => {
      this.props.updateUserPreferences({ isAccoutNameTipShown: true, canShowLinkAccountTip: true });
      this.showLinkAccountTipIfNeeded();
    });
  }

  showAddNewTipIfNeeded() {
    if (!this.addNewToolTipRef === true || !this.addNewToolTipRef.current === true || !this.state.gridData === true) {
      return;
    }
    if (this.props.userPreferences.isAddNewButtonTipShown === true) {
      return;
    }
    if (this.props.userPreferences.isAccoutNameTipShown === true) {
      return;
    }
    if (this.props.userPreferences.canShowAddNewButtonTip === false || this.props.hasUserLinkedAnyAccounts === false) {
      return;
    }
    this.addNewToolTipRef.current.show(i18n.t("addNewTip"), 20, 0, () => {
      this.props.updateUserPreferences({ isAddNewButtonTipShown: true });
    });
  }

  showMultiCurrencyTipIfNeeded() {
    if (this.isReadOnly() === true) {
      return;
    }

    const preferences = this.props.userPreferences;
    if (preferences.isMultiCurrencyTipShown === false) {
      if (this.state.gridData.numberOfCompletedRows() < 1) {
        return;
      }

      setTimeout(() => {
        this.props.showToastTip(
          "TIP",
          i18n.t("multiCurrencyTip"),
          undefined,
          -1,
          i18n.t("knowMore"),
          () => {
            window.kuberaOpen("https://help.kubera.com/article/11-portfolio-with-multiple-currency-support");
          },
          isDismissedByUser => {
            if (isDismissedByUser === true) {
              this.props.updateUserPreferences({
                isMultiCurrencyTipShown: true
              });
            }
          }
        );
      }, 500);
    }
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit, allowLocalUpdate) {
    const portfolioTicker = getTickerUsingShortName(this.props.portfolio.currency);

    const newCustodian = {
      name: updatedRow.cells[1].value,
      value: updatedRow.cells[3].value,
      valueTickerId: updatedRow.cells[3].getTickerId() || portfolioTicker.id,
      valueExchangeRate: updatedRow.cells[3].exchangeRateDetails,
      valueInvalidInputText: updatedRow.cells[3].invalidInputText,
      valueTickerInfo: updatedRow.cells[3].tickerInfo,
      currency: updatedRow.cells[3].currency,
      star: !updatedRow.cells[0].value ? 0 : 1,
      past: updatedRow.isUpdated === true ? 0 : 1,
      sortKey: updatedRow.sortKey,
      isCompleted: updatedRow.wasRowEverComplete === true ? 1 : 0,
      aum: updatedRow.isManaged ? 1 : 0
    };
    if (allowLocalUpdate === true && !isFirstEdit) {
      this.props.updateCustodianLocal({
        newCustodian,
        id: updatedRow.id
      });
    }
    this.props.updateCustodian(isFirstEdit, updatedRow.id, newCustodian, undefined, () => {
      unblockDetailsLoading();
    });

    if (this.accountNameToolTipRef.current) {
      this.accountNameToolTipRef.current.dismiss();
    }
    this.showLinkAccountTipIfNeeded();

    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[2].value = updatedRow.getIrr();
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleAddNewRow(sheetIndex, sectionIndex, rowIndex, row) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    const section = sheet.sections[sectionIndex];
    sheet.irr = null;
    section.irr = null;
    this.props.insertCustodian(this.props.portfolio.id, section.id, row.id, row.sortKey);
  }

  handleRemoveRow(sheetIndex, sectionIndex, rowIndex, row) {
    if (row.isEmpty() === true && !row.tsModified === true) {
      this.props.deleteLocalCustodian(this.props.portfolio.id, row.id);
      return;
    }
  }

  handleSectionMove(sourceSheetId, destinationSheetId, updatedSection) {
    this.props.updateSection(this.props.portfolio.id, sourceSheetId, updatedSection.id, {
      sheetId: destinationSheetId
    });
  }

  handleSectionUpdate(sheetIndex, sectionIndex, updatedSection) {
    const sheetId = this.state.gridData.sheets[sheetIndex].id;
    this.props.updateSection(this.props.portfolio.id, sheetId, updatedSection.id, {
      name: updatedSection.name,
      sortKey: updatedSection.sortKey,
      expanded: updatedSection.isCollapsed === true ? 0 : 1,
      columnSortKey: updatedSection.columnSortKey,
      columnSortOrder: updatedSection.columnSortOrder
    });
  }

  handleAddNewSection(sheetIndex, sectionIndex, section) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    sheet.irr = null;
    this.props.insertSection(this.props.portfolio.id, sheet.id, section);
  }

  handleRemoveSection(sheetIndex, sectionIndex, section) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    this.props.archiveSection(sheet.id, section.id);
  }

  handleSheetUpdate(sheetIndex, updatedSheet) {
    this.props.updateSheet(this.props.portfolio.id, updatedSheet.id, {
      name: updatedSheet.name,
      sortKey: updatedSheet.sortKey,
      updateFrequency: updatedSheet.updateFrequency.value
    });
  }

  handleRemoveSheet(sheetIndex, sheet) {
    const isLastSheet = this.state.gridData.sheets.length === 0;

    this.props.archiveSheet(sheet.id, isLastSheet);
  }

  handleReorderRowsForSections(sheetIndex, sourceSection, destinationSection, updatedRow) {
    this.props.moveCustodian(sourceSection.id, destinationSection.id, updatedRow.id, updatedRow.sortKey);
  }

  handleDetailsClick(sheetIndex, sectionIndex, rowIndex, row) {
    this.CustodianDetailsWrapperRef.current.show(sheetIndex, sectionIndex, rowIndex, row);
  }

  handleDetailsClose = (sheetIndex, sectionIndex, rowIndex, row, defaultTab) => {
    this.isUpdateClicked = true;
  };

  handleOptionsClick(sheetIndex, sectionIndex, rowIndex, row) {
    if (this.connectAccountToolTipRef.current) {
      this.connectAccountToolTipRef.current.dismiss();
    }
    if (this.whatsNewToolTipRef.current) {
      this.whatsNewToolTipRef.current.dismiss();
    }
  }

  handleToggleSectionStarStatus(sheetIndex, sectionIndex) {
    const section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];
    const custodianIds = section.rows.filter(row => row.isEmpty() === false).map(row => row.id);
    this.props.bulkChangeCustodianStarStatus(custodianIds, section.id, bulkActionSource.SECTION);
  }

  handleToggleSectionUpdatedStatus(sheetIndex, sectionIndex) {
    const section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];
    const custodianIds = section.rows.filter(row => row.isEmpty() === false).map(row => row.id);
    this.props.bulkChangeCustodianUpdatedStatus(custodianIds, section.id, bulkActionSource.SECTION);
  }

  handleToggleSheetStarStatus(sheetIndex) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    var custodianIds = [];
    for (const section of sheet.sections) {
      for (const row of section.rows) {
        if (row.isEmpty() === false) {
          custodianIds.push(row.id);
        }
      }
    }
    this.props.bulkChangeCustodianStarStatus(custodianIds, sheet.id, bulkActionSource.SHEET);
  }

  handleToggleSheetUpdatedStatus(sheetIndex) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    var custodianIds = [];
    for (const section of sheet.sections) {
      for (const row of section.rows) {
        if (row.isEmpty() === false) {
          custodianIds.push(row.id);
        }
      }
    }
    this.props.bulkChangeCustodianUpdatedStatus(custodianIds, sheet.id, bulkActionSource.SHEET);
  }

  handleAddNewSheet(sheetIndex, newSheet, addCategory = category.ASSET) {
    this.props.insertSheet(addCategory, newSheet);
  }

  handleUserLeavingCellEmpty(sheetIndex, sectionIndex, rowIndex, cellIndex) {}

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.LINK.id) {
      LinkAccountComponentExports.linkAccount(this.props.history, this.props.location, row.id);
    } else if (menuItem.id === contextMenuItemType.UNLINK.id) {
      const dialogExceptionList = [
        accountLinkingService.ZILLOW,
        accountLinkingService.CARS,
        accountLinkingService.DOMAINS
      ];
      const custodiansWithSameAccount = custodiansLinkedToSameAccount(row.id, false, true, menuItem.isRemoveFlow)
        .custodians.length;
      if (dialogExceptionList.includes(row.linkType) === false && custodiansWithSameAccount > 0) {
        this.setState({
          showUnlinkAllConnectionsDialog: true,
          unlinkDialogRow: row,
          unlinkDialogCount: custodiansWithSameAccount,
          isRemoveFlow: menuItem.isRemoveFlow
        });
      } else {
        this.props.unlinkAccountWithCustodian(row.id, true, () => {
          this.props.getConnectivityCenterData();
        });
      }
    } else if (menuItem.id === contextMenuItemType.RECONNECT.id) {
      LinkAccountComponentExports.reconnect(
        this.props.history,
        this.props.location,
        row.id,
        menuItem.doNotShowReconnectOptionsDialog
      );
    } else if (menuItem.id === contextMenuItemType.REAUTH.id) {
      LinkAccountComponentExports.linkAccount(this.props.history, this.props.location, row.id, linkAccountMode.EDIT);
    } else if (menuItem.id === contextMenuItemType.UPDATE_VALUES.id) {
      const lastRefreshRequest = this.custodianRefreshTsMap[row.id];
      if (
        row.linkType !== accountLinkingService.PLAID &&
        row.linkType !== accountLinkingService.LEAN &&
        !isCryptoLinkingService(row.linkType) &&
        lastRefreshRequest &&
        new Date().getTime() - lastRefreshRequest <= 2 * 60 * 1000
      ) {
        this.setState({
          showReconnectDialog: true,
          reconnectDialogRow: row
        });
        this.custodianRefreshTsMap[row.id] = 0;
      } else {
        this.custodianRefreshTsMap[row.id] = new Date().getTime();
      }

      LinkAccountComponentExports.refreshAccountValues(
        this.props.history,
        this.props.location,
        row.id,
        () => null,
        () => null,
        {
          force: 1
        }
      );
      this.isUpdateClicked = true;
    } else if (menuItem.id === contextMenuItemType.REMOVE.id || menuItem.id === contextMenuItemType.ARCHIVE.id) {
      const sheet = this.state.gridData.sheets[sheetIndex];
      const section = sheet.sections[sectionIndex];
      const row = section.rows[rowIndex];

      if (section.rows.length === 1) {
        if (row.isComplete()) {
          return this.handleRemoveCompletedRow(sheetIndex, sectionIndex, rowIndex, row);
        }
        return;
      }

      if (!isCustodianAddedToday(row.id) || !row.parentId === false) {
        return this.handleRemoveCompletedRow(sheetIndex, sectionIndex, rowIndex, row, () => {
          const sectionId = section.id;
          this.props.archiveCustodian(sectionId, row.id, row.isEmpty(), { isArchiveOnDelete: true });
        });
      } else if (row.isComplete()) {
        return this.handleRemoveCompletedRow(sheetIndex, sectionIndex, rowIndex, row, () => {
          this.props.deleteCustodian(row.id, row.isEmpty());
        });
      } else {
        this.props.deleteCustodian(row.id, row.isEmpty());
      }
    } else if (menuItem.id === contextMenuItemType.LINK_ACCOUNT_PICK_COUNTRY.id) {
      PickLinkAccountCountry.show(this.props.history, this.props.location, row.id);
    } else if (menuItem.id === contextMenuItemType.LINK_CRYPTO.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, sheetIndex, sectionIndex, rowIndex, 4)
      });
      LinkAccountComponentExports.linkAccountWithService(
        this.props.history,
        this.props.location,
        row.id,
        linkAccountMode.LINK,
        accountLinkingService.ZABO
      );
    } else if (menuItem.id === contextMenuItemType.MOVE_ROW.id) {
      const custodian = custodianSelector(
        store.getState(),
        this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].id
      );
      this.setState({ moveDialogForData: { item: custodian, itemType: moveItemType.CUSTODIAN } });
    } else if (menuItem.id === contextMenuItemType.CONNECT_STOCK.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, sheetIndex, sectionIndex, rowIndex, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_STOCK}&${hashParams.ID}=${row.id}`
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_CRYPTO.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, sheetIndex, sectionIndex, rowIndex, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_CRYPTO}&${hashParams.ID}=${row.id}`
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_METAL.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, sheetIndex, sectionIndex, rowIndex, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_METAL}&${hashParams.ID}=${row.id}`
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_EXTRAS.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, sheetIndex, sectionIndex, rowIndex, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_EXTRAS}&${hashParams.ID}=${row.id}`
      });
    } else if (menuItem.id === contextMenuItemType.MANAGED_ASSET.id) {
      const updatedRow = row;
      updatedRow.isManaged = !updatedRow.isManaged;
      this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, false);
    } else if (menuItem.id === contextMenuItemType.MANAGE_ACCOUNTS.id) {
      LinkAccountComponentExports.linkAccount(this.props.history, this.props.location, row.id, linkAccountMode.MANAGE);
    } else if (menuItem.id === contextMenuItemType.BAD_CONNECTION.id) {
      LinkAccountComponentExports.resolveLinkError(this.props.history, this.props.location, row.id);
    } else if (menuItem.id === contextMenuItemType.START_OVER.id) {
      if (menuItem.reconnectInfo) {
        LinkAccountComponentExports.reconnect(this.props.history, this.props.location, row.id, true);
      } else {
        const custodiansWithSameAccount = custodiansLinkedToSameAccount(row.id, false, true).custodians.length;
        this.setState({
          showDisconnectAndTrackManuallyDialog: true,
          unlinkDialogCount: custodiansWithSameAccount,
          rowToDisconnectAndTrackManually: row
        });
      }
    } else if (menuItem.id === contextMenuItemType.TRACK_MANUALLY.id) {
      const custodiansWithSameAccount = custodiansLinkedToSameAccount(row.id, false, true).custodians.length;
      this.setState({
        showDisconnectAndTrackManuallyDialog: true,
        unlinkDialogCount: custodiansWithSameAccount,
        rowToDisconnectAndTrackManually: row
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_PORTFOLIOS.id) {
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_PORTFOLIOS}&${hashParams.ID}=${row.id}`
      });
    } else if (menuItem.id === contextMenuItemType.ENTER_MANUALLY.id) {
      const parsedExchangeRate = JSON.parse(row.cells[3].exchangeRateDetails);

      if (parsedExchangeRate) {
        row.cells[3].value = parsedExchangeRate.rate * row.cells[3].value;
        row.cells[3].currency = getTickerUsingId(parsedExchangeRate.tickerId).shortName;
        row.cells[3].valueTickerId = parsedExchangeRate.tickerId;
      } else {
        const portfolioTicker = getTickerUsingShortName(this.props.portfolio.currency);
        row.cells[3].value =
          row.cells[3].value *
          row.cells[3].getExchangeRateFromDetailsString(
            row.cells[3].exchangeRateDetails,
            this.props.portfolio.currency
          );
        row.cells[3].currency = this.props.portfolio.currency;
        row.cells[3].valueTickerId = portfolioTicker.id;
      }
      row.cells[3].useRateFromExchangeRateDetails = false;
      row.cells[3].exchangeRateDetails = null;

      blockDetailsLoading();
      this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, row, false, true);
    } else if (menuItem.id === contextMenuItemType.ENTER_QTY_PRICE.id) {
      PVSTConnectModal.show(this.props.history, this.props.location, row.id);
    }
  }

  handleRemoveCompletedRow = (sheetIndex, sectionIndex, rowIndex, row, callback = () => null) => {
    const shouldDisconnect = () => {
      const disconnectExceptionList = [
        accountLinkingService.ZILLOW,
        accountLinkingService.CARS,
        accountLinkingService.DOMAINS
      ];

      if (
        row.isLinked === true &&
        disconnectExceptionList.includes(row.linkType) === false &&
        isCryptoLinkingService(row.linkType) === true &&
        row.linkContainer !== "nft"
      ) {
        return true;
      }
      return false;
    };

    if (shouldDisconnect()) {
      this.deleteConfirmationPromise = new DeferredPromise();
      setTimeout(() => {
        this.deleteConfirmationPromise.resolve(false);
        this.handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, {
          ...contextMenuItemType.UNLINK,
          label: !isCustodianAddedToday(row.id) ? "Disconnect & Archive" : "Disconnect & Remove",
          isRemoveFlow: true
        });
      }, 0);
      return this.deleteConfirmationPromise;
    }

    setTimeout(async () => {
      var deleteType = row.isLinked ? 2 : 1;
      const rowNameToDelete = custodianSelector(store.getState(), !row.parentId === false ? row.parentId : row.id).name;
      const rowIdToDelete = !row.parentId === false ? row.parentId : row.id;
      var numberOfRowsToDelete = 1;

      if (!row.parentId === false) {
        deleteType = 3;

        numberOfRowsToDelete = custodiansWithSameParentIdSelector(
          store.getState(),
          this.props.portfolio.id,
          row.parentId
        ).length;
      } else if (row.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
        deleteType = 4;
        numberOfRowsToDelete = custodiansLinkedToSameAccount(row.id).portfolioCustodiansMap[this.props.portfolio.id]
          .length;
      }

      this.setState({
        deleteType: deleteType,
        numberOfRowsToDelete: numberOfRowsToDelete,
        rowNameToDelete: rowNameToDelete,
        rowIdToDelete: rowIdToDelete
      });
      const isConfirm = await this.deleteConfirmationPromise.catch(err => null);
      if (isConfirm) {
        callback();
      }
    });

    this.deleteConfirmationPromise = new DeferredPromise();
    return this.deleteConfirmationPromise;
  };

  handleDeleteConfirmPositiveButtonClick = () => {
    this.setState({
      deleteType: 0,
      numberOfRowsToDelete: 0,
      rowNameToDelete: "",
      rowIdToDelete: ""
    });
    this.deleteConfirmationPromise.resolve(true);
  };

  handleDeleteConfirmNegativeButtonClick = () => {
    this.setState({
      deleteType: 0,
      numberOfRowsToDelete: 0,
      rowNameToDelete: "",
      rowIdToDelete: ""
    });
    this.deleteConfirmationPromise.resolve(false);
  };

  handleGridContextMenuSelection(menuItem) {
    if (menuItem.id === contextMenuItemType.ARCHIVED.id) {
      ArchivedComponent.show(this.props.history, this.props.location);
    }
  }

  handleSheetContextMenuSelection(sheetIndex, menuItem) {
    const sheet = this.state.gridData.sheets[sheetIndex];
    if (menuItem.id === contextMenuItemType.DOWNLOADEXCEL.id) {
      ApiClient.getSheetDownloadUrl(this.props.portfolio.id, sheet.id)
        .then(url => {
          downloadFile(url, sheet.name);
        })
        .catch(apiError => {});
    } else if (menuItem.id === contextMenuItemType.AUTO_UNUPDATE.id) {
      SheetUpdateFrequencyDialog.show(this.props.history, this.props.location, sheet.id);
    } else if (menuItem.id === contextMenuItemType.TOGGLE_UPDATED_STATUS.id) {
      this.handleToggleSheetUpdatedStatus(sheetIndex);
    } else if (menuItem.id === contextMenuItemType.TOGGLE_STAR_STATUS.id) {
      this.handleToggleSheetStarStatus(sheetIndex);
    } else if (menuItem.id === contextMenuItemType.MOVE_SHEET.id) {
      this.setState({
        moveDialogForData: {
          item: sheetSelector(store.getState(), this.state.gridData.sheets[sheetIndex].id),
          itemType: moveItemType.SHEET
        }
      });
    }
  }

  handleAddNewContextMenuSelection = (sheetIndex, sectionIndex, menuItem) => {
    let section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];

    if (!section) {
      const { sections } = this.state.gridData.sheets[sheetIndex];
      section = this.getEmptySection(sections.length, getSortKeyBetween(sections[sections.length - 1].sortKey, null));
      section.id = getUuid();
      this.state.gridData.sheets[sheetIndex].sections.push(section);
      this.handleChange(this.state.gridData);

      this.handleAddNewSection(sheetIndex, sectionIndex, section);
    }

    this.handleSectionContextMenuSelection(sheetIndex, sectionIndex, menuItem);
  };

  handleSectionContextMenuSelection(sheetIndex, sectionIndex, menuItem) {
    let section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];

    if (!menuItem) {
      this.gridDataRef.current.handleAddNewRow(sheetIndex, sectionIndex, section.rows.length, undefined, true, true);
    } else if (menuItem.id === contextMenuItemType.ENTER_QTY_PRICE.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(
          this.state.gridData.id,
          sheetIndex,
          sectionIndex,
          section.getFirstEmptyRowIndex(),
          4
        )
      });
      PVSTConnectModal.show(this.props.history, this.props.location, this.getRowIdToConnectForSection(section));
    } else if (menuItem.id === contextMenuItemType.TOGGLE_UPDATED_STATUS.id) {
      this.handleToggleSectionUpdatedStatus(sheetIndex, sectionIndex);
    } else if (menuItem.id === contextMenuItemType.TOGGLE_STAR_STATUS.id) {
      this.handleToggleSectionStarStatus(sheetIndex, sectionIndex);
    } else if (menuItem.id === contextMenuItemType.LINK_CARTA.id) {
      LinkAccountComponentExports.linkAccountWithService(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(section),
        linkAccountMode.LINK,
        accountLinkingService.IN_HOUSE_OAUTH
      );
    } else if (menuItem.id === contextMenuItemType.LINK.id) {
      LinkAccountComponentExports.linkAccount(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(section)
      );
    } else if (menuItem.id === contextMenuItemType.LINK_ACCOUNT_PICK_COUNTRY.id) {
      PickLinkAccountCountry.show(this.props.history, this.props.location, this.getRowIdToConnectForSection(section));
    } else if (menuItem.id === contextMenuItemType.LINK_CRYPTO.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(
          this.state.gridData.id,
          sheetIndex,
          sectionIndex,
          section.getFirstEmptyRowIndex(),
          4
        )
      });
      LinkAccountComponentExports.linkAccountWithService(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(section),
        linkAccountMode.LINK,
        accountLinkingService.ZABO
      );
    } else if (menuItem.id === contextMenuItemType.MOVE_SECTION.id) {
      this.setState({
        moveDialogForData: {
          item: sectionSelector(store.getState(), this.state.gridData.sheets[sheetIndex].sections[sectionIndex].id),
          itemType: moveItemType.SECTION
        }
      });
    } else if (
      menuItem.id === contextMenuItemType.SECTION_ADD_ROW.id ||
      menuItem.id === contextMenuItemType.SECTION_ADD_SECTION.id
    ) {
      // Show initial tips when user creates row or section after add new
      // tip was shown
      if (this.props.userPreferences.isAddNewButtonTipShown === true) {
        if (this.accountNameToolTipRef.current && this.accountNameToolTipRef.current.isVisible() === true) {
          return;
        }
        const lastSectionIndex = this.state.gridData.sheets[0].getLastSectionIndex();
        const lastSection = this.state.gridData.sheets[0].sections[lastSectionIndex];
        if (menuItem.id === contextMenuItemType.SECTION_ADD_ROW.id) {
          this.initialTipsRowIndex = lastSection.getLastEmptyRowIndex();
        } else {
          this.initialTipsRowIndex = lastSection.getFirstEmptyRowIndex();
        }

        this.props.updateUserPreferences({ canShowAccountNameTip: true });
        setTimeout(() => {
          this.showAccountNameTipIfNeeded();
        }, 500);
      }
    } else if (menuItem.id === contextMenuItemType.CONNECT_STOCK.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(
          this.state.gridData.id,
          sheetIndex,
          sectionIndex,
          section.getFirstEmptyRowIndex(),
          4
        )
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_STOCK}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          section
        )}`
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_CRYPTO.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(
          this.state.gridData.id,
          sheetIndex,
          sectionIndex,
          section.getFirstEmptyRowIndex(),
          4
        )
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_CRYPTO}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          section
        )}`
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_METAL.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(
          this.state.gridData.id,
          sheetIndex,
          sectionIndex,
          section.getFirstEmptyRowIndex(),
          4
        )
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_METAL}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          section
        )}`
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_EXTRAS.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(
          this.state.gridData.id,
          sheetIndex,
          sectionIndex,
          section.getFirstEmptyRowIndex(),
          4
        )
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_EXTRAS}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          section
        )}`
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_HOMES.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(
          this.state.gridData.id,
          sheetIndex,
          sectionIndex,
          section.getFirstEmptyRowIndex(),
          4
        )
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_HOMES}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          section
        )}`
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_CARS.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(
          this.state.gridData.id,
          sheetIndex,
          sectionIndex,
          section.getFirstEmptyRowIndex(),
          4
        )
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_CARS}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          section
        )}`
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_DOMAINS.id) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(
          this.state.gridData.id,
          sheetIndex,
          sectionIndex,
          section.getFirstEmptyRowIndex(),
          4
        )
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_DOMAINS}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          section
        )}`
      });
    } else if (menuItem.id === contextMenuItemType.CONNECT_PORTFOLIOS.id) {
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_PORTFOLIOS}&${
          hashParams.ID
        }=${this.getRowIdToConnectForSection(section)}`
      });
    }
  }

  handleIrrCellClick(e, sheetIndex, sectionIndex, rowIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    if (row.irrType !== irrTypes.HOLDING) {
      this.CustodianDetailsWrapperRef.current.show(sheetIndex, sectionIndex, rowIndex, row, detailsTabs.RETURNS);
    } else {
      this.CustodianDetailsWrapperRef.current.show(sheetIndex, sectionIndex, rowIndex, row, detailsTabs.HOLDINGS);
    }
  }

  getRowIdToConnectForSection(section) {
    const firstEmptyRow = section.getFirstEmptyRow();
    var rowId = !firstEmptyRow === true ? getUuid() : firstEmptyRow.id;
    if (!firstEmptyRow === true) {
      this.props.insertCustodianAtEndOfSection(this.props.portfolio.id, section.id, rowId, 1, { isAddedForConnect: 1 });
    }
    return rowId;
  }

  getEmptyGridView() {
    return (
      <EmptyGridViewAssetsComponent
        isReadOnlyWlClient={this.props.isReadOnlyWlClient}
        handleInitialTipClick={this.handleInitialTipClick}
        isInternalUser={this.props.isInternalUser}
      />
    );
  }

  handleInitialTipClick(item) {
    const newGridData = this.state.gridData;
    newGridData.autoFocusFirstCell = !item === true;
    this.setState({ ...this.state, gridData: newGridData });
    this.dismissStockUpdateToolTip();
    if (!item === true) {
      this.props.updateUserPreferences({
        canShowAccountNameTip: true,
        isAssetInitialTipShownForPortfolio: [
          ...this.props.userPreferences.isAssetInitialTipShownForPortfolio,
          this.props.portfolio.id
        ]
      });
      setTimeout(() => {
        this.showAccountNameTipIfNeeded();
      }, 500);
    } else if (item === contextMenuItemType.ENTER_QTY_PRICE) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, 0, 0, 0, 4)
      });
      PVSTConnectModal.show(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(newGridData.sheets[0].sections[0])
      );
    } else if (item === contextMenuItemType.LINK_CARTA) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      LinkAccountComponentExports.linkAccountWithService(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(newGridData.sheets[0].sections[0]),
        linkAccountMode.LINK,
        accountLinkingService.IN_HOUSE_OAUTH
      );
    } else if (item === contextMenuItemType.LINK) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      LinkAccountComponentExports.linkAccount(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(newGridData.sheets[0].sections[0])
      );
    } else if (item === contextMenuItemType.LINK_ACCOUNT_PICK_COUNTRY) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      PickLinkAccountCountry.show(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(newGridData.sheets[0].sections[0])
      );
    } else if (item === contextMenuItemType.LINK_CRYPTO) {
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, 0, 0, 0, 4)
      });
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      LinkAccountComponentExports.linkAccountWithService(
        this.props.history,
        this.props.location,
        this.getRowIdToConnectForSection(newGridData.sheets[0].sections[0]),
        linkAccountMode.LINK,
        accountLinkingService.ZABO
      );
    } else if (item === contextMenuItemType.CONNECT_STOCK) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, 0, 0, 0, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_STOCK}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          newGridData.sheets[0].sections[0]
        )}`
      });
    } else if (item === contextMenuItemType.CONNECT_CRYPTO) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, 0, 0, 0, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_CRYPTO}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          newGridData.sheets[0].sections[0]
        )}`
      });
    } else if (item === contextMenuItemType.CONNECT_EXTRAS) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, 0, 0, 0, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_EXTRAS}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          newGridData.sheets[0].sections[0]
        )}`
      });
    } else if (item === contextMenuItemType.CONNECT_METAL) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, 0, 0, 0, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_METAL}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          newGridData.sheets[0].sections[0]
        )}`
      });
    } else if (item === contextMenuItemType.CONNECT_HOMES) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, 0, 0, 0, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_HOMES}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          newGridData.sheets[0].sections[0]
        )}`
      });
    } else if (item === contextMenuItemType.CONNECT_CARS) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, 0, 0, 0, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_CARS}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          newGridData.sheets[0].sections[0]
        )}`
      });
    } else if (item === contextMenuItemType.CONNECT_DOMAINS) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, 0, 0, 0, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_DOMAINS}&${hashParams.ID}=${this.getRowIdToConnectForSection(
          newGridData.sheets[0].sections[0]
        )}`
      });
    } else if (item === contextMenuItemType.CONNECT_PORTFOLIOS) {
      this.props.updateUserPreferences({ canShowAddNewButtonTip: true });
      this.setState({
        connectToolTipTargetId: GridCellData.getCellId(this.state.gridData.id, 0, 0, 0, 4)
      });
      this.props.history.push({
        ...this.props.location,
        hash: `${hashParams.MODAL}=${modalValues.CONNECT_PORTFOLIOS}&${
          hashParams.ID
        }=${this.getRowIdToConnectForSection(newGridData.sheets[0].sections[0])}`
      });
    }

    this.setState({ hideEmptyState: true });
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === true) {
      if (cell.currency)
        setTimeout(() => {
          this.props.showToastTip("TIP", i18n.t("stockNotSupportedTip"), null, 10000);
        }, 500);
    } else {
      this.props.fetchCustodianTickerDetails(row.id, false);
    }
  }

  handleSheetSelection(sheetIndex) {
    if (sheetIndex === 0) {
      setTimeout(() => {
        this.showLinkAccountTipIfNeeded();
        this.showAccountNameTipIfNeeded();
        this.showAddNewTipIfNeeded();
      }, 10);
    }
  }

  handleAddNewFooterButtonClick(sheetIndex, sectionIndex, callback) {
    if (!this.addModalRef.current.isOpen()) {
      this.addModalRef.current.show(sheetIndex, sectionIndex, callback);
    }
    if (this.addNewToolTipRef && this.addNewToolTipRef.current) {
      this.addNewToolTipRef.current.dismiss();
    }
  }

  handleUnlinkAllConnectionsDialogPositiveButtonClick(e) {
    const custodianid = this.state.unlinkDialogRow.id;
    this.props.unlinkAllConnectedCustodians(custodianid, { isRemoveFlow: this.state.isRemoveFlow });
    this.setState({ showUnlinkAllConnectionsDialog: false, unlinkDialogRow: null, isRemoveFlow: false });
  }

  handleUnlinkAllConnectionsDialogOnDismiss() {
    this.setState({ showUnlinkAllConnectionsDialog: false, unlinkDialogRow: null, isRemoveFlow: false });
  }

  handleDisconnectAndTrackManuallyDialogPositiveButtonClick(e) {
    const custodianid = this.state.rowToDisconnectAndTrackManually.id;

    this.props.unlinkAndManuallyTrackAllConnectedCustodians(custodianid);
    this.setState({ showDisconnectAndTrackManuallyDialog: false, rowToDisconnectAndTrackManually: null });
  }

  handleDisconnectAndTrackManuallyDialogOnDismiss() {
    this.setState({ showDisconnectAndTrackManuallyDialog: false, rowToDisconnectAndTrackManually: null });
  }

  handleReconnectDialogPositiveButtonClick() {
    if (
      this.state.reconnectDialogRow.linkType !== accountLinkingService.ZILLOW &&
      this.state.reconnectDialogRow.linkType !== accountLinkingService.CARS &&
      this.state.reconnectDialogRow.linkType !== accountLinkingService.DOMAINS
    ) {
      LinkAccountComponentExports.reconnect(this.props.history, this.props.location, this.state.reconnectDialogRow.id);
    }
    this.handleReconnectDialogDismissButtonClick();
  }

  handleReconnectDialogNegativeButtonClick() {
    LinkAccountComponentExports.refreshAccountValues(
      this.props.history,
      this.props.location,
      this.state.reconnectDialogRow.id,
      () => null,
      () => null,
      {
        force: 1
      }
    );
    this.handleReconnectDialogDismissButtonClick();
    this.isUpdateClicked = true;
  }

  handleReconnectDialogDismissButtonClick() {
    this.setState({ showReconnectDialog: false, reconnectDialogRow: null });
  }

  handleRowClick(sheetIndex, sectionIndex, rowIndex, row) {
    if (row.isLinking === true) {
      if (!row.linkingAccountsData === false) {
        LinkAccountComponentExports.selectAccounts(
          this.props.history,
          this.props.location,
          row.id,
          row.linkingAccountsData.category
        );
        this.props.dismissToastAction();
      } else {
        LinkAccountCommonExports.reopen(row.id);
        this.props.dismissToastAction();
      }
    }
  }

  handleFirstTimeLinkingDialogButton() {
    this.setState({ showFirstTimeLinkAccountDialog: false });
    this.props.updateUserPreferences({
      isFirstTimeLinkingDialogShown: true
    });

    setTimeout(() => {
      this.showAddNewTipIfNeeded();
    }, 500);
  }

  handleLinkErrorClick(sheetIndex, sectionIndex, rowIndex, row) {
    if (this.connectAccountToolTipRef.current) {
      this.connectAccountToolTipRef.current.dismiss();
    }
    if (this.whatsNewToolTipRef.current) {
      this.whatsNewToolTipRef.current.dismiss();
    }

    return true;
  }

  handleMoveDialogDismiss() {
    this.setState({ moveDialogForData: null });
  }

  shouldShowEmptyState() {
    if (this.isReadOnly()) {
      return this.state.gridData.isEmpty() === true;
    }
    return this.props.isInitialTipShown === false && this.state.gridData.isEmpty() === true;
  }

  getInitialTipsSectionIndex() {
    const { sheets } = this.state.gridData;
    return sheets.length > 0 ? sheets[0].getLastSectionIndex() : null;
  }

  getReconnectDialogDescription(linkType) {
    if (linkType === accountLinkingService.ZILLOW) {
      return i18n.t("reconnectDialog.homes.description");
    } else if (linkType === accountLinkingService.CARS) {
      return i18n.t("reconnectDialog.cars.description");
    } else if (linkType === accountLinkingService.DOMAINS) {
      return i18n.t("reconnectDialog.domains.description");
    } else {
      return i18n.t("reconnectDialog.description");
    }
  }

  render() {
    window.renderlog("Render: AssetsComponent");

    const showFirstTimeLinkAccountDialog = this.state.showFirstTimeLinkAccountDialog;
    if (showFirstTimeLinkAccountDialog === true) {
      return <FirstTimeLinkingDialog onButtonClick={this.handleFirstTimeLinkingDialogButton} />;
    }

    const gridData = this.state.gridData;
    const shouldShowEmptyState = this.shouldShowEmptyState();

    if (shouldShowEmptyState === true) {
      this.isComingFromEmptyScreenRef.current = true;
      return (
        <EmptyGridMessage
          title={i18n.t("assetsComponent.emptyTitle")}
          customView={this.getEmptyGridView()}
          isHidden={this.state.hideEmptyState}
          isReadOnly={this.isReadOnly()}
        />
      );
    }

    setTimeout(() => {
      if (this.state.showFirstTimeLinkAccountDialog === false) {
        this.showAddNewTipIfNeeded();
      }
    }, 500);

    const showUnlinkAllConnectionsDialog = this.state.showUnlinkAllConnectionsDialog;
    const showDisconnectAndTrackManuallyDialog = this.state.showDisconnectAndTrackManuallyDialog;
    const initialTipsSectionIndex = this.getInitialTipsSectionIndex();
    const initialTipsRowIndex =
      this.initialTipsRowIndex !== undefined
        ? this.initialTipsRowIndex
        : gridData.sheets[0].sections[initialTipsSectionIndex].getFirstEmptyRowIndex();

    if (this.props.detailsOnlyRef) {
      return (
        <CustodianDetailsWrapperComponent
          gridData={gridData}
          handleRowContextMenuSelection={this.handleRowContextMenuSelection}
          history={this.props.history}
          location={this.props.location}
          ref={this.props.detailsOnlyRef}
          category={category.ASSET}
        />
      );
    }

    let archiveOrRemove = "Remove";
    if (this.state.rowIdToDelete) {
      archiveOrRemove = isCustodianAddedToday(this.state.rowIdToDelete) ? "Remove" : "Archive";
    }

    return (
      <Container>
        <CustodianDetailsWrapperComponent
          gridData={gridData}
          handleRowContextMenuSelection={this.handleRowContextMenuSelection}
          history={this.props.history}
          location={this.props.location}
          ref={this.CustodianDetailsWrapperRef}
          category={category.ASSET}
          handleDetailsClose={this.handleDetailsClose}
        />
        <Grid
          title={i18n.t("assetsComponent.title")}
          ref={this.gridDataRef}
          gridData={gridData}
          getEmptyRow={this.getEmptyRow}
          getEmptySection={this.getEmptySection}
          getEmptySheet={this.getEmptySheet}
          onSectionMove={this.handleSectionMove}
          onChange={this.handleChange}
          onRowUpdate={this.handleRowUpdate}
          onAddNewRow={this.handleAddNewRow}
          onRemoveRow={this.handleRemoveRow}
          onSectionUpdate={this.handleSectionUpdate}
          onReorderRows={this.handleReorderRowsForSections}
          onAddNewSection={this.handleAddNewSection}
          onRemoveSection={this.handleRemoveSection}
          onSheetUpdate={this.handleSheetUpdate}
          onRemoveSheet={this.handleRemoveSheet}
          onDetailsClick={this.handleDetailsClick}
          onOptionsClick={this.handleOptionsClick}
          onLinkErrorClick={this.handleLinkErrorClick}
          onAddNewSheet={this.handleAddNewSheet}
          onUserLeavingCellEmpty={this.handleUserLeavingCellEmpty}
          onRowContextMenuSelection={this.handleRowContextMenuSelection}
          onSheetContextMenuSelection={this.handleSheetContextMenuSelection}
          onGridContextMenuSelection={this.handleGridContextMenuSelection}
          onSectionContextMenuSelection={this.handleSectionContextMenuSelection}
          onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
          onSheetSelection={this.handleSheetSelection}
          userPreferences={this.props.userPreferences}
          updateUserPreferences={this.props.updateUserPreferences}
          onAddNewFooterButtonClick={this.handleAddNewFooterButtonClick}
          showToastTip={this.props.showToastTip}
          onRowClick={this.handleRowClick}
          portfolio={this.props.portfolio}
          location={this.props.location}
          history={this.props.history}
          category={category.ASSET}
        />
        <ToolTip ref={this.whatsNewToolTipRef} targetId={GridCellData.getCellId(gridData.id, 0, 0, 0, 6)} />
        <ToolTip
          ref={this.accountNameToolTipRef}
          targetId={GridCellData.getCellId(gridData.id, 0, initialTipsSectionIndex, initialTipsRowIndex, 0)}
          align={toolTipAlignment.LEFT}
        />
        <ToolTip
          ref={this.connectAccountToolTipRef}
          targetId={GridCellData.getCellId(gridData.id, 0, initialTipsSectionIndex, initialTipsRowIndex, 6)}
          align={toolTipAlignment.LEFT}
        />
        <ToolTip
          ref={this.connectToolTipRef}
          targetId={this.state.connectToolTipTargetId}
          align={toolTipAlignment.RIGHT}
          onPositiveClick={this.handleConnectPositiveClick}
        />
        <ToolTip ref={this.addNewToolTipRef} targetId={"add_new"} align={toolTipAlignment.RIGHT_BOTTOM} />
        {showUnlinkAllConnectionsDialog === true && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={
              this.state.unlinkDialogRow.linkType === accountLinkingService.KUBERA_PORTFOLIO
                ? `${i18n.t("unlinkPortfolio")}`
                : isCustodianAddedToday(this.state.unlinkDialogRow.id)
                ? i18n.t("unlinkAllConnectionsDialog.title")
                : i18n.t("unlinkAllConnectionsDialog.archive.title")
            }
            description={
              this.state.unlinkDialogRow.linkType === accountLinkingService.KUBERA_PORTFOLIO
                ? i18n.t("unlinkPortfolioDescription")
                : this.state.unlinkDialogCount > 1
                ? isCustodianAddedToday(this.state.unlinkDialogRow.id)
                  ? i18n.t("unlinkAllConnectionsDialog.multiple")
                  : i18n.t("unlinkAllConnectionsDialog.archive.multiple")
                : isCustodianAddedToday(this.state.unlinkDialogRow.id)
                ? i18n.t("unlinkAllConnectionsDialog.single")
                : i18n.t("unlinkAllConnectionsDialog.archive.single")
            }
            positiveButtonTitle={
              this.state.unlinkDialogRow.linkType === accountLinkingService.KUBERA_PORTFOLIO
                ? i18n.t("unlink")
                : this.state.unlinkDialogCount > 1
                ? isCustodianAddedToday(this.state.unlinkDialogRow.id)
                  ? i18n.t("unlinkAllConnectionsDialog.primaryButton") + ` ${this.state.unlinkDialogCount} rows`
                  : i18n.t("unlinkAllConnectionsDialog.archive.primaryButton") + ` ${this.state.unlinkDialogCount} rows`
                : isCustodianAddedToday(this.state.unlinkDialogRow.id)
                ? i18n.t("unlinkAllConnectionsDialog.primaryButton")
                : i18n.t("unlinkAllConnectionsDialog.archive.primaryButton")
            }
            negativeButtonTitle={i18n.t("cancel")}
            onDismiss={this.handleUnlinkAllConnectionsDialogOnDismiss}
            handlePositiveButtonClick={this.handleUnlinkAllConnectionsDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleUnlinkAllConnectionsDialogOnDismiss}
          />
        )}
        {showDisconnectAndTrackManuallyDialog === true && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={i18n.t("disconnectAndTrackManuallyDialog.title")}
            description={i18n.t("disconnectAndTrackManuallyDialog.description")}
            positiveButtonTitle={
              this.state.unlinkDialogCount > 1
                ? i18n.t("disconnectAndTrackManuallyDialog.primaryButton") + ` ${this.state.unlinkDialogCount} rows`
                : i18n.t("disconnectAndTrackManuallyDialog.primaryButton")
            }
            negativeButtonTitle={i18n.t("cancel")}
            onDismiss={this.handleDisconnectAndTrackManuallyDialogOnDismiss}
            handlePositiveButtonClick={this.handleDisconnectAndTrackManuallyDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleDisconnectAndTrackManuallyDialogOnDismiss}
          />
        )}
        {this.state.showReconnectDialog === true && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={i18n.t("reconnectDialog.title")}
            description={this.getReconnectDialogDescription(this.state.reconnectDialogRow.linkType)}
            positiveButtonTitle={
              this.state.reconnectDialogRow.linkType === accountLinkingService.ZILLOW ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.CARS ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.DOMAINS
                ? i18n.t("ok")
                : i18n.t("reconnect")
            }
            negativeButtonTitle={
              this.state.reconnectDialogRow.linkType === accountLinkingService.ZILLOW ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.CARS ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.DOMAINS
                ? null
                : i18n.t("updateValues")
            }
            tertiaryButtonTitle={
              this.state.reconnectDialogRow.linkType === accountLinkingService.ZILLOW ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.CARS ||
              this.state.reconnectDialogRow.linkType === accountLinkingService.DOMAINS
                ? null
                : i18n.t("goBackLink")
            }
            onDismiss={this.handleUnlinkAllConnectionsDialogOnDismiss}
            handlePositiveButtonClick={this.handleReconnectDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleReconnectDialogNegativeButtonClick}
            handleTertiaryButtonClick={this.handleReconnectDialogDismissButtonClick}
          />
        )}
        {this.state.deleteType === 1 && (
          <ConfirmationDialog
            title={i18n
              .t("removeRowTitle")
              .replace(/%category%/, category.ASSET)
              .replace("Remove", archiveOrRemove)}
            description={i18n
              .t("removeCompleteDesc")
              .replace(/%custodian_name%/, this.state.rowNameToDelete)
              .replace("remove", archiveOrRemove.toLowerCase())}
            positiveButtonTitle={i18n.t("remove").replace("Remove", archiveOrRemove)}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
            handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
          />
        )}
        {this.state.deleteType === 2 && (
          <ConfirmationDialog
            title={i18n
              .t("removeRowTitle")
              .replace(/%category%/, category.ASSET)
              .replace("Remove", archiveOrRemove)}
            description={i18n
              .t("removeLinkedDesc")
              .replace(/%custodian_name%/, this.state.rowNameToDelete)
              .replace("remove", archiveOrRemove.toLowerCase())}
            positiveButtonTitle={i18n.t("remove").replace("Remove", archiveOrRemove)}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
            handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
          />
        )}
        {this.state.deleteType === 3 && (
          <ConfirmationDialog
            title={
              i18n
                .t("removeRows")
                .replace(/%s1%/, this.state.numberOfRowsToDelete)
                .replace("Remove", archiveOrRemove) + "?"
            }
            description={i18n
              .t("removeHoldingRow.description")
              .replace(/%s1%/, this.state.numberOfRowsToDelete)
              .replace(/%s2%/, this.state.rowNameToDelete)
              .replace("remove", archiveOrRemove.toLowerCase())}
            positiveButtonTitle={i18n
              .t("removeRows")
              .replace(/%s1%/, this.state.numberOfRowsToDelete)
              .replace("Remove", archiveOrRemove)}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
            handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
          />
        )}
        {this.state.deleteType === 4 && (
          <ConfirmationDialog
            title={i18n.t("removeLinkedPortfolioRows").replace("Remove", archiveOrRemove)}
            description={i18n
              .t("removeLinkedPortfolioRows.description")
              .replace("remove", archiveOrRemove.toLowerCase())}
            positiveButtonTitle={i18n.t("remove").replace("Remove", archiveOrRemove)}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
            handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
          />
        )}
        <AddModal
          ref={this.addModalRef}
          isReadOnlyWlClient={this.props.isReadOnlyWlClient}
          handleMenuItemClick={this.handleAddNewContextMenuSelection}
          isEmpty={this.state.gridData.isEmpty()}
          gridData={gridData}
          isComingFromEmptyScreenRef={this.isComingFromEmptyScreenRef}
        />
        <GridPageHandleSheetIdHash isComingFromEmptyScreenRef={this.isComingFromEmptyScreenRef} />
        {!this.state.moveDialogForData === false && (
          <MoveItemDialog
            item={this.state.moveDialogForData.item}
            itemType={this.state.moveDialogForData.itemType}
            defaultCategory={category.ASSET}
            onDismiss={this.handleMoveDialogDismiss}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const portfolio = currentPortfolioSelector(state);
  return {
    fetchPortfolioPending: fetchPortfolioPendingSelector(state),
    lastDashboardUpdateTs: lastDashboardUpdateTsSelector(state),
    dashboardEntitesToUpdate: dashboardEntitesToUpdateSelector(state),
    portfolio,
    portfolioCurrency: currentPortfolioCurrencySelector(state),
    userPreferences: userPreferencesSelector(state),
    hasUserLinkedAnyAccounts: hasUserLinkedAnyAccounts(state),
    userCountryName: userCountryNameSelector(state),
    canShowWhatsNewTip: canShowWhatsNewTip(state),
    isInitialTipShown: isInitialTipShown(state, currentPortfolioSelector(state), category.ASSET),
    accountCurrentTs: accountCurrentTsSelector(state),
    accountEndTs: accountEndTsSelector(state),
    accountGraceTs: accountGraceTsSelector(state),
    accountStartTs: accountStartTsSelector(state),
    accountSubscriptionStatus: accountSubscriptionStatusSelector(state),
    accountClientCurrentTs: accountClientCurrentTsSelector(state),
    accountSubscriptionIsActive: accountSubscriptionIsActiveSelector(state),
    connectedLinkType: connectedLinkTypeSelector(state),
    isReadOnlyWlClient: isReadOnlyWlClient(state),
    siteConfig: siteConfigSelector(state),
    portfolioChangeData: currentPortfolioChangeDataSelector(state),
    maskAllValues: userMaskAllValuesSelector(state),
    getUnfundedCommitmentForCustodian: getUnfundedCommitmentForCustodian.bind(state),
    connectivityCenterDataForPortfolio: connectivityCenterDataForPortfolioSelector(state, portfolio.id),
    getOwnershipValueForCustodian: getOwnershipValueForCustodian.bind(state),
    getTickerUsingId: getTickerUsingId.bind(state),
    isInternalUser: isInternalUserSelector(state),
    user: userSelector(state)
  };
};

const mapDispatchToProps = {
  updateCustodianLocal: updateCustodianLocal,
  updateCustodian: updateCustodian,
  insertCustodian: insertCustodian,
  insertCustodianAtEndOfSection: insertCustodianAtEndOfSection,
  archiveCustodian: archiveCustodian,
  deleteCustodian: deleteCustodian,
  deleteLocalCustodian: deleteCustodianAction,
  insertSection: insertSection,
  updateSection: updateSection,
  updateSheet: updateSheet,
  bulkChangeCustodianStarStatus: bulkChangeCustodianStarStatus,
  bulkChangeCustodianUpdatedStatus: bulkChangeCustodianUpdatedStatus,
  archiveSection: archiveSection,
  archiveSheet: archiveSheet,
  insertSheet: insertSheet,
  unlinkAccountWithCustodian: unlinkAccountWithCustodian,
  unlinkAllConnectedCustodians: unlinkAllConnectedCustodians,
  updateUserPreferences: updateUserPreferences,
  showToastTip: showToastTip,
  dismissToastAction: dismissToastAction,
  moveCustodian: moveCustodian,
  fetchCustodianTickerDetails: fetchCustodianTickerDetails,
  setLinkType: setLinkType,
  fetchPortfolioChangeData: fetchPortfolioChangeData,
  unlinkAndManuallyTrackAllConnectedCustodians: unlinkAndManuallyTrackAllConnectedCustodians,
  getConnectivityCenterData: getConnectivityCenterData
};

export default isMobileDevice
  ? MobileAssetsWrapperComponent
  : connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(withInitialFetchHandle(AssetsComponent)));
