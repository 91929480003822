import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import {
  getUuid,
  updateCustodianHistory,
  updateCustodian,
  getCustodianHistoryFormattedDateString,
  convertCustodianHistoryApiDateFormatToUIFormat,
  guessDateInCustodianHistoryUIFormat,
  getTickerUsingId,
  tickerTypes,
  showToastTip,
  getSortKeyBetween,
  fetchTickerDetails,
  deleteCustodianHistory,
  isAppInViewMode,
  fetchCustodianDetailsHistory,
  refreshCustodian,
  getDateStringForExchangeRateDate,
  formatNumberAsCurrency,
  getSanitizedExchangeRate,
  updateUserPreferences,
  userPreferencesSelector,
  getExchangeRate,
  getExchangeRateDetails,
  getTickerUsingShortName,
  shortenTickerIfNeeded,
  ApiClient,
  fetchCustodianDetails,
  copyCustodianHistoryAfterDate,
  isCustodianHistoryRefreshingSelector,
  converUIDateFormatToCustodianHistoryApiDateFormat,
  guessDateFromUserInput,
  convertPVSTRateToValueExchangeRate,
  isValidFromToCurrencyRate,
  // isCustodianChartRefreshingSelector,
  custodianChartIsPendingSelector,
  getCustodianChartAction
} from "@kubera/common";
import { connect } from "react-redux";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import LineChartComponent from "components/charts/LineChartComponent";
import emptyLineChartBackground from "assets/images/details_line_chart_empty.png";
import { ReactComponent as DownArrowIcon } from "assets/images/menu_downarrow.svg";
import Loader from "components/loader/Loader";
import InViewPort from "components/in_viewport/InViewPort";
import ChangeLabel from "components/labels/ChangeLabel";
import ExchangeRateChangeDialog from "components/grid/ExchangeRateChangeDialog";
import ToolTip, { toolTipAlignment } from "components/tooltip/ToolTip";
import { ReactComponent as DownloadIcon } from "assets/images/download_icon.svg";
import { downloadFile } from "utilities/FileUtils";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .chartjs-buffer {
    margin-top: -3px;
  }
`;

const ChartDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -25px;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;
  margin-bottom: 15px;
  margin-top: 10px;
`;

const EmptyLineChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url(${emptyLineChartBackground});
  background-repeat: round;
  background-position: top;
  background-size: cover;
`;

const EmptyChartMessage = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -25px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
`;

const ChartLoading = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: black;
`;

const DetailsLineChartComponent = styled(LineChartComponent)`
  height: 100%;
`;

const ChartHeader = styled.div`
  display: flex;
  margin-top: 15px;
  height: 40px;
`;

const ChartChangeContainer = styled.div`
  visibility: ${props => (!props.hidden === false ? "hidden" : "visible")};
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const ChartChangeTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
`;

const ChartChangeLabel = styled(ChangeLabel)`
  margin-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;

  .child-container {
    justify-content: flex-start;
  }
`;

const SelectTimeRangeButtonContainer = styled.div`
  visibility: ${props => (!props.hidden === false ? "hidden" : "visible")};
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const SelectTimeRangeButton = styled.button`
  background-color: transparent;
  height: 16px;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  text-transform: uppercase;
`;

const SelectTimeRangeButtonIcon = styled(DownArrowIcon)`
  margin-left: 3px;
  margin-bottom: 1px;
`;

const GridContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
  position: relative;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.popoverBackgroundColor};
  z-index: 10000;
`;

const RefreshLoader = styled(Loader)`
  height: auto;
  margin-top: 100px;
`;

const Grid = styled(GridComponentWrapper)`
  flex: 1;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const ExchangeRateCellsContainer = styled.div`
  display: flex;
  position: relative;
`;

const ExchangeRateCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 3px 5px 3px 5px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(36, 36, 36, 0.6);
  cursor: ${props => (props.isPrivateTicker ? "default" : "pointer")};
  user-select: ${props => (props.isPrivateTicker ? "none" : null)};
`;

const ExchangeCurrencies = styled.div``;

const ExchangeRate = styled.div``;

const DownloadButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  z-index: 100;
`;

const DownloadButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const CSVDownloadIcon = styled(DownloadIcon)`
  pointer-events: none;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const DownloadLoaderContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class CustodianAssetValueComponent extends React.Component {
  constructor(props) {
    super(props);
    this.getEmptyRow = this.getEmptyRow.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);
    this.handleCellBlur = this.handleCellBlur.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
    this.onScrollToBottom = this.onScrollToBottom.bind(this);
    this.handleRateCellClick = this.handleRateCellClick.bind(this);
    this.handleRateCellDialogOnChange = this.handleRateCellDialogOnChange.bind(this);
    this.handleRateCellDialogOnDismiss = this.handleRateCellDialogOnDismiss.bind(this);

    props.history.sort((a, b) => b.date.localeCompare(a.date));
    this.state = {
      gridData: this.getGridData(props.currency, props.custodianChartData),
      showRateCellDialog: false
    };

    this.exchangeRateTipRef = React.createRef();
    this.showExchangeRateToolTipIfNeeded();
  }

  getHistory(history) {
    return history
      .filter(
        item =>
          (item.value !== null || item.value !== undefined) &&
          convertCustodianHistoryApiDateFormatToUIFormat(item.date) !==
            getCustodianHistoryFormattedDateString(new Date().getTime())
      )
      .sort((a, b) => b.date.localeCompare(a.date))
      .reduce((acc, item) => {
        if (!acc.find(i => i.date === item.date)) {
          acc.push(item);
        }
        return acc;
      }, []);
  }

  componentDidUpdate(oldProps) {
    if (
      this.state.gridData.currency !== this.props.currency ||
      JSON.stringify(this.props.history) !== JSON.stringify(oldProps.history) ||
      this.props.rate !== oldProps.rate ||
      JSON.stringify(this.props.custodianChartData) !== JSON.stringify(oldProps.custodianChartData) ||
      (this.props.isHistoryRefreshing === false && oldProps.isHistoryRefreshing === true) ||
      this.props.value !== oldProps.value
    ) {
      this.setState({
        gridData: this.getGridData(this.props.currency, this.props.custodianChartData)
      });
    }

    this.showExchangeRateToolTipIfNeeded();
  }

  isTodaysHistoryEntryPresent() {
    const dateStringForToday = getCustodianHistoryFormattedDateString(new Date().getTime());
    const history = this.getConsolidatedDatForGrid();
    if (history.length === 0) {
      return false;
    }
    return dateStringForToday === convertCustodianHistoryApiDateFormatToUIFormat(history[0].date);
  }

  getOwnershipPercentageForDate(date) {
    if (!date === true || !this.props.ownership === true || this.props.ownership.length === 0) {
      return 100;
    }

    this.props.ownership.sort((a, b) => b.date.localeCompare(a.date));

    for (const ownership of this.props.ownership) {
      const ownershipDate = new Date(ownership.date);
      ownershipDate.setHours(0, 0, 0, 0);

      if (date.getTime() >= ownershipDate.getTime()) {
        return ownership.percentage;
      }
    }
    return 100;
  }

  getRateForDate = date => {
    if (!date === true || !this.props.rate === true || this.props.rate.length === 0) {
      return undefined;
    }

    this.props.rate.sort((a, b) => b.date.localeCompare(a.date));

    for (const rate of this.props.rate) {
      const rateDate = new Date(rate.date);
      rateDate.setHours(0, 0, 0, 0);

      if (date.getTime() >= rateDate.getTime() && rate.rate) {
        return JSON.parse(rate.rate);
      }
    }
    return undefined;
  };

  getConsolidatedDatForGrid = () => {
    const history = this.getHistory(this.props.history);
    const portfolioCurrencyTickerData = getTickerUsingShortName(this.props.currency);

    const shouldOverrideDummyEntryFromChartData =
      portfolioCurrencyTickerData?.id ===
      (this.props.detailsInfo.valueTickerId === 171
        ? JSON.parse(this.props.detailsInfo?.rate)?.t
        : this.props.detailsInfo.valueTickerId);

    const consolidatedDataPoints =
      !this.props.custodianChartData || shouldOverrideDummyEntryFromChartData
        ? [...history]
        : [
            ...history,
            ...this.props.custodianChartData?.chart?.groupByDay?.data,
            ...this.props.custodianChartData?.chart?.groupByWeek?.data,
            ...this.props.custodianChartData?.chart?.groupByMonth?.data,
            ...this.props.custodianChartData?.chart?.groupByYear?.data
          ]
            .reduce((acc, curr) => {
              if (!acc.find(item => item.date === curr.date)) {
                const data = JSON.parse(JSON.stringify(curr));
                data.isDummyEntry = data.id ? false : true;
                if (!data.id) {
                  data.tickerId = this.props.custodianChartData?.chart?.groupByDay?.tickerId;
                }
                acc.push(data);
              }
              return acc;
            }, [])
            .filter(data => {
              return data.isDummyEntry &&
                convertCustodianHistoryApiDateFormatToUIFormat(data.date) ===
                  getCustodianHistoryFormattedDateString(new Date().getTime())
                ? false
                : true;
            });
    return consolidatedDataPoints.sort((a, b) => b.date.localeCompare(a.date));
  };

  getGridData(currency, custodianChartData) {
    var rows = [];
    let nextSortKey = null;
    const consolidatedDataPoints = this.getConsolidatedDatForGrid();
    const dateStringForToday = getCustodianHistoryFormattedDateString(new Date().getTime());
    for (let historyEntry of consolidatedDataPoints) {
      if (historyEntry.value === undefined || historyEntry.value === null) {
        continue;
      }
      if (historyEntry.isDummyEntry) {
        // find the last date entry and get details from there
        const lastHistoryData = consolidatedDataPoints.find(
          item => item.date <= historyEntry.date && item.isDummyEntry === false
        );
        if (!lastHistoryData) {
          continue;
        }

        if (lastHistoryData.valueTickerId === 171) {
          let valueExchangeRate = JSON.parse(lastHistoryData.valueExchangeRate);
          valueExchangeRate.rate = Math.abs(historyEntry.value / lastHistoryData.value);
          valueExchangeRate.date = historyEntry.date;
          historyEntry = {
            ...lastHistoryData,
            id: getUuid(),
            date: historyEntry.date,
            isDummyEntry: historyEntry.isDummyEntry,
            valueExchangeRate: JSON.stringify(valueExchangeRate)
          };
        } else if (lastHistoryData.valueTickerId === historyEntry.tickerId) {
          historyEntry = {
            ...lastHistoryData,
            id: getUuid(),
            value: historyEntry.value,
            date: historyEntry.date,
            isDummyEntry: historyEntry.isDummyEntry,
            valueTickerId: historyEntry.tickerId
          };
        } else {
          let valueExchangeRate = JSON.parse(lastHistoryData.valueExchangeRate);
          valueExchangeRate.rate = Math.abs(historyEntry.value / lastHistoryData.value);
          valueExchangeRate.date = historyEntry.date;
          historyEntry = {
            ...lastHistoryData,
            id: getUuid(),
            date: historyEntry.date,
            isDummyEntry: historyEntry.isDummyEntry,
            value: lastHistoryData.value,
            valueExchangeRate: JSON.stringify(valueExchangeRate)
          };
        }
      }
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyRow(nextSortKey, historyEntry);
      row.id = historyEntry.id;
      row.cells[0].value = convertCustodianHistoryApiDateFormatToUIFormat(historyEntry.date);
      row.isDummyEntry = historyEntry.isDummyEntry;
      //for current day history entry get value from quantity text box
      row.cells[1].value =
        dateStringForToday === convertCustodianHistoryApiDateFormatToUIFormat(historyEntry.date)
          ? this.props.value
          : historyEntry.value;
      row.cells[1].ownership = historyEntry.isDummyEntry
        ? 100
        : this.getOwnershipPercentageForDate(new Date(historyEntry.date));
      row.cells[1].rate = this.getRateForDate(new Date(historyEntry.date));

      if (historyEntry?.valueTickerId) {
        row.cells[1].currency = getTickerUsingId(historyEntry.valueTickerId).shortName;
        row.cells[1].valueTickerId = historyEntry.valueTickerId;

        if (historyEntry?.valueTickerId === 171) {
          const parsedExchangeRate = JSON.parse(historyEntry?.valueExchangeRate);
          if (parsedExchangeRate) {
            if (historyEntry.isDummyEntry) {
              row.cells[1].exchangeRateDetails = JSON.stringify(parsedExchangeRate);
            } else {
              parsedExchangeRate.rate = row.cells[1].rate ? parsedExchangeRate.rate * row.cells[1].rate.p : 0;
              row.cells[1].exchangeRateDetails = JSON.stringify(parsedExchangeRate);
            }
          }
        } else if (historyEntry?.valueExchangeRate) {
          row.cells[1].exchangeRateDetails = historyEntry.valueExchangeRate;
        }
      }

      row.cells[1].exchangeRateDate = new Date(historyEntry.date);
      row.cells[1].showDecimal = false;

      if (historyEntry?.valueTickerId === 171) {
        row.cells[1].allowPVST = true;
      }

      row.tsModified = historyEntry?.lastModified;
      rows.push(row);
    }

    if (rows.length === 0) {
      rows.push(this.getEmptyRow("1"));
    }

    if (this.isTodaysHistoryEntryPresent() === false) {
      const row = this.getEmptyRow("1");
      row.cells[0].value = getCustodianHistoryFormattedDateString(new Date().getTime());
      row.cells[1].value = this.props.value;
      row.cells[1].currency = getTickerUsingId(this.props.valueTickerId).shortName;
      row.cells[1].ownership = this.getOwnershipPercentageForDate(new Date());
      row.cells[1].rate = this.getRateForDate(new Date());

      const rate = getExchangeRate(row.cells[1].currency, this.props.currency);
      row.cells[1].exchangeRateDetails = getExchangeRateDetails(getTickerUsingShortName(this.props.currency).id, rate);
      row.cells[1].exchangeRateDate = new Date();

      rows.unshift(row);

      if (this.props.detailsInfo.valueTickerId === 171) {
        rows[0].cells[1].exchangeRateDetails = this.props.convertPVSTRateToValueExchangeRate(
          JSON.parse((this.props.rate && this.props.rate[0]?.rate) || null),
          undefined,
          true
        );
      }
    }

    if (rows[0].cells[0].value === dateStringForToday) {
      rows[0].cells[0].value = i18n.t("today");
      rows[0].cells[0].isEditable = false;
      rows[0].cells[0].hideCellFocusHighlight = true;
      rows[0].cells[1].allowPVST = true;
    }

    rows[0].cells[1].isEditable = this.props.isCustodianLinked === false;

    for (let i = 0; i < Math.min(rows.length, 1); i++) {
      rows[i].cells[2].hide = true;
    }

    const section = this.getEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey) {
    const dateColumn = new GridColumnData("Date", true, this.props.isReadOnly === false, false);
    const valueColumn = new GridColumnData("Value", true, this.props.isReadOnly === false, false);
    const optionsColumn = new GridColumnData(null, true, false, true);
    const columns = [dateColumn, valueColumn, optionsColumn];

    if (this.props.isReadOnly === true) {
      columns.pop();
    }

    const sectionData = new GridSectionData(getUuid(), sortKey, "Section " + (forIndex + 1), [], columns, 1, 1, false);
    sectionData.showFooter = false;
    return sectionData;
  }

  getEmptyRow(sortKey, historyEntry) {
    const dateCell = new GridCellData(cellType.TEXT, "Date", null);
    dateCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const valueCell = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[1];
      if (
        this.props.currency === valueCell.currency ||
        !valueCell.exchangeRateDate === true ||
        valueCell.loading === true
      ) {
        return null;
      }

      const cellData = {
        exchangeRateDate: valueCell.exchangeRateDate,
        fromCurrency: valueCell.currency,
        toCurrency: this.props.currency,
        rate: valueCell.getCellExchangeRate(this.props.currency),
        sanitizedRate: getSanitizedExchangeRate(
          valueCell.getCellExchangeRate(this.props.currency),
          this.props.currency
        ),
        sheetIndex: sheetIndex,
        sectionIndex: sectionIndex,
        rowIndex: rowIndex,
        cellIndex: 1
      };

      const rateCellId = `${rowIndex}-${cellIndex}-ratecell`;
      if (!this.firstRateCellId === true) {
        this.firstRateCellId = rateCellId;
      }

      if (!isNaN(cellData.sanitizedRate) && valueCell.currency !== "PVST") {
        return (
          <ExchangeRateCellsContainer>
            <ExchangeRateCell
              id={rateCellId}
              onClick={e => this.handleRateCellClick(e, cellData)}
              isPrivateTicker={valueCell.valueTickerId === 171}
            >
              <ExchangeCurrencies>{`${shortenTickerIfNeeded(valueCell.currency)}/${shortenTickerIfNeeded(
                this.props.currency
              )}`}</ExchangeCurrencies>
              <ExchangeRate>{formatNumberAsCurrency(cellData.sanitizedRate, this.props.currency)}</ExchangeRate>
            </ExchangeRateCell>
            {this.firstRateCellId === rateCellId && (
              <ToolTip ref={this.exchangeRateTipRef} targetId={this.firstRateCellId} align={toolTipAlignment.LEFT} />
            )}
          </ExchangeRateCellsContainer>
        );
      }

      return null;
    };

    const valueCell = new CurrencyCellData(cellType.CURRENCY, "Value", null, this.props.currency);
    valueCell.useRateFromExchangeRateDetails = true;
    if (this.props.detailsInfo.valueTickerId === 171) {
      valueCell.allowPVST = true;
    }
    valueCell.supportedTickerTypes = [
      tickerTypes.FIAT,
      tickerTypes.CRYPTO,
      tickerTypes.STOCK,
      tickerTypes.FUND,
      tickerTypes.BOND,
      tickerTypes.DERIVATIVE,
      tickerTypes.INDEX
    ];
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    const cells = [dateCell, valueCell, optionsCell];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
      const date = cells[0].value;
      const value = cells[1].value;
      const guessedDate = guessDateInCustodianHistoryUIFormat(date);

      if (guessedDate.isInvalid === false && value !== null && value !== undefined) {
        return true;
      }
      return false;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      if (this.props.isReadOnly === true || rowIndex === 0) {
        return null;
      }
      const menuItems = [[contextMenuItemType.INSERT_ABOVE], [contextMenuItemType.COPY_VALUE_ABOVE]];
      if (!!historyEntry?.isDummyEntry === false) {
        menuItems.push([contextMenuItemType.DELETE]);
      }
      return menuItems;
    };
    rowData.onUpdateDelay = 5000;
    return rowData;
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    if (updatedRow.isComplete() === false) {
      return;
    }
    this.props.getCustodianChartAction();
    this.entryForTodayDone = false;
    this.handleHistoryUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, this.props.getCustodianChartData);
  }

  handleHistoryUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, onSuccess = () => {}) {
    const guessedDate = guessDateInCustodianHistoryUIFormat(updatedRow.cells[0].value);
    if (
      guessedDate.isInvalid === true ||
      this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)
    ) {
      return;
    }

    const valueCell = this.state.gridData.sheets[0].sections[0].rows[rowIndex].cells[1];
    valueCell.rate = this.getRateForDate(guessedDate.date);

    if (
      valueCell.currency !== this.props.currency &&
      (getDateStringForExchangeRateDate(valueCell.exchangeRateDate) !==
        getDateStringForExchangeRateDate(guessedDate.date) ||
        (updatedRow.cells[1].getTickerId() === 171 &&
          !this.props.isValidFromToCurrencyRate(
            getTickerUsingId(valueCell.rate?.t).shortName,
            this.props.currency,
            undefined,
            valueCell.exchangeRateDate,
            updatedRow.cells[1].getTickerId()
          )))
    ) {
      valueCell.invalidInputText = valueCell.currency;
      valueCell.exchangeRateDate = guessedDate.date;
      this.handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, 1);
      return;
    }

    const isNewHistoryEntry = updatedRow.tsModified === 0 || updatedRow.isDummyEntry;
    if (isNewHistoryEntry === true) {
      updatedRow.tsModified = new Date().getTime() / 1000;
    }

    const updateHistory = () => {
      this.props.updateHistory(
        isNewHistoryEntry,
        this.props.sectionId,
        this.props.custodianId,
        {
          id: updatedRow.id,
          date: guessedDate.dateString,
          value: updatedRow.cells[1].value,
          valueTickerId: updatedRow.cells[1].getTickerId(),
          valueExchangeRate: updatedRow.cells[1].exchangeRateDetails,
          rate: updatedRow.cells[1].rate
        },
        false,
        () => {
          if (this.isOldestRow(updatedRow, sheetIndex, sectionIndex)) {
            // If the oldest row is edited / added fire a custodian refresh to ensure the marketDateStart is updated correctly
            this.props.refreshCustodian(this.props.custodianId);
          }
          onSuccess();
        }
      );

      if (updatedRow.cells[1].getTickerId() === 171) {
        this.setState({
          gridData: this.getGridData(this.props.currency, this.props.custodianChartData)
        });
      }

      if (
        this.entryForTodayDone === false &&
        this.isTodaysHistoryEntryPresent() === false &&
        guessedDate.dateString !== getCustodianHistoryFormattedDateString(new Date().getTime()) &&
        new Date(guessedDate.dateString).getTime() >=
          new Date(
            convertCustodianHistoryApiDateFormatToUIFormat(this.getHistory(this.props.history)[0].date)
          ).getTime()
      ) {
        this.entryForTodayDone = true;
        this.handleHistoryUpdate(
          sheetIndex,
          sectionIndex,
          0,
          this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[0]
        );
      }
    };

    const shouldUpdateCustodian = this.shouldUpdateCustodian(updatedRow.cells[0].value);
    if (shouldUpdateCustodian) {
      const propertiesToUpdate = {
        value: updatedRow.cells[1].value,
        valueTickerId: updatedRow.cells[1].getTickerId(),
        valueExchangeRate: updatedRow.cells[1].exchangeRateDetails,
        past: 0
      };
      this.props.updateCustodian(false, this.props.custodianId, propertiesToUpdate);
      this.props.onCustodianUpdate(propertiesToUpdate);
      updateHistory();
    } else {
      updateHistory();
    }
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.DELETE.id) {
      // If user tries to delete the top most history row and a history entry
      // for today is not present make an entry for today so that the top history
      // value and the custodian value are in sync
      this.props.getCustodianChartAction();
      if (rowIndex === 1 && this.isTodaysHistoryEntryPresent() === false) {
        const todaysRow = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[0];
        this.props.updateHistory(
          todaysRow.tsModified === 0,
          this.props.sectionId,
          this.props.custodianId,
          {
            id: todaysRow.id,
            date: todaysRow.cells[0].value,
            value: todaysRow.cells[1].value,
            valueTickerId: todaysRow.cells[1].getTickerId(),
            valueExchangeRate: todaysRow.cells[1].exchangeRateDetails,
            rate: todaysRow.cells[1].rate
          },
          false
        );
      }
      this.props.deleteHistory(
        this.props.sectionId,
        this.props.custodianId,
        row.id,
        false,
        this.props.getCustodianChartData
      );
    } else if (menuItem.id === contextMenuItemType.COPY_VALUE_ABOVE.id) {
      this.props.getCustodianChartAction();
      const todaysRow = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[0];
      const valueAndDate = {
        date: converUIDateFormatToCustodianHistoryApiDateFormat(row.cells[0].value),
        value: row.cells[1].value,
        valueTickerId: row.cells[1].getTickerId(),
        valueExchangeRate:
          todaysRow.cells[1].getTickerId() === 171
            ? this.props.convertPVSTRateToValueExchangeRate(todaysRow.cells[1].rate, undefined, false)
            : todaysRow.cells[1].exchangeRateDetails
      };
      this.props.copyHistoryAfterDate(this.props.sectionId, this.props.custodianId, valueAndDate, custodian => {
        const propertiesToUpdate = {
          value: custodian.value,
          valueTickerId: custodian.valueTickerId,
          valueExchangeRate: custodian.valueExchangeRate
        };
        this.props.onCustodianUpdate(propertiesToUpdate, true, () => {
          this.props.getCustodianChartData();
        });
      });
    }
  }

  shouldUpdateCustodian(updatedRowDateString) {
    if (!updatedRowDateString === true) {
      return false;
    }

    const updatedRowDate = guessDateInCustodianHistoryUIFormat(updatedRowDateString);
    if (updatedRowDate.isInvalid === true) {
      return false;
    }

    for (const row of this.state.gridData.sheets[0].sections[0].rows) {
      if (!row.cells[0].value === false) {
        const rowDate = guessDateFromUserInput(row.cells[0].value);
        if (rowDate.getTime() > updatedRowDate.date.getTime()) {
          return false;
        }
      }
    }
    return true;
  }

  handleAddNewRow(sheetIndex, sectionIndex, rowIndex, row) {}

  handleCellBlur(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    if (cellIndex === 0) {
      const cellValue = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex]
        .value;
      if (!cellValue === true) {
        return;
      }

      const guessedDate = guessDateInCustodianHistoryUIFormat(cellValue);
      var dateString = guessedDate.dateString;
      const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];

      if (guessedDate.isInvalid === true) {
        this.props.showToastTip("TIP", i18n.t("invalidDateEntry"), null, 3000);
        if (!dateString === true) {
          dateString = cellValue;
        }
        row.cells[0].invalidInputText = cellValue;
      } else if (this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)) {
        this.props.showToastTip("TIP", i18n.t("duplicateDateEntry"), null, 3000);
        row.cells[0].invalidInputText = cellValue;
      } else {
        row.cells[0].invalidInputText = null;
      }

      const dateStringForToday = getCustodianHistoryFormattedDateString(new Date().getTime());
      row.cells[0].value = dateString === dateStringForToday ? i18n.t("today") : dateString;

      row.cells[1].ownership = this.getOwnershipPercentageForDate(guessedDate.date);
      row.cells[1].rate = this.getRateForDate(guessedDate.date);
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
    }
  }

  isOldestRow(row, sheetIndex, sectionIndex) {
    var min = null;
    for (const row of this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows) {
      if (!row.cells[0].value === false) {
        const tempDate = new Date(row.cells[0].value);
        if (!min === true) {
          min = row.cells[0].value;
        }

        if (tempDate.getTime() < new Date(min).getTime()) {
          min = row.cells[0].value;
        }
      }
    }

    if (min === row.cells[0].value && !row.cells[1].value === false) {
      return true;
    }
    return false;
  }

  isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, dateString) {
    if (!dateString === true) {
      return false;
    }
    const existingEntryIndex = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows.findIndex(
      (row, index) => rowIndex !== index && row.cells[0].value === dateString
    );
    return existingEntryIndex !== -1 && existingEntryIndex !== rowIndex;
  }

  updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      let invalidInputText = cell.invalidInputText;
      if (cell.getTickerId() === 171) {
        const rateParsed = cell.rate || JSON.parse(this.props.detailsInfo.rate || null);
        invalidInputText = rateParsed ? getTickerUsingId(rateParsed.t).shortName : cell.currency;
      }
      cell.loading = true;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
      const date = guessDateInCustodianHistoryUIFormat(row.cells[0].value).date;
      this.props.fetchTickerDetails(
        invalidInputText,
        date,
        result => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          if (!result === true) {
            this.props.showToastTip("TIP", i18n.t("invalidTickerError"), null, 10000);
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          if (cell.getTickerId() === 171) {
            cell.exchangeRateDetails = this.props.convertPVSTRateToValueExchangeRate(
              cell.rate,
              guessDateInCustodianHistoryUIFormat(row.cells[0].value).dateString
            );
            cell.currency = "PVST";
            cell.invalidInputText = null;
          } else {
            cell.exchangeRateDetails = result.exchangeRateDetails;
            cell.currency = result.tickerShortName;
            cell.invalidInputText = null;
          }

          const newRow = row.clone();
          this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
          this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
        },
        error => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  onScrollToBottom() {
    if (!this.props.isFetchPending && !this.props.isCustodianChartFetchPending && !this.props.isHistoryLoaded) {
      const detailsHistory = this.props.history.filter(item => item.timestamp);
      if (detailsHistory && detailsHistory[detailsHistory.length - 1]) {
        const { timestamp } = detailsHistory[detailsHistory.length - 1];
        this.props.fetchDetailsHistory(this.props.custodianId, timestamp);
      }
    }
  }

  handleRateCellClick(e, cellData) {
    if (cellData.fromCurrency === "PVST" || this.props.isReadOnly === true) {
      return;
    }
    if (this.exchangeRateTipRef.current && this.exchangeRateTipRef.current.isVisible()) {
      this.exchangeRateTipRef.current.dismiss();
    }
    this.setState({ showRateCellDialog: true, rateCellDialogData: cellData });
  }

  handleRateCellDialogOnDismiss() {
    this.setState({ showRateCellDialog: false, rateCellDialogData: null });
  }

  handleRateCellDialogOnChange(rate) {
    const rateData = this.state.rateCellDialogData;
    const newGridData = this.state.gridData;

    const row = newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex];
    const cell = row.cells[rateData.cellIndex];
    cell.setExchangeRateDetails(this.props.currency, rate);
    newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex] = row.clone();

    this.setState({ gridData: newGridData });
    this.handleHistoryUpdate(
      rateData.sheetIndex,
      rateData.sectionIndex,
      rateData.rowIndex,
      row,
      this.props.getCustodianChartData
    );
  }

  showExchangeRateToolTipIfNeeded() {
    if (this.props.rate?.length > 0 || this.props.isReadOnly) {
      return;
    }

    setTimeout(() => {
      const gridData = this.state.gridData;
      if (!this.exchangeRateTipRef === true || !this.exchangeRateTipRef.current === true || !gridData === true) {
        return;
      }
      if (this.props.userPreferences.valueHistoryExchangeRateToolTipShown === true) {
        return;
      }
      this.exchangeRateTipRef.current.show(i18n.t("exchangeRateTip"), -5, 0, () => {
        this.props.updateUserPreferences({ valueHistoryExchangeRateToolTipShown: true });
      });
    }, 500);
  }

  handleDownloadButtonClick(e, custodianId) {
    this.setState({ ...this.state, showDownloadLoader: true });
    ApiClient.getCustodianSubDetailsDownloadUrl(custodianId, "valueHistory", "CSV")
      .then(url => {
        downloadFile(url);
        this.setState({ ...this.state, showDownloadLoader: false });
      })
      .catch(apiError => {
        this.setState({ ...this.state, showDownloadLoader: false });
      });
  }

  render() {
    const gridData = this.state.gridData;
    const canShowChart = this.props.canShowChart;
    const lineChartData = this.props.lineChartData;
    const custodianChartRequestPending = this.props.custodianChartRequestPending;
    const currentChartTimeRangeText = this.props.currentChartTimeRangeText;
    const custodianId = this.props.custodianId;
    const showRefreshLoader = this.props.isHistoryRefreshing === true;
    //|| this.props.isCustodianChartFetchPending;
    const showTabLoader = this.props.isFetchPending && !this.props.isHistoryLoaded && !showRefreshLoader;

    return (
      <Container className={this.props.className}>
        <DownloadButtonContainer>
          {!this.state.showDownloadLoader && (
            <DownloadButton onClick={e => this.handleDownloadButtonClick(e, custodianId)}>
              <CSVDownloadIcon></CSVDownloadIcon>
            </DownloadButton>
          )}
          {this.state.showDownloadLoader && (
            <DownloadLoaderContainer>
              <Loader />
            </DownloadLoaderContainer>
          )}
        </DownloadButtonContainer>
        {canShowChart === true && (
          <ChartDetailsContainer>
            <ChartHeader>
              <ChartChangeContainer hidden={!lineChartData === true}>
                <ChartChangeTitle>{i18n.t("dayChange")}</ChartChangeTitle>
                <ChartChangeLabel
                  currency={this.props.currency}
                  startValue={this.props.previousDayValue}
                  endValue={Math.floor(this.props.valueInPortfolioCurrency)}
                  alignPosition="left"
                />
              </ChartChangeContainer>
              <ChartChangeContainer hidden={!lineChartData === true}>
                <ChartChangeTitle>{currentChartTimeRangeText}</ChartChangeTitle>
                <ChartChangeLabel
                  currency={this.props.currency}
                  startValue={this.props.chartStartValue}
                  endValue={Math.floor(this.props.valueInPortfolioCurrency)}
                  alignPosition="left"
                />
              </ChartChangeContainer>
              <SelectTimeRangeButtonContainer hidden={!lineChartData === true && custodianChartRequestPending === true}>
                <SelectTimeRangeButton onClick={this.props.handleChartTimeRangeSelectionButtonClick}>
                  {currentChartTimeRangeText}
                  <SelectTimeRangeButtonIcon />
                </SelectTimeRangeButton>
              </SelectTimeRangeButtonContainer>
            </ChartHeader>
            <ChartContainer>
              {!lineChartData === false && (
                <DetailsLineChartComponent
                  data={lineChartData}
                  chartHeight={120}
                  onHoverOverSection={this.props.handleChartHoverSelection}
                  chartPadding={{
                    left: 0,
                    right: 0,
                    top: 20,
                    bottom: 3
                  }}
                  isWithinDetails
                  showBuffer
                />
              )}
              {!lineChartData === true && custodianChartRequestPending === true && (
                <ChartLoading>{i18n.t("detailsChartLoading")}</ChartLoading>
              )}
              {!lineChartData === true && custodianChartRequestPending === false && (
                <EmptyLineChartContainer>
                  <EmptyChartMessage>{i18n.t("detailsChartEmpty")}</EmptyChartMessage>
                </EmptyLineChartContainer>
              )}
            </ChartContainer>
          </ChartDetailsContainer>
        )}
        <GridContainer>
          <Grid
            title={i18n.t("assetsComponent.title")}
            gridData={gridData}
            getEmptyRow={this.getEmptyRow}
            onChange={this.handleChange.bind(this)}
            onRowUpdate={this.handleRowUpdate}
            onAddNewRow={this.handleAddNewRow}
            onCellBlur={this.handleCellBlur}
            onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
            addRowBtnTxt={
              isAppInViewMode() || this.props.isReadOnly
                ? null
                : this.props.isHistoryLoaded && i18n.t("custodianDetails.addHistoryBtnTxt")
            }
            onRowContextMenuSelection={this.handleRowContextMenuSelection}
          />
          {showRefreshLoader && (
            <LoaderOverlay>
              <RefreshLoader />
            </LoaderOverlay>
          )}
        </GridContainer>
        {showTabLoader && <Loader />}
        <InViewPort onVisible={this.onScrollToBottom} />
        {!this.state.showRateCellDialog === false && (
          <ExchangeRateChangeDialog
            rateData={this.state.rateCellDialogData}
            onRateChange={this.handleRateCellDialogOnChange}
            onDismiss={this.handleRateCellDialogOnDismiss}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userPreferences: userPreferencesSelector(state),
  isHistoryRefreshing: isCustodianHistoryRefreshingSelector(state),
  convertPVSTRateToValueExchangeRate: convertPVSTRateToValueExchangeRate.bind(state),
  isValidFromToCurrencyRate: isValidFromToCurrencyRate.bind(state),
  // isCustodianChartRefreshing: isCustodianChartRefreshingSelector(state),
  isCustodianChartFetchPending: custodianChartIsPendingSelector(state)
});

const mapDispatchToProps = {
  fetchDetailsHistory: fetchCustodianDetailsHistory,
  updateHistory: updateCustodianHistory,
  updateCustodian: updateCustodian,
  showToastTip: showToastTip,
  fetchTickerDetails: fetchTickerDetails,
  deleteHistory: deleteCustodianHistory,
  refreshCustodian: refreshCustodian,
  updateUserPreferences: updateUserPreferences,
  fetchDetails: fetchCustodianDetails,
  copyHistoryAfterDate: copyCustodianHistoryAfterDate,
  getCustodianChartAction: getCustodianChartAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodianAssetValueComponent);
