import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";

import {
  getPreferredMFA,
  isAuthenticatedByThirdPartyProvider,
  MFA_PREFERENCES,
  userOnlyGoogleAuthenticatedSelector,
  userShowSetPasswordSelector
} from "@kubera/common";
import TwoFAModal from "components/dialog/TwoFAModal";
import TurnOffModal from "components/dialog/TurnOffModal";
import MFADisabledAlert from "components/dialog/MFADisabledAlert";
import TwoFAVerify from "components/dialog/TwoFAVerify";

const TwoFAContainer = styled.div`
  padding-bottom: 38px;
  margin-bottom: 26px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`;

const TwoFAtitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 18px;
`;

const SetUpTwoFABtn = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  text-decoration-line: underline;
  cursor: pointer;
  color: ${props => props.theme.linkColor};
  margin-bottom: 6px;
`;

const TwoFADesc = styled.div`
  font-size: 13px;
  line-height: 140%;
`;

const TwoFADescThirdP = styled(TwoFADesc)`
  margin-bottom: -13px;
`;

const SelectedOption = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  background: rgba(0, 0, 0, 0.05);
  padding: 20px;
  box-sizing: border-box;
`;

const DefaultTxt = styled.div`
  font-size: 13px;
  line-height: 150%;
  color: #333333;
  margin-bottom: 3px;
`;

const SelectedOptionDisplay = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
  margin-bottom: 30px;
`;

const SelectedOptionTOTPDisplay = styled(SelectedOptionDisplay)`
  margin-bottom: 5px;
`;

const Options = styled.div`
  display: flex;
  font-size: 10px;
  line-height: 12px;
`;

const TOTPOptions = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.7);
`;

const Option = styled.div`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const GenerateBackupBlk = styled.div`
  box-sizing: border-box;
  padding: 15px 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 24px;
`;

const GenerateBackupAction = styled.span`
  cursor: pointer;
`;

const TurnOffMFAAction = styled.span`
  cursor: pointer;
  font-size: 14px;
  line-height: 150%;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.8);
`;

const TwoFALink = styled.a`
  font-size: 13px;
  line-height: 140%;
  color: ${props => props.theme.linkColor};
`;

let initLoginTime = null;

const MFAContainer = props => {
  const timePassedAfterLogin = initLoginTime
    ? Math.floor(((new Date().getTime() - initLoginTime.getTime()) / 1000 / 60) << 0)
    : 11;

  const userOnlyGoogleAuthenticated = useSelector(userOnlyGoogleAuthenticatedSelector);
  const userShowSetPassword = useSelector(userShowSetPasswordSelector);

  const [isTwoFAVerifyOpen, setIsTwoFAVerifyOpen] = useState(timePassedAfterLogin > 10);
  const [isSignedUpUser, setIsSignedUpUser] = useState(true);
  const [is2FAModalOpen, setIs2FAModalOpen] = useState(false);
  const [isCodesModalOpen, setIsCodesModalOpen] = useState(false);
  const [isDisabledAlertOpen, setIsDisabledAlertOpen] = useState(false);
  const [isTurnOffModalOpen, setIsTurnOffModalOpen] = useState(false);
  const [mfaPreference, setMfaPreference] = useState(null);
  const [userAttrs, setUserAttrs] = useState(null);

  const getMFA = async () => {
    const preference = await getPreferredMFA();

    setMfaPreference(preference.default);
    setUserAttrs(preference.attrs);
  };

  const openTwoFAModal = () => {
    setIs2FAModalOpen(true);
  };

  const closeTwoFAModal = () => {
    setIs2FAModalOpen(false);
  };

  const setIsMFAPossible = useCallback(() => {
    const cb = async () => {
      const preference = await getPreferredMFA();
      setIsSignedUpUser(!userOnlyGoogleAuthenticated && !userShowSetPassword && preference.default);
    };

    cb();
  }, [userOnlyGoogleAuthenticated, userShowSetPassword]);

  const turnOffMFA = () => {
    setIsTurnOffModalOpen(true);
  };

  const turnOffMFADismiss = () => {
    setIsTurnOffModalOpen(false);
  };

  const onRemove2FA = () => {
    getMFA();
    turnOffMFADismiss();
  };

  const openCodesModal = () => {
    setIsCodesModalOpen(true);
  };

  const closeCodesModal = () => {
    setIsCodesModalOpen(false);
  };

  const closeDisabledAlert = () => {
    setIsDisabledAlertOpen(false);
  };

  const showAlert = () => {
    if (localStorage.getItem("mfa_disabled")) {
      localStorage.removeItem("mfa_disabled");
      setIsDisabledAlertOpen(true);
    }
  };

  const closeTwoFAVerify = () => {
    getMFA();
    showAlert();
    setIsTwoFAVerifyOpen(false);
    initLoginTime = new Date();
  };

  useEffect(() => {
    getMFA();
  }, [is2FAModalOpen]);

  useEffect(() => {
    if (mfaPreference === MFA_PREFERENCES.NONE) {
      setIsTwoFAVerifyOpen(false);
    }
  }, [mfaPreference]);

  useEffect(() => {
    setIsMFAPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedUpUser]);

  useEffect(showAlert, []);

  if (mfaPreference === MFA_PREFERENCES.SMS) {
    return (
      <TwoFAContainer className={props.className}>
        <TwoFAtitle>
          {i18n.t("accountSettings.twoFAtitle")} ({i18n.t("enabled")})
        </TwoFAtitle>
        <SelectedOption>
          <DefaultTxt>{` ${i18n.t("twoFA.defaultMfaSMSTitle")}`}</DefaultTxt>
          <SelectedOptionDisplay data-private>{userAttrs && userAttrs.phone_number}</SelectedOptionDisplay>
          <Options>
            <Option onClick={openTwoFAModal}>{i18n.t("change")}</Option>
          </Options>
        </SelectedOption>
        <GenerateBackupBlk>
          <GenerateBackupAction onClick={openCodesModal}>{i18n.t("twoFA.generateCodesTxt")}</GenerateBackupAction>
        </GenerateBackupBlk>
        <div>
          <TurnOffMFAAction onClick={turnOffMFA}>{i18n.t("twoFA.turnOffTxt")}</TurnOffMFAAction>
        </div>
        <TwoFAModal
          initUserAttrs={userAttrs}
          isOpen={is2FAModalOpen}
          onDismiss={closeTwoFAModal}
          displayOption={0}
          displayStep={0}
        />
        <TurnOffModal isOpen={isTurnOffModalOpen} onRemove2FA={onRemove2FA} onDismiss={turnOffMFADismiss} />
        <TwoFAModal
          initUserAttrs={userAttrs}
          isOpen={isCodesModalOpen}
          onDismiss={closeCodesModal}
          displayStep={3}
          isCodesDismissOptTwo
        />
        <TwoFAVerify isOpen={isTwoFAVerifyOpen} onDismiss={closeTwoFAVerify} />
      </TwoFAContainer>
    );
  }

  if (mfaPreference === MFA_PREFERENCES.TOTP) {
    return (
      <TwoFAContainer className={props.className}>
        <TwoFAtitle>
          {i18n.t("accountSettings.twoFAtitle")} ({i18n.t("enabled")})
        </TwoFAtitle>
        <SelectedOption>
          <DefaultTxt>{` ${i18n.t("twoFA.defaultMfaAuthenticatorTitle")}`}</DefaultTxt>
          <SelectedOptionTOTPDisplay>{i18n.t("twoFA.option2txtLong")}</SelectedOptionTOTPDisplay>
          <TOTPOptions>{i18n.t("twoFA.option2descLong")}</TOTPOptions>
        </SelectedOption>
        <GenerateBackupBlk>
          <GenerateBackupAction onClick={openCodesModal}>{i18n.t("twoFA.generateCodesTxt")}</GenerateBackupAction>
        </GenerateBackupBlk>
        <div>
          <TurnOffMFAAction onClick={turnOffMFA}>{i18n.t("twoFA.turnOffTxt")}</TurnOffMFAAction>
        </div>
        <TwoFAModal
          initUserAttrs={userAttrs}
          isOpen={is2FAModalOpen}
          onDismiss={closeTwoFAModal}
          displayOption={0}
          displayStep={0}
        />
        <TurnOffModal isOpen={isTurnOffModalOpen} onRemove2FA={onRemove2FA} onDismiss={turnOffMFADismiss} />
        <TwoFAModal
          initUserAttrs={userAttrs}
          isOpen={isCodesModalOpen}
          onDismiss={closeCodesModal}
          displayStep={3}
          isCodesDismissOptTwo
        />
        <TwoFAVerify isOpen={isTwoFAVerifyOpen} onDismiss={closeTwoFAVerify} />
      </TwoFAContainer>
    );
  }

  if (isSignedUpUser) {
    return (
      <TwoFAContainer className={props.className}>
        <TwoFAtitle>{i18n.t("accountSettings.twoFAtitle")}</TwoFAtitle>
        <SetUpTwoFABtn onClick={openTwoFAModal}>{i18n.t("accountSettings.setUpTwoFALink")}</SetUpTwoFABtn>
        <TwoFADesc>{i18n.t("accountSettings.twoFADesc")}</TwoFADesc>
        <TwoFAModal
          initUserAttrs={userAttrs}
          isOpen={is2FAModalOpen}
          onDismiss={closeTwoFAModal}
          displayOption={0}
          displayStep={0}
        />
        <MFADisabledAlert isOpen={isDisabledAlertOpen} onDismiss={closeDisabledAlert} />
      </TwoFAContainer>
    );
  }

  return (
    <TwoFAContainer className={props.className}>
      <TwoFAtitle>{i18n.t("accountSettings.twoFAtitle")}</TwoFAtitle>
      <TwoFADescThirdP>
        {i18n.t("accountSettings.twoFAThirdParty")}
        <TwoFALink href="https://myaccount.google.com/security" target="_blank" rel="noopener noreferrer">
          https://myaccount.google.com/security
        </TwoFALink>
      </TwoFADescThirdP>
    </TwoFAContainer>
  );
};

export default MFAContainer;
