import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import menuIcon from "assets/images/menu_downarrow.svg";
import menuIconDark from "assets/images/menu_downarrow_dark.svg";
import optionsIcon from "assets/images/options.svg";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import {
  deletePortfolio,
  portfoliosSelector,
  currentPortfolioSelector,
  hasValidPortfolioSelector,
  updatePortfolio,
  getSymbolForTickerUsingShortName,
  signOut,
  updateDashboardAction,
  exportPortfolio,
  userNameSelector,
  userEmailSelector,
  accountPlanSelector,
  accountCurrentTsSelector,
  accountEndTsSelector,
  accountSubscriptionStatusSelector,
  accountRemainingDaysSelector,
  getSubscriptionEndDateString,
  userStorageUsage,
  userStorageLimit,
  subscriptionSelector,
  wlClientContextSelector,
  isAmcUser,
  ApiClient,
  userProfilePictureTokenSelector,
  getUserProfilePhotoUrl,
  isAppInViewMode,
  userPreferencesSelector,
  accountCreationTsSelector,
  updateUserPreferences,
  isCurrentPortfolioEmpty,
  isMetaKeyPressed,
  siteConfigSelector,
  currentPortfolioCurrencySelector,
  currentPortfolioNameSelector,
  getUpdatedIrr,
  showToastTip,
  getMetaKeyName,
  getHashParams,
  fetchPortfolioPendingSelector,
  showRefreshingSelector,
  userMaskAllValuesSelector,
  getCurrentPortfolioDataInNestedFormat,
  currentThemeMode,
  fetchNetWorthDataForPortfolio,
  fetchPortfolios,
  sharedPortfolioUsersSelector,
  userSelector,
  getPortfolioSessionUserId,
  store,
  sharedPortfolioAccountTitleSelector,
  sharedPortfolioAccountSubtitleSelector,
  switchToSharedPortfolioUserAccount,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  isSharedPortfolioUserSelector,
  multiuserListForCurrentPortfolioSelector,
  isAppInWhiteLabelMode,
  fetchUser,
  multiuserListSelector,
  showAPIBadgeTipSelector,
  CHATBOTS_DATA_DOWNLOAD_RELEASE_TS,
  userTypes,
  isReadOnlyWlClient
} from "@kubera/common";
import { hashParams, routes } from "routes";
import ContextMenu from "components/contextmenu/ContextMenu";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import PortfolioNameComponent from "components/portfolio/PortfolioNameComponent";
import DashboardComponentExports from "components/dashboard/DashboardComponentExports";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import PortfolioDownloadDialog from "components/portfolio/PortfolioDownloadDialog";
import UserProfileImage from "components/inputs/UserProfileImage";
import AccountSettingsComponentExports, {
  accountSettingsTabs
} from "components/account_settings/AccountSettingsComponentExports";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import CurrencyPickerComponent from "components/dashboard/CurrencyPickerComponent";
import AccountSubscribeActionTxt from "components/account_settings/AccountSubscribeActionTxt";
import { downloadFile } from "utilities/FileUtils";
import { ReactComponent as ShareIcon } from "assets/images/share.svg";
import ShareROLinkComponent from "components/multiuser/ShareROLinkComponent";
import CollaboratePortfolioComponent from "components/multiuser/CollaboratePortfolioComponent";
import DeletePortfolioConfirmationDialog from "components/dashboard/DeletePortfolioConfirmationDialog";
import { ReactComponent as SearchIcon } from "assets/images/search_icon.svg";
import SearchPortfolioComponent from "components/dashboard/SearchPortfolioComponent";
import TopLineLoader from "components/loader/TopLineLoader";
import RadioButton from "components/inputs/RadioButton";
import { ThemeSwitchComponent } from "theme";
import { ReactComponent as RefreshIcon } from "assets/images/desktop_refresh_icon.svg";
import Spinner from "components/loader/Spinner";
import PortfolioTreeDialog from "components/portfolio/PortfolioTreeDialog";
import DownloadChatbotsDataDialog from "components/dashboard/DownloadChatbotsDataDialog";
import RedDot from "components/indicators/RedDot";

const Container = styled.div`
  background-color: ${props => props.theme.dashboardHeaderBackgroundColor};
  color: ${props => props.theme.dashboardHeaderColor};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  font-feature-settings: "ss01" on;
`;

const ProfileImageWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
`;

const ProfileImage = styled(UserProfileImage)`
  margin-right: 23px;
`;

const HeaderButton = styled.div`
  cursor: ${props => (props.isClickable === true ? "pointer" : "auto")};
  margin-left: 20px;
  background-color: transparent
  background-repeat: no-repeat;
  background-position: right;
  background-size: ${props => (props.isClickable === true ? "9px 9px" : "0px")};
  background-image: url(${props => (props.theme.mode === "default" ? menuIcon : menuIconDark)});   
  padding-right: 12px;
  font-size: 14px;
  `;

const PortfolioNameButton = styled(HeaderButton)`
  margin-right: 20px;
  text-transform: capitalize;
`;

const SearchButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const SearchPortfolioIcon = styled(SearchIcon)`
  pointer-events: none;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const PortfolioShareButton = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const SharingRedDot = styled(RedDot)`
  position: absolute;
  display: inline-block;
  top: 9px;
  left: 3px;
  width: 8px;
  height: 8px;
  background: "#FF6464";
  border: 2px solid #fcfcfc;
`;

const PortfolioShareIcon = styled(ShareIcon)`
  pointer-events: none;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const TickerButton = styled(HeaderButton)``;

const AutoUpdatesAction = styled(AccountSubscribeActionTxt)`
  cursor: pointer;
  margin-right: 20px;
`;

const PortfolioMenuItem = styled.div`
  height: 40px;
  padding-right: 15px;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: ${props => (props.isLast === true ? "10px" : "0px")};

  &:hover {
    background-color: ${props => props.theme.contextMenuItemSelectedBackgroundColor};
  }
`;

const ThemeMenuItem = styled(PortfolioMenuItem)``;

const PortfolioName = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-style: normal;
  font-weight: ${props => (props.isSelected === true ? "bold" : "normal")};
  font-size: 13px;
  line-height: 16px;
  color: "#000000";
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  text-transform: capitalize;
`;

const ActionButton = styled.button`
  width: 30px;
  height: 30px;
  min-width: 30px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px 14px;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const PortfolioOptionButton = styled(ActionButton)`
  background-image: url(${optionsIcon});
`;

const PortfolioButtonContainer = styled.div`
  border-top: 1px solid #0000001a;
`;

const NewPortfolioButton = styled(PortfolioMenuItem)`
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 36px;
  font-size: 13px;
  padding-left: 30px;

  &:hover {
    background-color: ${props => props.theme.contextMenuItemSelectedBackgroundColor};
  }
`;

const PortfolioTreeButton = styled(NewPortfolioButton)`
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding-top: 10px;
  margin-bottom: 0px;
  cursor: pointer;
`;

const PortfolioTreeTitle = styled.div`
  font-size: 13px;
`;

const PortfolioTreeDescription = styled.div`
  margin-top: 2px;
  line-height: 12px;
  color: ${props => props.theme.contextMenuItemDescriptionColor};
  font-size: 10px;
`;

const TickerContextMenu = styled(ContextMenu)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const ThemeContextMenu = styled(ContextMenu)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const ThemeSwitch = styled(ThemeSwitchComponent)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const PortfolioContextMenu = styled(ContextMenu)`
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 650px;
`;

const MaskValuesBadge = styled.div`
  width: 7px;
  height: 7px;
  margin-top: ${props => (props.marginTop ? props.marginTop : "none")};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "none")};
  //border: 3px solid ${props => props.theme.badgeBorderCLR};
  border-radius: 50%;
  background-color: #2f8fff;'
`;

const RefreshButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  cursor: pointer;
`;

const RefreshButtonIcon = styled(RefreshIcon)`
  width: 17px;

  path {
    fill: ${props => props.theme.desktopRefreshButtonIconColor};
    fill-opacity: 1 !important;
  }
`;
const SpinIt = styled(Spinner)`
  width: 14px;
  height: 14px;
`;

class DashboardHeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleMaskValues = this.toggleMaskValues.bind(this);
    this.handlePortfolioButtonClick = this.handlePortfolioButtonClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleNewPortfolioButtonClick = this.handleNewPortfolioButtonClick.bind(this);
    this.handleTickerButtonClick = this.handleTickerButtonClick.bind(this);
    this.handleTickerSelection = this.handleTickerSelection.bind(this);
    this.handleThemeSelection = this.handleThemeSelection.bind(this);
    this.handleAccountSettingsButtonClick = this.handleAccountSettingsButtonClick.bind(this);
    this.handleDialogNegativeButtonClick = this.handleDialogNegativeButtonClick.bind(this);
    this.handleDialogPositiveButtonClick = this.handleDialogPositiveButtonClick.bind(this);
    this.showSubscriptionModal = this.showSubscriptionModal.bind(this);
    this.handleEmptyPortfolioDialogDismiss = this.handleEmptyPortfolioDialogDismiss.bind(this);
    this.handlePortfolioDownloadDataClick = this.handlePortfolioDownloadDataClick.bind(this);
    this.handlePortfolioDownloadDataDocumentClick = this.handlePortfolioDownloadDataDocumentClick.bind(this);
    this.handlePortfolioShareButtonClick = this.handlePortfolioShareButtonClick.bind(this);
    this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
    this.handlePortfolioTreeButtonClick = this.handlePortfolioTreeButtonClick.bind(this);
    this.handlePortfolioTreeDialogDismiss = this.handlePortfolioTreeDialogDismiss.bind(this);

    this.state = {
      contextMenuForPortfolio: null,
      showDeletePortfolioDialog: false,
      showEmptyPortfolioDialog: false,
      profilePictureUrlUserMap: {},
      isForceRefreshClick: false,
      showPortfolioTreeDialog: false
    };

    this.profilePictureUrlUserMap = {};

    this.portfolioMenuRef = React.createRef();
    this.tickerMenuRef = React.createRef();
    this.themeMenuRef = React.createRef();
    this.contextMenuRef = React.createRef();
    this.portfolioOptionsMenuRef = React.createRef();
    this.sharingRedDotRef = React.createRef();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.profilePictureToken !== this.props.profilePictureToken) {
      this.getProfilePicture(this.props.user.id);
    } else if (oldProps.sharedPortfolioUsers !== this.props.sharedPortfolioUsers) {
      if (this.props.sharedPortfolioUsers.length > 0) {
        this.props.sharedPortfolioUsers.forEach(user => {
          this.getProfilePicture(user.id, user.picture);
        });
      }
    }

    if (oldProps.portfoliosFetchPending === true && this.props.portfoliosFetchPending === false) {
      this.setState({
        isForceRefreshClick: false
      });
    }
  }

  getProfilePicture(userId, pictureToken = undefined) {
    this.props.getUserProfilePhotoUrl(
      url => {
        this.profilePictureUrlUserMap[userId] = url;
        this.setState({ profilePictureUrlUserMap: this.profilePictureUrlUserMap });
      },
      apiError => {},
      pictureToken
    );
  }

  componentDidMount() {
    if (localStorage.getItem("mfa_disabled")) {
      AccountSettingsComponentExports.show(this.props.history, this.props.location, accountSettingsTabs.SECURITY);
    }
    addKeyboardEventListener(this.handleKeyDown);
    if (this.props.user) {
      this.getProfilePicture(this.props.user.id);
    }
    if (this.props.sharedPortfolioUsers.length > 0) {
      this.props.sharedPortfolioUsers.forEach(user => {
        this.getProfilePicture(user.id, user.picture);
      });
    }
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown);
  }

  handleKeyDown(e) {
    // key is shown as 8 in mac & * in windows
    if (isMetaKeyPressed(e) === true && e.shiftKey === true && (e.key === "8" || e.key === "*")) {
      this.toggleMaskValues();
      e.preventDefault();
      return true;
    } else if (
      (isMetaKeyPressed(e) === true && e.key === "g") ||
      (e.key === "/" && e.target && e.target.tagName.toLowerCase() === "body")
    ) {
      const urlHashParams = getHashParams(this.props.location);
      const modalValue = urlHashParams[hashParams.MODAL];
      if (!modalValue === true) {
        SearchPortfolioComponent.show(this.props.history, this.props.location);
        e.preventDefault();
        return true;
      }
    }
  }

  handlePortfolioTreeButtonClick(e) {
    this.portfolioMenuRef.current.dismiss();
    this.setState({ showPortfolioTreeDialog: true });
  }

  handlePortfolioTreeDialogDismiss() {
    this.setState({ showPortfolioTreeDialog: false });
  }

  handlePortfolioButtonClick(event) {
    if (this.portfolioMenuRef.current.isVisible() === true) {
      this.portfolioMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    this.portfolioMenuRef.current.show(
      [],
      targetPosition.left + targetPosition.width - this.portfolioMenuRef.current.getWidth(),
      targetPosition.top + targetPosition.height + 10,
      true,
      event.target
    );
  }

  handlePortfolioSelection(portfolio) {
    this.portfolioMenuRef.current.dismiss();
    this.props.getUpdatedIrr(portfolio.id);
    DashboardComponentExports.showPortfolio(this.props.history, this.props.location, portfolio.id);
  }

  handlePortfolioDownloadDataClick() {
    const portfolio = this.props.currentPortfolio;
    this.portfolioMenuRef.current.dismiss();

    if (this.props.hasValidPortfolio) {
      this.props.showToastTip("TIP", i18n.t("downloadingPortfolioExcel"), null, 3000);

      ApiClient.getPortfolioDownloadDataUrl(portfolio.id)
        .then(apiResponse => {
          downloadFile(apiResponse.payload.url, portfolio.name);
        })
        .catch(apiError => {});
    } else {
      this.setState({ showEmptyPortfolioDialog: true });
    }
  }

  handlePortfolioDownloadDataDocumentClick() {
    const portfolio = this.props.currentPortfolio;
    this.portfolioMenuRef.current.dismiss();

    if (this.props.hasValidPortfolio) {
      this.props.exportPortfolio(portfolio.id, portfolio.name);
      PortfolioDownloadDialog.show(this.props.history, this.props.location);
    } else {
      this.setState({ showEmptyPortfolioDialog: true });
    }
  }

  handleEmptyPortfolioDialogDismiss() {
    this.setState({ showEmptyPortfolioDialog: false });
  }

  handlePortfolioContextMenuButtonClick(event, portfolio) {
    if (this.portfolioOptionsMenuRef.current.isVisible() === true) {
      this.portfolioOptionsMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();

    var menuItems = [[contextMenuItemType.RENAME_PORTFOLIO]];
    if (this.props.portfolios.length > 1) {
      menuItems[0].push(contextMenuItemType.DELETE);
    }

    this.portfolioOptionsMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height + 2,
      false,
      event.target
    );
    this.setState({ ...this.state, contextMenuForPortfolio: portfolio });
  }

  handleAccountSettingsButtonClick(event) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();

    const currentDate = getSubscriptionEndDateString(this.props.accountCurrentTs);
    const oneLessEndTs = getSubscriptionEndDateString(this.props.accountEndTs - 86400);
    const remainingDays = this.props.remainingDays;
    const isTodayTomorrow = remainingDays === 0 || currentDate === oneLessEndTs;
    const displayDate = isTodayTomorrow ? getSubscriptionEndDateString(this.props.accountEndTs) : oneLessEndTs;
    const trialEndLabel = i18n.t("accountSettings.subscriptionOnTrial").replace("%s%", displayDate);

    var users = [
      ...(isAppInWhiteLabelMode() && this.props.user.wl?.type === userTypes.SUB_USER ? [] : [this.props.user]),
      ...this.props.sharedPortfolioUsers
    ];
    const portfolioUserId = getPortfolioSessionUserId();
    if (!portfolioUserId === false) {
      users = [users.find(item => item.id === portfolioUserId), ...users.filter(item => item.id !== portfolioUserId)];
    }

    const userPortfoliosMenuItems = users.map(user => {
      const item = { ...contextMenuItemType.USER_PORTFOLIOS };

      if (user.id === this.props.user.id) {
        item.label = this.props.userName;
        item.subtext = this.props.userEmail;
        if (
          portfolioUserId === user.id &&
          this.props.accountPlan === SUBSCRIPTION_PLANS.TRIAL &&
          this.props.accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE
        ) {
          item.subtext += `\n${trialEndLabel}`;
        }
        item.isSelf = true;
      } else {
        item.label = sharedPortfolioAccountTitleSelector(store.getState(), user);
        item.subtext = sharedPortfolioAccountSubtitleSelector(store.getState(), user);
      }
      item.profileImageUrl = this.profilePictureUrlUserMap[this.props.user.id];
      item.secondaryImageUrl = this.profilePictureUrlUserMap[user.id];
      item.user = user;
      item.isSelected = !portfolioUserId === false ? user.id === portfolioUserId : user.id === this.props.user.id;
      item.disabled = item.isSelected;
      return [item];
    });

    const accountSettingsItem = {
      ...contextMenuItemType.ACCOUNT_SETTINGS,
      showBadge: this.props.showAPIBadgeTip
    };
    const signoutItem = { ...contextMenuItemType.SIGN_OUT };
    let menuItems = [...userPortfoliosMenuItems, [accountSettingsItem, signoutItem]];

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.right,
      targetPosition.top + targetPosition.height + 10,
      false,
      event.target
    );
  }

  handleContextMenuSelection(item, event) {
    const portfolio = this.state.contextMenuForPortfolio;
    this.setState({ ...this.state, contextMenuForPortfolio: null });

    if (item.id === contextMenuItemType.RENAME_PORTFOLIO.id) {
      PortfolioNameComponent.showEditPortfolio(this.props.history, this.props.location, portfolio.id);
    } else if (item.id === contextMenuItemType.DELETE.id) {
      this.setState({ ...this.state, showDeletePortfolioDialog: true });
    } else if (item.id === contextMenuItemType.SIGN_OUT.id) {
      signOut();
    } else if (item.id === contextMenuItemType.ACCOUNT_SETTINGS.id) {
      AccountSettingsComponentExports.show(this.props.history, this.props.location, accountSettingsTabs.ACCOUNT);
    } else if (item.id === contextMenuItemType.DOWNLOAD_PORTFOLIO_DATA.id) {
      this.handlePortfolioDownloadDataClick();
    } else if (item.id === contextMenuItemType.DOWNLOAD_PORTFOLIO_DATA_DOCUMENT.id) {
      this.handlePortfolioDownloadDataDocumentClick();
    } else if (item.id === contextMenuItemType.SHARE_PORTFOLIO_RO_LINK.id) {
      ShareROLinkComponent.show(this.props.history, this.props.location);
    } else if (item.id === contextMenuItemType.COLLABORATE_PORTFOLIO.id) {
      CollaboratePortfolioComponent.show(
        this.props.history,
        this.props.location,
        this.props.portfolios,
        this.props.userList
      );
    } else if (item.id === contextMenuItemType.MASK_VALUES.id) {
      this.toggleMaskValues();
    } else if (item.id === contextMenuItemType.NETWORTH_STATEMENT.id) {
      window.kuberaOpen(`${process.env.PUBLIC_URL}${routes.NETWORTH_STATEMENT}`);
    } else if (item.id === contextMenuItemType.DARK_MODE.id) {
      this.handleThemeMenuClick(event);
    } else if (item.id === contextMenuItemType.USER_PORTFOLIOS.id) {
      if (item.isSelected === true) {
        AccountSettingsComponentExports.show(this.props.history, this.props.location, accountSettingsTabs.ACCOUNT);
      } else {
        switchToSharedPortfolioUserAccount(item.user);
      }
    } else if (item.id === contextMenuItemType.DOWNLOAD_DATA_CHATBOTS.id) {
      DownloadChatbotsDataDialog.show(this.props.history, this.props.location);

      if (this.props.userPreferences.isChatbotsDataDownloadTipShown === false) {
        this.props.updateUserPreferences({
          isChatbotsDataDownloadTipShown: true
        });
      }
    }
  }

  toggleMaskValues() {
    const toggleMaskAllValues = !this.props.maskAllValues;
    this.props.updateUserPreferences({ maskAllValues: toggleMaskAllValues }, undefined, undefined, true);
    this.props.updateDashboardAction(null);
  }

  showSubscriptionModal() {
    AccountSettingsComponentExports.show(this.props.history, this.props.location, accountSettingsTabs.SUBSCRIPTION);
  }

  handleNewPortfolioButtonClick() {
    this.portfolioMenuRef.current.dismiss();
    PortfolioNameComponent.showCreatePortfolio(this.props.history, this.props.location);
  }

  handleTickerButtonClick(event) {
    if (!this.tickerMenuRef.current) {
      return;
    }
    if (this.tickerMenuRef.current.isVisible() === true) {
      this.tickerMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    this.tickerMenuRef.current.show(
      [],
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height + 10,
      false,
      event.target
    );
  }

  handleThemeMenuClick(event) {
    if (!this.themeMenuRef.current) {
      return;
    }
    if (this.themeMenuRef.current.isVisible() === true) {
      this.themeMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    this.themeMenuRef.current.show(
      [],
      targetPosition.left + 20,
      targetPosition.top + targetPosition.height - 10,
      false,
      event.target
    );
  }

  handleTickerSelection(ticker) {
    this.tickerMenuRef.current.dismiss();
    const updatedPortfolio = this.props.currentPortfolio;
    updatedPortfolio.currency = ticker.shortName;
    this.props.updatePortfolio(updatedPortfolio);
    this.props.getUpdatedIrr(updatedPortfolio.id);
    this.props.fetchNetWorthData(updatedPortfolio.id, updatedPortfolio.currency);
  }

  handleThemeSelection() {
    this.themeMenuRef.current.dismiss();
  }

  getSortedPortfolios() {
    const portfolios = this.props.portfolios;
    portfolios.sort((a, b) => a.tsCreated - b.tsCreated);
    return portfolios;
  }

  handleDialogNegativeButtonClick(e) {
    this.setState({ ...this.state, showDeletePortfolioDialog: false });
  }

  handleDialogPositiveButtonClick(e) {
    this.setState({ ...this.state, showDeletePortfolioDialog: false }, () => {
      this.props.deletePortfolio(this.state.contextMenuForPortfolio, portfolioId => {
        DashboardComponentExports.showPortfolio(this.props.history, this.props.location, portfolioId);
      });
    });
  }

  getPortfolioTickerString() {
    const portfolioCurrency = this.props.currentPortfolioCurrency;
    const portfolioCurrencySymbol = getSymbolForTickerUsingShortName(portfolioCurrency);
    var tickerString = portfolioCurrency;

    if (portfolioCurrency !== portfolioCurrencySymbol) {
      tickerString = portfolioCurrency + " " + portfolioCurrencySymbol;
    }
    return tickerString.replace(".CC", "").replace(".INDX", "");
  }

  handlePortfolioShareButtonClick(event) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    if (
      this.props.assetsData &&
      this.props.assetsData.sheets.length === 0 &&
      this.props.debtsData &&
      this.props.debtsData.sheets.length === 0
    ) {
      contextMenuItemType.NETWORTH_STATEMENT.disabled = true;
    } else {
      contextMenuItemType.NETWORTH_STATEMENT.disabled = false;
    }

    const contextMenuMaskValuesMenuItem = contextMenuItemType.MASK_VALUES;
    const contextMenuNetWorthStatementMenuItem = contextMenuItemType.NETWORTH_STATEMENT;
    const label = this.props.maskAllValues === true ? i18n.t("unMaskValuesLabel") : i18n.t("maskValuesLabel");
    const description = `${getMetaKeyName()} Shift 8\nHelps screen sharing without revealing the numbers`;

    contextMenuMaskValuesMenuItem.onRender = () => {
      const Container = styled.div`
        display : flex
        flex-direction : column
      `;
      const LabelContainer = styled.div`
        display: flex;
        align-items: center;
      `;
      const Label = styled.div`
        flex : 1
        min-height: 8px;
        color: ${props => props.theme.contextMenuItemLabelColor};
        color: ${props =>
          props.isDisabled !== true
            ? props.theme.contextMenuItemLabelColor
            : props.theme.contextMenuItemDisabledLabelColor};
        text-align: left;
        font-style: normal;
        font-weight: ${props => (props.isSelected === true ? "bold" : "normal")};
      `;
      const Description = styled.div`
        color: ${props => props.theme.contextMenuItemDescriptionColor};
        text-align: left;
        line-height: 130%;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        word-wrap: break-word;
        width: 100%;
      `;
      return (
        <Container>
          <LabelContainer>
            <Label>{label}</Label>
            {this.props.maskAllValues && <MaskValuesBadge />}
          </LabelContainer>
          <Description>{description}</Description>
        </Container>
      );
    };

    contextMenuNetWorthStatementMenuItem.onRender = () => {
      const LabelContainer = styled.div`
        display: flex;
        flex: 1
        align-items: center;
      `;
      const Label = styled.div`
      flex : 1
      min-height: 8px;
      color: ${props => props.theme.contextMenuItemLabelColor};
      color: ${props =>
        props.isDisabled !== true
          ? props.theme.contextMenuItemLabelColor
          : props.theme.contextMenuItemDisabledLabelColor};
      text-align: left;
      font-style: normal;
      font-weight: ${props => (props.isSelected === true ? "bold" : "normal")};
    `;

      return (
        <LabelContainer>
          <Label isDisabled={contextMenuItemType.NETWORTH_STATEMENT.disabled}>
            {contextMenuItemType.NETWORTH_STATEMENT.label}
          </Label>
        </LabelContainer>
      );
    };

    const getDownloadChatbotsDataMenuItem = () => {
      const userTsCreated = this.props.user?.tsCreated;

      return {
        ...contextMenuItemType.DOWNLOAD_DATA_CHATBOTS,
        showBadge:
          this.props.userPreferences.isChatbotsDataDownloadTipShown === false &&
          userTsCreated &&
          userTsCreated * 1000 < CHATBOTS_DATA_DOWNLOAD_RELEASE_TS
      };
    };

    const getCollaboratePortfolioMenuItem = () => {
      return {
        ...contextMenuItemType.COLLABORATE_PORTFOLIO,
        description:
          isAppInWhiteLabelMode() && this.props.isAmcUser === false
            ? i18n.t("viewCollaboratorsList")
            : contextMenuItemType.COLLABORATE_PORTFOLIO.description
      };
    };

    var menuItems = [
      [contextMenuItemType.SHARE_PORTFOLIO_RO_LINK],
      [
        contextMenuItemType.NETWORTH_STATEMENT,
        getDownloadChatbotsDataMenuItem(),
        contextMenuItemType.DOWNLOAD_PORTFOLIO_DATA
      ],
      [contextMenuMaskValuesMenuItem]
    ];
    if (this.props.isReadOnlyWlClient === false) {
      menuItems[0].push(getCollaboratePortfolioMenuItem());
    }
    if (this.props.siteConfig.showDocument !== "hide") {
      menuItems[1] = [...menuItems[1], contextMenuItemType.DOWNLOAD_PORTFOLIO_DATA_DOCUMENT];
    } else if (isAppInViewMode()) {
      var darkModeMenuItem = contextMenuItemType.DARK_MODE;
      darkModeMenuItem.description = this.getLabelForTheme(this.props.currentThemeMode);

      menuItems = [
        [
          contextMenuItemType.NETWORTH_STATEMENT,
          getDownloadChatbotsDataMenuItem(),
          contextMenuItemType.DOWNLOAD_PORTFOLIO_DATA
        ],
        [contextMenuMaskValuesMenuItem],
        [contextMenuItemType.DARK_MODE]
      ];
    } else if (!this.props.currentPortfolio.details.document?.length === true) {
      menuItems = [
        [contextMenuItemType.SHARE_PORTFOLIO_RO_LINK],
        [
          contextMenuItemType.NETWORTH_STATEMENT,
          getDownloadChatbotsDataMenuItem(),
          contextMenuItemType.DOWNLOAD_PORTFOLIO_DATA
        ],
        [contextMenuMaskValuesMenuItem]
      ];
      if (this.props.isReadOnlyWlClient === false) {
        menuItems[0].push(getCollaboratePortfolioMenuItem());
      }
    }

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.right,
      targetPosition.top + targetPosition.height + 7,
      false,
      event.target
    );
  }

  getLabelForTheme(theme) {
    switch (theme) {
      case "default":
        return i18n.t("off");
      case "dark":
        return i18n.t("on");
      case "sts":
        return i18n.t("mobileHeader.sts");
      case "sp":
        return i18n.t("mobileHeader.sp");
      default:
        return "";
    }
  }

  handleSearchButtonClick(e) {
    SearchPortfolioComponent.show(this.props.history, this.props.location);
  }

  canSharePortfolio() {
    return isAppInViewMode() === false;
  }

  onRefreshButtonClick = () => {
    if (this.props.portfoliosFetchPending === false) {
      this.setState({
        isForceRefreshClick: true
      });
      this.props.fetchPortfolios(false, true);
      this.props.fetchUser();
    }
  };

  getSharedPortfolioUser = () => {
    const userId = getPortfolioSessionUserId();
    if (!userId === true || !this.props.user === true || this.props.user.id === userId) {
      return null;
    }
    return this.props.sharedPortfolioUsers.find(user => user.id === userId);
  };

  render() {
    const isInViewOnlyMode = isAppInViewMode();
    const wlClientContext = this.props.wlClientContext;
    const currentPortfolio = this.props.currentPortfolio;
    const portfolioTickerString = this.getPortfolioTickerString();
    const portfolios = this.getSortedPortfolios();
    const showDeletePortfolioDialog = this.state.showDeletePortfolioDialog;
    const isForceRefreshClick = this.state.isForceRefreshClick;
    const sharedPortfolioUser = this.getSharedPortfolioUser();
    const maxPortfolioCount =
      this.props.subscription && this.props.subscription.maxPortfolioCount
        ? this.props.subscription.maxPortfolioCount
        : 10;

    return (
      <Container className={this.props.className}>
        {isInViewOnlyMode === false && (
          <AutoUpdatesAction onClick={this.showSubscriptionModal} remainingDays={this.props.remainingDays} />
        )}
        <RefreshButton onClick={this.onRefreshButtonClick}>
          {isForceRefreshClick === false && <RefreshButtonIcon />}
          {isForceRefreshClick === true && <SpinIt thickness={3} />}
        </RefreshButton>
        {this.props.portfoliosFetchPending === true &&
          this.props.showRefreshing === false &&
          isForceRefreshClick === false && <TopLineLoader />}
        <SearchButton
          onClick={this.handleSearchButtonClick}
          title={i18n.t("searchToolTip").replace("%s%", getMetaKeyName())}
        >
          <SearchPortfolioIcon />
        </SearchButton>
        {isInViewOnlyMode === false && (
          <PortfolioShareButton
            data-cy={"portfolioShareButton"}
            title={this.canSharePortfolio() ? i18n.t("share") : i18n.t("downloadPortfolioToolTip")}
            onClick={this.handlePortfolioShareButtonClick}
          >
            {this.canSharePortfolio() && <PortfolioShareIcon />}
            {this.props.maskAllValues && <MaskValuesBadge marginTop="-18px" marginLeft="2px" />}
            <SharingRedDot
              ref={this.sharingRedDotRef}
              preference="isChatbotsDataDownloadTipShown"
              showIfUserBeforeThisTs={CHATBOTS_DATA_DOWNLOAD_RELEASE_TS}
            />
          </PortfolioShareButton>
        )}
        <TickerButton
          data-cy="tickerButton"
          isClickable={true}
          onClick={this.handleTickerButtonClick}
          title={i18n.t("currency")}
        >
          {portfolioTickerString}
        </TickerButton>
        <PortfolioNameButton
          data-cy="portfolioNameButton"
          data-private
          isClickable={true}
          onClick={isInViewOnlyMode === true ? this.handlePortfolioShareButtonClick : this.handlePortfolioButtonClick}
          title={i18n.t("portfolio")}
        >
          {this.props.currentPortfolioName}
        </PortfolioNameButton>
        {!wlClientContext === true && isInViewOnlyMode === false && (
          <ProfileImageWrapper onClick={this.handleAccountSettingsButtonClick}>
            <ProfileImage
              imageSize={28}
              borderSize={2}
              userName={this.props.userName}
              imageUrl={!this.props.user === false ? this.profilePictureUrlUserMap[this.props.user.id] : null}
              title={i18n.t("settings")}
              secondaryUserName={
                !sharedPortfolioUser === false ? sharedPortfolioUser.wl?.clientName || sharedPortfolioUser.name : null
              }
              secondaryImageUrl={
                !sharedPortfolioUser === false ? this.profilePictureUrlUserMap[sharedPortfolioUser.id] : null
              }
              showBadge={this.props.showAPIBadgeTip}
            />
          </ProfileImageWrapper>
        )}
        <PortfolioContextMenu dataPrivate ref={this.portfolioMenuRef} width={265} hideOnScroll={true}>
          {portfolios.map((portfolio, index) => {
            return (
              <PortfolioMenuItem key={index} isLast={index === portfolios.length - 1}>
                <PortfolioName
                  data-cy={"nameOfPortfolio" + index}
                  isSelected={portfolio.id === currentPortfolio.id}
                  onClick={() => this.handlePortfolioSelection(portfolio)}
                >
                  {portfolio.name}
                </PortfolioName>
                {portfolio.write === 1 && (
                  <PortfolioOptionButton
                    data-cy={"portfolioOptionButton" + index}
                    onClick={event => this.handlePortfolioContextMenuButtonClick(event, portfolio)}
                  />
                )}
              </PortfolioMenuItem>
            );
          })}
          {this.props.isReadOnlyWlClient === false &&
            (this.props.isSharedPortfolioUser === false || sharedPortfolioUser?.allowPortfolioCreate === 1) &&
            portfolios.length < maxPortfolioCount && (
              <PortfolioButtonContainer>
                <NewPortfolioButton onClick={this.handleNewPortfolioButtonClick} data-cy="newPortfolioButton">
                  {`+ ${i18n.t("newPortfolio")}`}
                </NewPortfolioButton>
              </PortfolioButtonContainer>
            )}
          {this.props.isReadOnlyWlClient === false && portfolios.length > 1 && (
            <PortfolioButtonContainer>
              <PortfolioTreeButton onClick={this.handlePortfolioTreeButtonClick}>
                <PortfolioTreeTitle>{i18n.t("portfolioTree")}</PortfolioTreeTitle>
                <PortfolioTreeDescription>{i18n.t("portfolioTreeDesc")}</PortfolioTreeDescription>
              </PortfolioTreeButton>
            </PortfolioButtonContainer>
          )}
        </PortfolioContextMenu>
        <TickerContextMenu
          ref={this.tickerMenuRef}
          width={200}
          hideOnScroll={false}
          style={{ position: "absolute", left: "auto", right: "100px" }}
        >
          <CurrencyPickerComponent currentPortfolio={currentPortfolio} onTickerSelection={this.handleTickerSelection} />
        </TickerContextMenu>
        <ThemeContextMenu ref={this.themeMenuRef} width={200} hideOnScroll={false} style={{ zIndex: "10000" }}>
          <ThemeMenuItem key={"default"} disabled={true}>
            <ThemeSwitch mode="default" onClick={this.handleThemeSelection}>
              <RadioButton
                label={this.getLabelForTheme("default")}
                value="default"
                checked={this.props.currentThemeMode === "default"}
                onChange={() => {}}
              />
            </ThemeSwitch>
          </ThemeMenuItem>
          <ThemeMenuItem key={"dark"} disabled={true}>
            <ThemeSwitch mode="dark" onClick={this.handleThemeSelection}>
              <RadioButton
                label={this.getLabelForTheme("dark")}
                value="on"
                checked={this.props.currentThemeMode === "dark"}
                onChange={() => {}}
              />
            </ThemeSwitch>
          </ThemeMenuItem>
          <ThemeMenuItem key={"sts"} disabled={true} onClick={this.handleThemeSelection}>
            <ThemeSwitch mode="sts">
              <RadioButton
                label={this.getLabelForTheme("sts")}
                value="sts"
                checked={this.props.currentThemeMode === "sts"}
                onChange={() => {}}
              />
            </ThemeSwitch>
          </ThemeMenuItem>
          <ThemeMenuItem key={"sp"} disabled={true} onClick={this.handleThemeSelection}>
            <ThemeSwitch mode="sp">
              <RadioButton
                label={this.getLabelForTheme("sp")}
                value="sp"
                checked={this.props.currentThemeMode === "sp"}
                onChange={() => {}}
              />
            </ThemeSwitch>
          </ThemeMenuItem>
        </ThemeContextMenu>
        <ContextMenu dataPrivate ref={this.contextMenuRef} width={275} onSelection={this.handleContextMenuSelection} />
        <ContextMenu ref={this.portfolioOptionsMenuRef} onSelection={this.handleContextMenuSelection} />
        {showDeletePortfolioDialog === true && (
          <DeletePortfolioConfirmationDialog
            portfolioName={this.state.contextMenuForPortfolio.name}
            onNegativeButtonClick={this.handleDialogNegativeButtonClick}
            onPositiveButtonClick={this.handleDialogPositiveButtonClick}
          />
        )}
        {this.state.showEmptyPortfolioDialog === true && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={i18n.t("beneficiary.emptyPortfolioTitle")}
            description={i18n.t("beneficiary.emptyPortfolioDesc")}
            positiveButtonTitle={i18n.t("ok")}
            onDismiss={this.handleEmptyPortfolioDialogDismiss}
            handlePositiveButtonClick={this.handleEmptyPortfolioDialogDismiss}
          />
        )}
        {this.state.showPortfolioTreeDialog === true && (
          <PortfolioTreeDialog onDismiss={this.handlePortfolioTreeDialogDismiss} />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: userSelector(state),
  portfolios: portfoliosSelector(state),
  currentPortfolio: currentPortfolioSelector(state),
  currentPortfolioCurrency: currentPortfolioCurrencySelector(state),
  currentPortfolioName: currentPortfolioNameSelector(state),
  hasValidPortfolio: hasValidPortfolioSelector(state),
  profilePictureToken: userProfilePictureTokenSelector(state),
  userName: userNameSelector(state),
  userEmail: userEmailSelector(state),
  userStorageLimit: userStorageLimit(state),
  userStorageUsage: userStorageUsage(state),
  accountPlan: accountPlanSelector(state),
  accountSubscriptionStatus: accountSubscriptionStatusSelector(state),
  accountCurrentTs: accountCurrentTsSelector(state),
  accountEndTs: accountEndTsSelector(state),
  remainingDays: accountRemainingDaysSelector(state),
  subscription: subscriptionSelector(state),
  wlClientContext: wlClientContextSelector(state),
  isAmcUser: isAmcUser(state),
  userPreferences: userPreferencesSelector(state),
  accountCreationTs: accountCreationTsSelector(state),
  isCurrentPortfolioEmpty: isCurrentPortfolioEmpty(state),
  siteConfig: siteConfigSelector(state),
  portfoliosFetchPending: fetchPortfolioPendingSelector(state),
  showRefreshing: showRefreshingSelector(state),
  maskAllValues: userMaskAllValuesSelector(state),
  assetsData: getCurrentPortfolioDataInNestedFormat(state, "Asset"),
  debtsData: getCurrentPortfolioDataInNestedFormat(state, "Debt"),
  currentThemeMode: currentThemeMode(state),
  sharedPortfolioUsers: sharedPortfolioUsersSelector(state),
  isSharedPortfolioUser: isSharedPortfolioUserSelector(state),
  userListForPortfolio: multiuserListForCurrentPortfolioSelector(state),
  userList: multiuserListSelector(state),
  showAPIBadgeTip: showAPIBadgeTipSelector(state),
  isReadOnlyWlClient: isReadOnlyWlClient(state)
});

const mapDispatchToProps = {
  updatePortfolio: updatePortfolio,
  deletePortfolio: deletePortfolio,
  exportPortfolio: exportPortfolio,
  updateDashboardAction: updateDashboardAction,
  getUserProfilePhotoUrl: getUserProfilePhotoUrl,
  updateUserPreferences: updateUserPreferences,
  getUpdatedIrr: getUpdatedIrr,
  showToastTip: showToastTip,
  fetchNetWorthData: fetchNetWorthDataForPortfolio,
  fetchPortfolios: fetchPortfolios,
  fetchUser: fetchUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardHeaderComponent));
