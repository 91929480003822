import { Auth } from "aws-amplify";
import queryString from "query-string";

export const VIEW_ONLY_TOKEN_KEY = "VIEW_ONLY_TOKEN_KEY";
const SIGNOUT_USER = "SIGNOUT_USER";
const RECAP_DATA_STORAGE_KEY_PREFIX = "RECAP_DATA_STORAGE_KEY";
export const VIEW_ONLY_PASSCODE_KEY = "VIEW_ONLY_PASSCODE_KEY";

export const userTypes = {
  USER: "user",
  OWNER: "owner",
  ADMIN: "admin",
  MANAGER: "manager",
  SUB_USER: "subuser"
};

export const getSiteWhiteLabelConfigId = () => {
  // Set Whitelabel mode
  // return "alpha";
  // return "dev";

  return window.getSiteWhiteLabelConfigId();
};

export const isAppInWhiteLabelMode = () => {
  const whiteLabelId = getSiteWhiteLabelConfigId();
  return !whiteLabelId === false;
};

const VIEW_MODE_ROUTE = "/view";
export const isAppInViewMode = () => {
  return window.location.pathname.startsWith(VIEW_MODE_ROUTE);
};

export const getSWConsts = () => {
  return {
    SIGNOUT_USER,
    RECAP_DATA_STORAGE_KEY_PREFIX,
    STORE_PORTFOLIO_DATA: "STORE_PORTFOLIO_DATA",
    GET_CLONE_OBJ: "GET_CLONE_OBJ"
  };
};

export const getQueryParams = location => {
  return queryString.parse(location.search);
};

export const getTokenForViewMode = (checkSavedToken = true) => {
  const params = getQueryParams(window.location);
  if (!params === false && (!params.token === false || !params.t === false)) {
    return params.token || params.t;
  }
  const savedToken = sessionStorage.getItem(VIEW_ONLY_TOKEN_KEY);
  if (!savedToken === false) {
    return savedToken;
  }
  return null;
};

export const getSubscriptionRemainingDays = (accountCurrentTs, accountEndTs) => {
  const subscriptionCurrentTs = accountCurrentTs * 1000;
  const subscriptionExpiryTs = accountEndTs * 1000;
  const dateCurrentTs = new Date(subscriptionCurrentTs);
  const dateExpiryTs = new Date(subscriptionExpiryTs);
  return Math.ceil((dateExpiryTs.getTime() - dateCurrentTs.getTime()) / 86400000);
};

export const getCapitalizedStr = (str, lowerCaseRest = false) => {
  if (!lowerCaseRest) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeStringWithSpaces = name => {
  const parts = name.trim().split(" ");
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    parts[i] = getCapitalizedStr(part);
  }
  return parts.join(" ");
};

let recaptchaV2Token = null;
export let isV2WidgetLoaded = false;

window.verifyRecaptchaV2Callback = function(token) {
  recaptchaV2Token = token;
};

export const showRecaptchaV2Widget = async onCheckCallback => {
  if (!process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2) return;

  try {
    recaptchaV2Token = null;
    window.grecaptcha.render("html_element", {
      sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2,
      callback: token => {
        window.verifyRecaptchaV2Callback(token);
        onCheckCallback();
      }
    });
    isV2WidgetLoaded = true;
  } catch (_) {}
};

export const getRecaptchaTokenType = () => {
  return isV2WidgetLoaded ? "v2" : "v3";
};

export const getRecaptchaToken = action => {
  return new Promise((resolve, reject) => {
    if (!window.grecaptcha === true) {
      const error = new Error("reCAPTCHA not loaded");
      reject(error);
      return;
    }

    if (isV2WidgetLoaded && recaptchaV2Token) {
      resolve(recaptchaV2Token);
    } else if (isV2WidgetLoaded) {
      const error = {
        code: "UserLambdaValidationException",
        message: "Please complete the reCAPTCHA verification before submitting the form."
      };
      reject(error);
    }
    window.grecaptcha.ready(function() {
      try {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: action }).then(function(token) {
          resolve(token);
        });
      } catch (error) {
        error.message = "reCAPTCHA Error";
        reject(error);
      }
    });
  });
};

export const VIEW_ONLY_USER_NAME = "VIEW_ONLY_USER_NAME";

export const setUserNameForViewMode = userName => {
  if (isAppInViewMode() === false) {
    return;
  }
  if (!userName === true) {
    sessionStorage.removeItem(VIEW_ONLY_USER_NAME);
  } else {
    sessionStorage.setItem(VIEW_ONLY_USER_NAME, userName);
  }
};

export const getAuthToken = async () => {
  const result = await Auth.currentSession()
    .then(data => {
      return "Bearer " + data.accessToken.jwtToken;
    })
    .catch(() => {
      return "";
    });
  return result;
};

export const getIdToken = async () => {
  const result = await Auth.currentSession()
    .then(data => {
      return data.idToken.jwtToken;
    })
    .catch(() => {
      return "";
    });
  return result;
};

export const isCustomWhiteLabelHost = () => {
  const whiteLabelConfigId = getSiteWhiteLabelConfigId();
  if (!whiteLabelConfigId === true || whiteLabelConfigId.split(".").length === 1) {
    return false;
  }
  return whiteLabelConfigId.endsWith(".kubera.com") === false;
};

export const getPasscodeForViewMode = () => {
  if (isAppInViewMode() === false) {
    return null;
  }
  return sessionStorage.getItem(VIEW_ONLY_PASSCODE_KEY);
};

export const showPasscodeScreen = () => {
  var url = Routes.PASSCODE;
  window.location.href = url + `?t=${getTokenForViewMode()}`;
};

export var Navigator;
export var Routes;
export const setupNavigator = (instance, routes) => {
  Navigator = instance;
  Routes = routes;
};
