import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { useTheme } from "theme";
import SignInComponent from "components/signin/SignInComponent";
import MobileSignInComponent from "components/signin/MobileSignInComponent";
import i18n from "i18next";
import * as Sentry from "@sentry/browser";
import { routes, queryParams, queryParamValues, baseRoutes } from "routes";
import { BrowserRouter as Router, Routes as Switch, Navigate as Redirect, Route } from "react-router-dom";
import {
  configureAmplify,
  isMobile,
  isUserSignedIn,
  signOut,
  getQueryParams,
  fetchPortfolios,
  isAppInWhiteLabelMode,
  setSiteConfig,
  fetchUser,
  userSelector,
  isAmcUser,
  signWithGoogleFlowSelector,
  isUserValid,
  isAppInViewMode,
  siteConfigSelector,
  apiErrorCodes,
  appMaintenanceModeSelector,
  redirectPostSignInSelector,
  setSignInRedirectPathAction,
  shareCapabilities,
  signUpWithGoogleFlowSelector,
  multiuserInvitationDataSelector,
  getCurrentUser,
  setPortfolioSessionUserId,
  getPortfolioSessionUserId,
  injectReducersForSharedPortfolios,
  sharedPortfolioUsersSelector,
  removePortfolioSessionUserId,
  getLastUsedPortfolioUserId,
  portfoliosStateSelector,
  getRecapDataFromLocalForagePromise,
  getPortfoliosDataFromLocalForagePromise,
  tickersRehydratedPromise
} from "@kubera/common";
import DashboardComponent from "components/dashboard/DashboardComponent";
import InitialLoader from "components/loader/InitialLoader";
import SignUpComponent from "components/signin/SignUpComponent";
import ForgotPasswordComponent from "components/signin/ForgotPasswordComponent";
import MobileForgotPasswordComponent from "components/signin/MobileForgotPasswordComponent";
import ForgotPasswordConfirmationComponent from "components/signin/ForgotPasswordConfirmationComponent";
import MobileForgotPasswordConfirmationComponent from "components/signin/MobileForgotPasswordConfirmationComponent";
import DeleteAccountConfirmationComponent from "components/account_settings/DeleteAccountConfirmationComponent";
import { store } from "@kubera/common";
import MarkUserActiveComponent from "components/account_settings/MarkUserActiveComponent";
import Cookies from "js-cookie";
import IdleTimer from "react-idle-timer";
import WhiteLabelDashboardComponent from "components/white_label/WhiteLabelDashboardComponent";
import RedirectCallbackComponent from "components/root/RedirectCallbackComponent";
import StripeCallbackComponent from "components/root/StripeCallbackComponent";
import RedirectHopComponent from "components/root/RedirectHopComponent";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import PasscodeComponent from "components/root/PasscodeComponent";
import WLKAdmin from "components/headsup/WLKAdmin";
import MobileSignUpComponent from "components/signin/MobileSignUpComponent";
import HandleHistory from "components/history";
import RecapComponentExpanded from "components/recap/RecapComponentExpanded";
import MaintenanceModeComponent from "components/dashboard/MaintenanceModeComponent";
import OutageBannerComponent from "components/Banner/OutageBannerComponent";
import { ConsiderDefaultThemeContext } from "utilities/Contexts";
import { NetWorthStatementComponent } from "components/net_worth/NetWorthStatementComponent";
import LinkAccountProviderPageComponent from "components/link_account/LinkAccountProviderPageComponent";
import { LINK_CUSTODIAN_ID_KEY } from "components/link_account/LinkAccountProviderPageComponentExports";
import { SignOut, SignInCallback, ViewRoute } from "routes";
import ScrollToTop from "./ScrollToTop";
import FamilyInvitationComponent from "components/multiuser/FamilyInvitationComponent";
import Logo from "components/labels/AppLogo";

const isMobileDevice = isMobile();

const Container = styled.div`
  display: block;
  height: 100%;
  background: ${props => (isMobileDevice ? props.theme.mobileBackground : props.theme.initialLoaderBG)};

  polyline {
    stroke: ${props => props.theme.polylineStroke};
  }
`;

const OutageBanner = styled(OutageBannerComponent)``;

const MobileConfirmationDialog = styled(ConfirmationDialog)`
  width: 100%;
  white-space: pre-wrap;
  box-sizing: border-box;
  padding: 32px 25px 43px;

  * {
    box-sizing: border-box;
  }
`;

const AppLogo = styled(Logo)`
  margin-bottom: 32px;
`;

const InviteMessageContainer = styled(Container)`
  margin-top; 30px;
  padding: 0 30px;
  background: ${props => props.theme.mobileSignInBackground};
`;

const InviteMessageBlock = styled.div`
  padding-top: 32px;
`;

const InviteMessageTitle = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.39px;
  margin-bottom: 27px;
`;

const InviteMessageDesc = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.27px;
`;

const trackOpenPage = function() {
  localStorage.setItem("openpages", Date.now());
  return new Promise(resolve => {
    const onLocalStorageEvent = function(e) {
      if (e.key === "openpages") {
        // Emit that you're already available.
        localStorage.setItem("page_available", Date.now());
      }
      if (e.key === "page_available") {
        resolve(true);
      }
    };

    const rememberMe = localStorage.getItem("remember_me");

    if (rememberMe !== "0") {
      resolve(false);
    }
    setTimeout(() => {
      resolve(false);
    }, 1000);
    window.addEventListener("storage", onLocalStorageEvent, false);
  });
};

const isInViewMode = isAppInViewMode();

// const mobileSupportedEnvs = ["local", "staging"];

const ThemeWrapper = ({ children }) => {
  const theme = useTheme();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

class RootComponent extends React.Component {
  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.handleSignInCallback = this.handleSignInCallback.bind(this);
    this.handleOnSiteConfigLoadRefresh = this.handleOnSiteConfigLoadRefresh.bind(this);
    this.refreshUser = this.refreshUser.bind(this);
    this.handleRememberMe = this.handleRememberMe.bind(this);
    this.handleGoogleSignInDialogPositiveButtonClick = this.handleGoogleSignInDialogPositiveButtonClick.bind(this);
    this.handleGoogleSignInDialogNegativeButtonClick = this.handleGoogleSignInDialogNegativeButtonClick.bind(this);
    this.handleMultiuserSignUpEmailMismatchDialogButtonClick = this.handleMultiuserSignUpEmailMismatchDialogButtonClick.bind(
      this
    );
    this.redirectToHome = this.redirectToHome.bind(this);

    this.shouldTriggerRefreshOnTabFocus = false;

    const user = this.getUser();
    // If there is no user but a session user id is there remove it as
    // without the user object we can't know if its for current user or not
    // and hence can't make right API calls. E.g. when the indexed data is
    // not available for some reason or has been manually deleted
    if (!user === true && !getPortfolioSessionUserId() === false) {
      console.log("Remove old session *************");
      removePortfolioSessionUserId();
    }
    const sharedPortfolioUsers = sharedPortfolioUsersSelector(store.getState());
    if (sharedPortfolioUsers.length > 0) {
      injectReducersForSharedPortfolios(sharedPortfolioUsers);
    }
    const sessionPortfolioUserId = getPortfolioSessionUserId();
    if (!window.portfolioUserId === false) {
      setPortfolioSessionUserId(window.portfolioUserId);
    } else if (!sessionPortfolioUserId === true && !getLastUsedPortfolioUserId() === false) {
      setPortfolioSessionUserId(getLastUsedPortfolioUserId());
    } else if (!sessionPortfolioUserId === true && !user === false) {
      setPortfolioSessionUserId(user.id);
    }

    console.log("Session being used:" + getPortfolioSessionUserId());

    this.state = {
      isSiteConfigFetchPending: true,
      siteConfigLoadError: null,
      isAuthCheckPending: true,
      isSignedIn: false,
      isUserFetchPending: false,
      userFetchError: null,
      isTrackingPageOpen: true,
      showGoogleSignInConfirmationDialog: false,
      linkExpiredError: null,
      siteConfigLoadErrorCode: null,
      userFetchErrorCode: null,
      showMultiuserSignupEmailMismatchDialog: false
    };

    this.fetchSiteConfig();
  }

  async componentDidMount() {
    await getRecapDataFromLocalForagePromise;
    await getPortfoliosDataFromLocalForagePromise;
    await tickersRehydratedPromise;
    const isSignedIn = await this.handleRememberMe();
    this.setState({
      isTrackingPageOpen: !isSignedIn
    });

    if (isSignedIn) {
      this.refreshUser(false);
    }
  }

  async handleRememberMe() {
    const rememberMe = localStorage.getItem("remember_me");
    const isActiveSession = sessionStorage.getItem("is_active_session");
    sessionStorage.setItem("is_active_session", "1");

    const isPageAlreadyOpen = await trackOpenPage();
    if (rememberMe === "0" && !isPageAlreadyOpen && !isActiveSession) {
      Sentry.withScope(function(scope) {
        scope.setTag("signout_exception", "Uncaught Exception");
        Sentry.captureException("RootComponent handleRememberMe signOut triggered.");
      });
      signOut();
      return false;
    }
    return true;
  }

  refreshUser(callApi) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    if (callApi) {
      store.dispatch(fetchUser());
    }

    setTimeout(() => {
      this.refreshUser(true);
    }, tomorrow.getTime() - today.getTime());
  }

  fetchSiteConfig(isRetry = false) {
    if (isRetry === true) {
      this.setState({ isSiteConfigFetchPending: true, siteConfigLoadError: null });
    }

    store.dispatch(
      setSiteConfig(
        siteConfig => {
          if (!siteConfig.tsExpiry === false && siteConfig.tsExpiry - Math.floor(new Date().getTime() / 1000) < 0) {
            this.setState({ linkExpiredError: i18n.t("linkExpired") });
            return;
          }

          this.setState({ isSiteConfigFetchPending: false });

          document.title = isAppInWhiteLabelMode()
            ? i18n.t("wlPageTitle").replace("%s1%", siteConfig.shortName)
            : i18n.t("appName");
          localStorage.setItem("wl_page_title", document.title);

          this.handleSiteConfigOnLoad();
        },
        apiError => {
          this.setState({
            siteConfigLoadError: apiError.errorMessage,
            siteConfigLoadErrorCode: apiError.errorCode
          });
        }
      )
    );
  }

  handleSiteConfigOnLoad() {
    configureAmplify();

    this.checkForUserSignInStatus(true);
    this.setSourceCookieIfNeeded();

    document.addEventListener("visibilitychange", () => {
      if (
        this.state.isSignedIn === true &&
        document.hidden === false &&
        (this.shouldTriggerRefreshOnTabFocus === true || isMobileDevice === true)
      ) {
        this.shouldTriggerRefreshOnTabFocus = false;
        store.dispatch(fetchPortfolios(isMobileDevice === false));
      }
    });
  }

  handleOnSiteConfigLoadRefresh() {
    this.fetchSiteConfig(true);
  }

  handleOnIdle(event) {
    if (this.state.isSignedIn === false) {
      return;
    }

    const rememberMe = localStorage.getItem("remember_me");

    if (rememberMe === "0" && document.hidden === false) {
      Sentry.withScope(function(scope) {
        scope.setTag("signout_exception", "Uncaught Exception");
        Sentry.captureException("RootComponent handleOnIdle signOut triggered. User id:" + this.getUserId());
      });
      signOut();
    }

    if (document.hidden === false) {
      store.dispatch(fetchPortfolios(true));
    } else {
      this.shouldTriggerRefreshOnTabFocus = true;
    }
  }

  setSourceCookieIfNeeded() {
    const pathName = window.location.pathname;
    if (pathName === "/" || pathName === routes.SIGNUP) {
      if (Cookies.get("k-utm")) {
        return;
      }
      const queryParams = getQueryParams(window.location);
      var trackerDetails = {};

      if (!queryParams["utm_source"] === false) {
        trackerDetails["source"] = queryParams["utm_source"];
      }
      if (!queryParams["utm_medium"] === false) {
        trackerDetails["medium"] = queryParams["utm_medium"];
      }
      if (!queryParams["utm_campaign"] === false) {
        trackerDetails["campaign"] = queryParams["utm_campaign"];
      }
      if (!queryParams["utm_term"] === false) {
        trackerDetails["term"] = queryParams["utm_term"];
      }
      if (!queryParams["utm_content"] === false) {
        trackerDetails["content"] = queryParams["utm_content"];
      }
      if (!document.referrer === false) {
        trackerDetails["referrer"] = document.referrer;
      }

      if (Object.keys(trackerDetails).length > 0) {
        Cookies.set("k-utm", JSON.stringify(trackerDetails), {
          expires: 1,
          path: "",
          domain: ".kubera.com",
          sameSite: "Strict",
          secure: true
        });
      }
    }
  }

  checkForUserSignInStatus = async (shouldRefreshUserDetails = false) => {
    if (isInViewMode === true) {
      this.setState({ isAuthCheckPending: false, isSignedIn: true });
      return;
    }

    if (!portfoliosStateSelector(store.getState()) === true) {
      console.log("Mismatch between session id and user data, need to logout *************");
      this.setState({ isAuthCheckPending: false, isSignedIn: false });
      signOut();
      return;
    }

    const status = await isUserSignedIn();
    if (status === true) {
      // 86400000 (oneDayInMillis) * 45 (days)
      let maxAllowedMillis = 3888000000;
      const timeNow = Date.now();
      const lastLogin = Cookies.get("kuberaAppLastAccessSecure");
      if (!lastLogin === false && timeNow - lastLogin > maxAllowedMillis) {
        signOut(true);
        return;
      }

      Cookies.set("kuberaAppLastAccessSecure", timeNow, {
        sameSite: "Strict",
        secure: true,
        expires: 365,
        domain: ".kubera.com"
      });
      Cookies.set("kuberaAppDomain", window.location.host, {
        path: "/",
        domain: ".kubera.com",
        expires: 365,
        sameSite: "Strict",
        secure: true
      });
      sessionStorage.removeItem("kuberaRecaptchaVerifyResponse");

      if (shouldRefreshUserDetails === true && window.location.pathname !== routes.SIGNOUT) {
        store.dispatch(
          fetchUser(userData => {
            if (!userData.wl === false && !userData.wl.tsArchive === false) {
              this.signOutArchivedUser(userData);
            }
          })
        );
      }
    }

    const invitationData = multiuserInvitationDataSelector(store.getState());
    const params = getQueryParams(window.location);
    if (!params[queryParams.INVITATION_ID] === false) {
      invitationData.email = params[queryParams.EMAIL_ID];
      invitationData.id = params[queryParams.INVITATION_ID];
    }
    if (status === true && !invitationData.email === false) {
      getCurrentUser(
        user => {
          if (user.signInUserSession?.idToken?.payload?.email === invitationData.email) {
            this.setState({ isAuthCheckPending: false, isSignedIn: status });
          } else {
            this.setState({ showMultiuserSignupEmailMismatchDialog: true, isUserFetchPending: false });
          }
        },
        error => {
          this.setState({ userFetchError: error.message });
        }
      );
    } else {
      if (status === true && !this.getUser() === true) {
        store.dispatch(
          fetchUser(
            () => {
              this.setState({ isAuthCheckPending: false, isUserFetchPending: false, isSignedIn: status });
            },
            apiError => {
              this.setState({ userFetchError: apiError.errorMessage, userFetchErrorCode: apiError.errorCode });
            }
          )
        );
      } else {
        this.setState({ isAuthCheckPending: false, isSignedIn: status });
      }
    }
  };

  signOutArchivedUser(userData) {
    signOut(
      true,
      `${queryParams.SIGNIN_ERROR}=${queryParamValues.ARCHIVED_USER_ERROR}&${queryParams.EMAIL_ID}=${userData.wl.email}`
    );
    Sentry.withScope(function(scope) {
      scope.setTag("signout_exception", "Uncaught Exception");
      Sentry.captureException("RootComponent signOutArchivedUser signOut triggered. User id:" + this.getUserId());
    });
  }

  getUser() {
    return userSelector(store.getState());
  }

  getUserId() {
    const user = this.getUser();

    if (!user === true) {
      return "<null>";
    }
    return user.id;
  }

  handleSignInCallback() {
    setTimeout(() => {
      this.checkForUserSignInStatus();

      if (!this.getUser() === false) {
        return;
      }
      removePortfolioSessionUserId();
      this.setState({ isUserFetchPending: true, userFetchError: null });

      const getUserData = () => {
        store.dispatch(
          fetchUser(
            userData => {
              if (!userData.wl === false && !userData.wl.tsArchive === false) {
                this.signOutArchivedUser(userData);
              } else {
                this.setState({ isUserFetchPending: false });
                const portfolioSessionId = getPortfolioSessionUserId();
                if (!portfolioSessionId === true) {
                  setPortfolioSessionUserId(userData.id);
                }
              }
            },
            apiError => {
              this.setState({ userFetchError: apiError.errorMessage, userFetchErrorCode: apiError.errorCode });
            }
          )
        );
      };

      const invitationData = multiuserInvitationDataSelector(store.getState());
      if (!invitationData.id === false && !signUpWithGoogleFlowSelector(store.getState()) === false) {
        if (this.state.isSignedIn === true) {
          getCurrentUser(
            user => {
              if (user.signInUserSession?.idToken?.payload?.email === invitationData.email) {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set(queryParams.INVITATION_ID, invitationData.id);
                if (invitationData.portfolioId) {
                  urlParams.set(queryParams.PORTFOLIO_ID, invitationData.portfolioId);
                }
                window.location = `${routes.FAMILY_INVITATION}?${urlParams.toString()}`;
                getUserData();
              } else {
                this.setState({ showMultiuserSignupEmailMismatchDialog: true, isUserFetchPending: false });
              }
            },
            error => {
              this.setState({ userFetchError: error.message });
            }
          );
        } else {
          setTimeout(() => {
            this.handleSignInCallback();
          }, 1000);
        }
      } else if (
        this.state.showGoogleSignInConfirmationDialog === false &&
        !signWithGoogleFlowSelector(store.getState()) === false
      ) {
        store.dispatch(
          isUserValid(
            isValid => {
              if (isValid === true) {
                getUserData();
              } else {
                this.setState({ showGoogleSignInConfirmationDialog: true, isUserFetchPending: false });
              }
            },
            apiError => {
              this.setState({ userFetchError: apiError.errorMessage });
            }
          )
        );
      } else {
        getUserData();
      }
    }, 0);
  }

  handleSignOutCallback() {
    setTimeout(() => {
      this.setState({ ...this.state, isSignedIn: false });
    }, 300);
  }

  redirectToHome() {
    if (isAmcUser(store.getState()) === false) {
      const params = getQueryParams(window.location);
      if (!params[queryParams.INVITATION_ID] === false) {
        return (
          <Redirect
            to={{
              pathname: routes.FAMILY_INVITATION,
              search: window.location.search,
              hash: window.location.hash
            }}
          />
        );
      }

      const redirectPostSignInPath = redirectPostSignInSelector(store.getState());
      if (
        !redirectPostSignInPath === false &&
        this.getDashboardRoutes().includes(redirectPostSignInPath.pathname) === false &&
        redirectPostSignInPath.pathname === routes.FAMILY_INVITATION
      ) {
        setTimeout(() => {
          store.dispatch(setSignInRedirectPathAction(null));
        }, 2000);
        return (
          <Redirect
            to={{
              pathname: routes.FAMILY_INVITATION,
              search: redirectPostSignInPath.search,
              hash: redirectPostSignInPath.hash
            }}
          />
        );
      }
    }
    return <Redirect to={routes.HOME} />;
  }

  getDashboardRoutes() {
    var dashboardRoutes = [
      routes.ASSETS,
      routes.DEBTS,
      routes.INSURANCE,
      routes.SAFE_DEPOSIT_BOX,
      routes.BENEFICIARY,
      routes.NET_WORTH,
      routes.REPORTS,
      routes.MOBILENAV,
      routes.WHATS_NEW_POPUP,
      routes.REPORTS_EXPANDED,
      routes.PLANNING,
      routes.PLUGIN
    ];

    if (isAppInViewMode()) {
      const siteConfig = siteConfigSelector(store.getState());
      const capabilities = JSON.parse(siteConfig.capability);
      var routesToRemove = [routes.BENEFICIARY];

      if (
        capabilities.includes(shareCapabilities.ALL) === false &&
        capabilities.includes(shareCapabilities.INSURANCE) === false
      ) {
        routesToRemove.push(routes.INSURANCE);
      }
      if (
        capabilities.includes(shareCapabilities.ALL) === false &&
        capabilities.includes(shareCapabilities.DOCUMENT) === false
      ) {
        routesToRemove.push(routes.SAFE_DEPOSIT_BOX);
      }
      if (
        capabilities.includes(shareCapabilities.ALL) === false &&
        capabilities.includes(shareCapabilities.NETWORTH) === false
      ) {
        routesToRemove.push(routes.NET_WORTH);
      }
      if (
        capabilities.includes(shareCapabilities.ALL) === false &&
        capabilities.includes(shareCapabilities.RECAP) === false
      ) {
        routesToRemove.push(routes.REPORTS);
      }
      if (
        capabilities.includes(shareCapabilities.ALL) === false &&
        capabilities.includes(shareCapabilities.PLANNING) === false
      ) {
        routesToRemove.push(routes.PLANNING);
      }
      dashboardRoutes = dashboardRoutes.filter(item => routesToRemove.includes(item) === false);
    }
    return dashboardRoutes;
  }

  handleGoogleSignInDialogPositiveButtonClick(e) {
    this.setState({ showGoogleSignInConfirmationDialog: false, isUserFetchPending: true });

    store.dispatch(
      fetchUser(
        userData => {
          this.setState({ isUserFetchPending: false });
        },
        apiError => {
          this.setState({ userFetchError: apiError.errorMessage });
        }
      )
    );
  }

  handleGoogleSignInDialogNegativeButtonClick(e) {
    signOut();
  }

  handleMultiuserSignUpEmailMismatchDialogButtonClick(e) {
    signOut();
  }

  signoutCallback = () => {
    this.setState({
      isSignedIn: false
    });
  };

  render() {
    window.renderlog("Render: RootComponent");

    const params = getQueryParams(window.location);
    const custodianId =
      params[queryParams.ID] ||
      sessionStorage.getItem(LINK_CUSTODIAN_ID_KEY) ||
      localStorage.getItem(LINK_CUSTODIAN_ID_KEY);

    if (appMaintenanceModeSelector(store.getState())) {
      return (
        <ConsiderDefaultThemeContext.Provider value={true}>
          <ThemeWrapper>
            <MaintenanceModeComponent />
          </ThemeWrapper>
        </ConsiderDefaultThemeContext.Provider>
      );
    }

    if (this.state.showGoogleSignInConfirmationDialog === true) {
      if (isMobileDevice) {
        return (
          <MobileConfirmationDialog
            title={i18n.t("mobileSignIn.newUserDialogTitle")}
            description={i18n.t("mobileSignIn.newUserDialogDesc")}
            positiveButtonTitle={i18n.t("ok")}
            handlePositiveButtonClick={this.handleGoogleSignInDialogNegativeButtonClick}
          />
        );
      }

      return (
        <Router>
          <ConfirmationDialog
            title={i18n.t("signInWithGoogleDialog.title")}
            description={i18n.t("signInWithGoogleDialog.description")}
            positiveButtonTitle={i18n.t("signUp")}
            negativeButtonTitle={i18n.t("cancel")}
            handlePositiveButtonClick={this.handleGoogleSignInDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleGoogleSignInDialogNegativeButtonClick}
          />
        </Router>
      );
    }

    if (this.state.showMultiuserSignupEmailMismatchDialog === true) {
      if (isMobileDevice) {
        return (
          <ThemeWrapper>
            <InviteMessageContainer>
              <InviteMessageBlock>
                <AppLogo hasNoLink={isAppInWhiteLabelMode()} forceDefault />
                <InviteMessageTitle>{`${i18n.t("sorry")}!`}</InviteMessageTitle>
                <InviteMessageDesc>{i18n.t("mobileFamilyInviteMessage")}</InviteMessageDesc>
              </InviteMessageBlock>
            </InviteMessageContainer>
          </ThemeWrapper>
        );
      }

      return (
        <Router>
          <ConfirmationDialog
            title={i18n.t("multiuserSignupEmailMismatch.title")}
            description={i18n.t("multiuserSignupEmailMismatch.description")}
            positiveButtonTitle={i18n.t("ok")}
            handlePositiveButtonClick={this.handleMultiuserSignUpEmailMismatchDialogButtonClick}
          />
        </Router>
      );
    }

    if (!this.state.linkExpiredError === false || !this.state.siteConfigLoadError === false) {
      if (this.state.siteConfigLoadErrorCode === apiErrorCodes.WL_DOMAIN_DISABLED) {
        return (
          <ThemeWrapper>
            <InitialLoader
              errorMessage={i18n.t("disabledWlkErrorMessage")}
              overrideGenericError={true}
              disableRefresh={true}
            />
          </ThemeWrapper>
        );
      }
      return (
        <ThemeWrapper>
          <InitialLoader
            errorMessage={i18n.t("portfolioExpiredError")}
            overrideGenericError={true}
            disableRefresh={true}
          />
        </ThemeWrapper>
      );
    }

    if (this.state.isSiteConfigFetchPending === true || !this.state.siteConfigLoadError === false) {
      return (
        <ThemeWrapper>
          <InitialLoader errorMessage={this.state.siteConfigLoadError} onRefresh={this.handleOnSiteConfigLoadRefresh} />
        </ThemeWrapper>
      );
    }

    if (this.state.isAuthCheckPending === true || this.state.isTrackingPageOpen) {
      return (
        <ThemeWrapper>
          <InitialLoader />
        </ThemeWrapper>
      );
    }

    if (this.state.isUserFetchPending === true || !this.state.userFetchError === false) {
      const isUserSuspended = this.state.userFetchErrorCode === apiErrorCodes.ACCOUNT_SUSPENDED;
      return (
        <ThemeWrapper>
          <InitialLoader
            errorMessage={isUserSuspended ? i18n.t("accountSuspendedError") : this.state.userFetchError}
            onRefresh={this.handleSignInCallback}
            overrideGenericError={isUserSuspended}
            disableRefresh={isUserSuspended}
          />
        </ThemeWrapper>
      );
    }

    return (
      <ConsiderDefaultThemeContext.Provider value={!this.state.isSignedIn}>
        <ThemeWrapper>
          <Container>
            <Router>
              <HandleHistory />
              <IdleTimer
                ref={ref => {
                  this.idleTimer = ref;
                }}
                timeout={1000 * 60 * 60} // 60 minutes
                onIdle={this.handleOnIdle}
                debounce={250}
              />
              <ScrollToTop />
              <Switch>
                <Route
                  exact
                  path={routes.SIGNIN}
                  element={
                    this.state.isSignedIn === true ? (
                      this.redirectToHome()
                    ) : isMobileDevice ? (
                      <MobileSignInComponent />
                    ) : (
                      <SignInComponent />
                    )
                  }
                />
                <Route
                  exact
                  path={routes.SIGNUP}
                  element={
                    this.state.isSignedIn === true ? (
                      this.redirectToHome()
                    ) : isAppInWhiteLabelMode() === true ? (
                      <Redirect to={routes.SIGNIN} />
                    ) : isMobileDevice ? (
                      <MobileSignUpComponent />
                    ) : (
                      <SignUpComponent />
                    )
                  }
                />
                <Route
                  exact
                  path={routes.FORGOT_PASSWORD}
                  element={
                    this.state.isSignedIn ? (
                      this.redirectToHome()
                    ) : isMobileDevice ? (
                      <MobileForgotPasswordComponent />
                    ) : (
                      <ForgotPasswordComponent />
                    )
                  }
                />
                <Route
                  exact
                  path={routes.FORGOT_PASSWORD_CONFIRMATION}
                  element={
                    this.state.isSignedIn === true ? (
                      this.redirectToHome()
                    ) : isMobileDevice ? (
                      <MobileForgotPasswordConfirmationComponent />
                    ) : (
                      <ForgotPasswordConfirmationComponent />
                    )
                  }
                />
                <Route exact path={routes.SET_USER_ACTIVE} element={<MarkUserActiveComponent />} />
                <Route
                  exact
                  path={routes.DELETE_ACCOUNT_CONFIRMATION}
                  element={<DeleteAccountConfirmationComponent />}
                  action={() => {
                    setTimeout(() => {
                      this.setState({ ...this.state, isSignedIn: false });
                    }, 1000);
                  }}
                />
                <Route
                  exact
                  path={routes.LINK_PROVIDER_PAGE}
                  element={<LinkAccountProviderPageComponent custodianId={custodianId} />}
                />
                <Route exact path={routes.REDIRECT_CALLBACK} element={<RedirectCallbackComponent />} />
                <Route exact path={routes.STRIPE_CALLBACK} element={<StripeCallbackComponent />} />
                <Route exact path={routes.REDIRECT_HOP} element={<RedirectHopComponent />} />
                <Route
                  exact
                  path={routes.SIGNIN_CALLBACK}
                  element={
                    <SignInCallback
                      isSignedIn={this.state.isSignedIn}
                      redirectToHome={this.redirectToHome}
                      handleSignInCallback={this.handleSignInCallback}
                    />
                  }
                />
                <Route
                  exact
                  path={routes.SIGNOUT}
                  element={<SignOut isSignedIn={this.state.isSignedIn} signoutCallback={this.signoutCallback} />}
                />
                <Route
                  exact
                  path={routes.SIGNOUT_CALLBACK}
                  element={<Redirect to={routes.SIGNIN} />}
                  action={() => {
                    this.handleSignOutCallback();
                  }}
                />
                <Route
                  exact
                  path={routes.HOME}
                  element={
                    isAmcUser(store.getState()) === true ? (
                      isMobileDevice ? (
                        <WLKAdmin />
                      ) : (
                        <Redirect to={routes.WL_DASHBOARD} />
                      )
                    ) : isMobileDevice ? (
                      <Redirect to={routes.MOBILENAV} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: routes.NET_WORTH,
                          search: params[queryParams.PORTFOLIO_ID]
                            ? `?${queryParams.PORTFOLIO_ID}=${params[queryParams.PORTFOLIO_ID]}`
                            : ""
                        }}
                      />
                    )
                  }
                />
                <Route exact path={baseRoutes.VIEW} element={<ViewRoute />} />
                <Route exact path={routes.PASSCODE} element={<PasscodeComponent />} />
                <Route
                  path={routes.REPORTS_EXPANDED}
                  element={
                    this.state.isSignedIn ? (
                      <RecapComponentExpanded isSubscriptionRequired={this.isSubscriptionRequired} />
                    ) : (
                      <Redirect to={routes.SIGNIN} />
                    )
                  }
                />
                <Route
                  path={routes.NETWORTH_STATEMENT}
                  element={
                    this.state.isSignedIn ? (
                      <NetWorthStatementComponent isSubscriptionRequired={this.isSubscriptionRequired} />
                    ) : (
                      <Redirect to={routes.SIGNIN} />
                    )
                  }
                />
                <Route
                  path={routes.FAMILY_INVITATION}
                  element={
                    this.state.isSignedIn ? (
                      <FamilyInvitationComponent />
                    ) : (
                      <Redirect
                        to={{
                          pathname: routes.SIGNIN,
                          search: window.location.search,
                          hash: window.location.hash
                        }}
                      />
                    )
                  }
                />
                <Route
                  path="/*"
                  element={
                    this.state.isSignedIn ? (
                      <DashboardComponent isSubscriptionRequired={this.isSubscriptionRequired} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: routes.SIGNIN,
                          search:
                            !window.location.search === true
                              ? ""
                              : `${window.location.search}&${queryParams.REDIRECT_PATH}=${window.location.pathname}`,
                          hash: window.location.hash
                        }}
                      />
                    )
                  }
                />
                <Route exact path={routes.OLD_WL_DASHBOARD} element={<Redirect to={routes.WL_DASHBOARD} />} />
                <Route
                  path={routes.WL_DASHBOARD}
                  element={
                    this.state.isSignedIn ? (
                      <WhiteLabelDashboardComponent isSubscriptionRequired={this.isSubscriptionRequired} />
                    ) : (
                      <Redirect to={routes.SIGNIN} />
                    )
                  }
                />
              </Switch>
            </Router>
            <OutageBanner />
          </Container>
        </ThemeWrapper>
      </ConsiderDefaultThemeContext.Provider>
    );
  }
}

export default RootComponent;
