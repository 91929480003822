import { setupLogger, userSelector, store, isAppInViewMode, isInternalUserSelector } from "@kubera/common";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import lrConditionalCall from "utilities/lrConditionalCall";
import { isMobile, getAppVersion } from "@kubera/common";

var LoggerRef;

const isMobileDevice = isMobile();

export const configureLogger = () => {
  if (process.env.REACT_APP_ENV !== "local" && !isMobileDevice) {
    if (!LoggerRef === false) {
      return;
    }
    if (isAppInViewMode() === false && (!store === true || !userSelector(store.getState()) === true)) {
      return;
    }

    let hostname = "kubera.com";
    // if (process.env.REACT_APP_ENV === "prod") {
    //   hostname = "kubera.com";
    // }
    LogRocket.init(process.env.REACT_APP_LOGROCKET_ID, {
      rootHostname: hostname,
      mergeIframes: true
    });
    setupLogRocketReact(LogRocket);
    LogRocket.log("Logrocket Init", {
      user: userSelector(store.getState())
    });
    LoggerRef = LogRocket;
  }
};

export const setupCommonLogger = () => {
  setupLogger(Logger);
};

class Logger {
  static captureException = error => {
    const isInternalUser = isInternalUserSelector(store.getState());
    if (!LoggerRef === true) {
      return;
    }
    lrConditionalCall(() => {
      LoggerRef.captureException(error);
    }, isInternalUser);
  };

  static reduxMiddleware = () => {
    return null;
    // return LogRocket.reduxMiddleware();
  };
}

export const setupClarity = userId => {
  const onClarityLoad = () => {
    window.clarity("consent");
    window.clarity("identify", userId);
    window.clarity("set", "kUserId", userId);
    window.clarity("set", "kAppVersion", getAppVersion());
  };

  if (process.env.REACT_APP_CLARITY_ID && isMobileDevice) {
    (function(c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function() {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      t.onload = onClarityLoad;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", process.env.REACT_APP_CLARITY_ID);
  }
};
