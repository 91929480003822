import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { hashParams, modalValues } from "routes";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import DeferredPromise from "utilities/DeferredPromise";
import {
  fetchCustodianDetails,
  fetchCustodianDetailsHistory,
  updateCustodian,
  updateDashboardAction,
  archiveCustodian,
  unlinkAccountWithCustodian,
  unlinkAllConnectedCustodians,
  getMonthFromDate,
  unarchiveCustodian,
  deleteCustodianPermanently,
  currentPortfolioSelector,
  portfoliosSelector,
  custodianDetailsIsPendingSelector,
  custodianDetailsErrorSelector,
  custodianDetailsSelector,
  updateCustodianDetailsAction,
  getTickerUsingId,
  convertCurrency,
  deleteCustodian,
  getCustodianLastUpdateDetails,
  shouldShowLinkingErrorForCustodian,
  custodianSelector,
  custodiansLinkedToSameAccount,
  accountCurrentTsSelector,
  accountEndTsSelector,
  accountGraceTsSelector,
  accountStartTsSelector,
  accountSubscriptionStatusSelector,
  accountSubscriptionIsActiveSelector,
  isAssetCustodian,
  userPreferencesSelector,
  store,
  updateUserPreferences,
  shouldShowHoldingsExpansionTipForCustodian,
  updateCustodianOnDashboard,
  accountLinkingService,
  refreshCustodian,
  chartTimeRange,
  getCustodianChart,
  getCustodianValueChartTimeRange,
  isAppInWhiteLabelMode,
  wlClientContextSelector,
  tickerTypes,
  getTickerUsingShortName,
  custodiansWithSameParentIdSelector,
  updateParentCustodianValueWithHoldingsTotal,
  isAppInViewMode,
  siteConfigSelector,
  shareCapabilities,
  custodianDocumentsSelector,
  isCryptoLinkingService,
  isReadOnlyWlClient,
  updateCustodianInBulkAction,
  calcCustodianOwnershipValue,
  showToastTip,
  dismissToastAction,
  accountLinkingContainers,
  irrTypes,
  getHashParams,
  formatNumberWithCurrency,
  getUnfundedCommitmentForCustodian,
  tickerSubTypes,
  updateCustodianBulk,
  userMaskAllValuesSelector,
  getExchangeRate,
  isCustodianAddedToday,
  checkIfACustodianIsCash,
  getEstimatedTaxOnUnrealizedGainsForCustodian,
  getUnrealizedGainForACustodian,
  connectivityCenterDataForPortfolioSelector,
  getConnectivityCenterData,
  getDateInKuberaFormat,
  getTaxableTypeForCustodian,
  custodianTaxTypes,
  fetchCustodianDetailsSuccessAction,
  setInnerDetailsStateAction,
  previousDetailsStateSelector,
  setCurrentStateAction,
  custodianDetailsScrollTopSelector,
  getDetailsBlockPromise,
  custodianChartDataSelector,
  custodianChartIsPendingSelector,
  holdingSubTypes
} from "@kubera/common";
import Loader from "components/loader/Loader";
import EditableLabel from "components/inputs/EditableLabel";
import optionsIcon from "assets/images/options.svg";
import CurrencyLabel from "components/labels/CurrencyLabel";
import ContextMenu, { getTickerDescriptionContextMenuItem } from "components/contextmenu/ContextMenu";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CustodianHomesComponent from "components/custodian_details/CustodianHomesComponent";
import CustodianNotesComponent from "components/custodian_details/CustodianNotesComponent";
import CustodianDocumentsComponent from "components/custodian_details/CustodianDocumentsComponent";
import CustodianDebtHistoryComponent from "components/custodian_details/CustodianDebtHistoryComponent";
import CustodianInsuranceDetailsComponent from "components/custodian_details/CustodianInsuranceDetailsComponent";
import { category } from "components/dashboard/DashboardComponentExports";
import { MIN_CHART_DATA_POINTS } from "components/net_worth/NetWorthComponent";
import LinkAccountComponentExports, { linkAccountMode } from "components/link_account/LinkAccountComponentExports";
import {
  contextMenuItemType,
  getLinkContextMenuItem,
  getUpdateValuesContextMenuItem,
  getManagedAssetContextMenuItem,
  getLinkedAccountDescriptionMenuItem,
  getStartOverContextMenuItem
} from "components/contextmenu/ContextMenu";
import CustodianHoldingsComponent from "components/custodian_details/CustodianHoldingsComponent";
import linkedAccountIcon from "assets/images/linked_account_icon.svg";
import PickLinkAccountCountry from "components/link_account/PickLinkAccountCountry";
import { ReactComponent as LinkAccountErrorIcon } from "assets/images/link_account_warning.svg";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { GridRowData } from "components/grid/GridDataModel";
import ChangeLabel from "components/labels/ChangeLabel";
import CustodianParentDetailsComponent from "components/custodian_details/CustodianParentDetailsComponent";
import CustodianPVSTDetailsComponent from "components/custodian_details/CustodianPVSTDetailsComponent";
import CustodianAssetValueComponent from "components/custodian_details/CustodianAssetValueComponent";
import CustodianReturnsComponent from "components/custodian_details/CustodianReturnsComponent";
import { ReactComponent as CloseIcon } from "assets/images/close.svg";
import CustodianOwnershipComponent from "./CustodianOwnershipComponent";
import NFTComponent from "./NFTComponent";
import CustodianAssortedFlagsComponent from "./CustodianAssortedFlagsComponent";
import LinkedPortfolioComponent from "./LinkedPortfolioComponent";
import CustodianDetailsDesc from "./CustodianDetailsDesc";
import { ReactComponent as PoweredByCartaLogo } from "assets/images/poweredbycarta.svg";
import CustodianDetailsComponentExports, { detailsTabs } from "./CustodianDetailsComponentExports";
import RedDot from "components/indicators/RedDot";
import CustodianReportingComponent from "./CustodianReportingComponent";

const DetailsDialogOverlay = styled(DialogOverlay)`
  background-color: transparent;
`;

const DetailsDialog = styled(Dialog)`
  width: ${props => `${props.width}px`};
  min-height: 633px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const DetailsContainer = styled.div`
  display: flex;
  margin: ${props => (props.isCustodianMarkedAsTaxFree ? "50px 60px 50px 60px" : "50px 60px 80px 60px")};
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const DetailsLoader = styled(Loader)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  z-index: 1000;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleDetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled(EditableLabel)`
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
  padding: 0;
  word-break: break-all;
`;

const LinkedAccountIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-top: 2px;
  margin-left: 5px;
  background-image: url(${linkedAccountIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const ValueAndOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const ValueContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const Value = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
`;

const ValueChangeLabel = styled(ChangeLabel)`
  margin-left: 8px;
  margin-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const AllBadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 3px;
  background: #f2f2f2;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const BadgeDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 125%;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(36, 36, 36, 0.6);
  white-space: pre-wrap;
  text-transform: uppercase;
`;

const LinkingErrorButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 34px;
  height: 34px;
  width: 36px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  margin-bottom: 15px;
`;

const OptionsWrapper = styled.div`
  position: relative;
`;

const ManualOverride = styled.div`
  position: absolute;
  right: 100%;
  top: -10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const ManualOverideBadge = styled.div`
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: stretch;
`;

const ManualOverideBadgeTxt = styled.div`
  flex: 1;
  width: 101px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`;

const ManualOverideToolTip = styled(ManualOverideBadge)`
  position: relative;
  margin-top: 13px;
  flex-direction: column;

  ::after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
    transform: rotateY(48deg);
  }
`;

const ManualOverideToolTipTxt = styled(ManualOverideBadgeTxt)`
  font-size: 11px;
  line-height: 140%;
`;

const GotItBtn = styled.div`
  font-size: 11px;
  line-height: 140%;
  cursor: pointer;
  margin-top: 17px;
  color: rgba(255, 255, 255, 0.7);
`;

const CloseBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  padding-right: 3px;

  path {
    fill: rgba(255, 255, 255, 0.7);
  }
`;

const CloseIconComponent = styled(CloseIcon)`
  width: 8px;
  height: 8px;
  color: #000;
`;

const OptionsButton = styled.button`
  text-align: center;
  width: 34px;
  height: 28px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  margin-left: 2px;
  margin-bottom: 12px;
  margin-right: -5px;
  cursor: pointer;
  background-color: transparent;
  background-image: url(${optionsIcon});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 14px 16px;
`;

const DetailsTabs = styled(Tabs)`
  margin-top: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  white-space: nowrap;
`;

const DetailsTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-bottom: 8px;
`;

const PoweredByCartaLogoComponent = styled(PoweredByCartaLogo)`
  width: 120px;
  margin: 2px 5px 3px;
`;

const DetailsTab = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.35);
  padding-bottom: 8px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: black;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
  }
`;

const DetailsTabPanel = styled(TabPanel)`
  margin-top: -10px;
  display: none;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  &.is-selected {
    display: flex;
    flex: 1;
  }
`;

const DetailsTabLoader = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const NotesPanel = styled(CustodianNotesComponent)`
  border: ${props => props.theme.notesBR};
  border-top: 0;
  background-color: white;
`;

const HomePanel = styled(CustodianHomesComponent)``;

const NFTPanel = styled(NFTComponent)``;

const LinkedPortfolioPanel = styled(LinkedPortfolioComponent)``;

const InsuranceDetailsPanel = styled(CustodianInsuranceDetailsComponent)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 35px 25px 35px 25px;
  background-color: white;
`;

const DocumentsPanel = styled(CustodianDocumentsComponent)``;

const AssetValuePanel = styled(CustodianAssetValueComponent)`
  margin-top: 12px;
  background-color: transparent;
`;

const AssetReturnsPanel = styled(CustodianReturnsComponent)`
  margin-top: 12px;
  background-color: transparent;
`;

const DebtHistoryPanel = styled(CustodianDebtHistoryComponent)`
  margin-top: 12px;
  background-color: transparent;
`;

const HoldingsPanel = styled(CustodianHoldingsComponent)`
  margin-top: 12px;
  background-color: transparent;
`;

const ParentDetailsPanel = styled(CustodianParentDetailsComponent)`
  margin-top: 12px;
  background-color: transparent;
`;

const PVSTDetailsPanel = styled(CustodianPVSTDetailsComponent)`
  margin-top: 30px;
  background-color: transparent;
`;

const OwnerShipPanel = styled(CustodianOwnershipComponent)``;

const AssortedFlagsPanel = styled(CustodianAssortedFlagsComponent)``;

const ReportingPanel = styled(CustodianReportingComponent)``;

const BadgeLabel = styled.div`
  font-size: 9px;
`;

const FundScheduleRedDot = styled(RedDot)`
  position: relative;
  display: inline-block;
  width: 6px;
  height: 6px;
  left: -50px;
  background: "#FF6464";
`;

const ReportingTabRedDot = styled(FundScheduleRedDot)``;

class CustodianDetailsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleDescriptionKeyDown = this.handleDescriptionKeyDown.bind(this);
    this.handleOptionsClick = this.handleOptionsClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleCustodianUpdate = this.handleCustodianUpdate.bind(this);
    this.handleContextMenuDismiss = this.handleContextMenuDismiss.bind(this);
    this.handleLoaderRefreshClick = this.handleLoaderRefreshClick.bind(this);
    this.handleLinkingErrorButtonClick = this.handleLinkingErrorButtonClick.bind(this);
    this.handleUnlinkAllConnectionsDialogPositiveButtonClick = this.handleUnlinkAllConnectionsDialogPositiveButtonClick.bind(
      this
    );
    this.handleUnlinkAllConnectionsDialogOnDismiss = this.handleUnlinkAllConnectionsDialogOnDismiss.bind(this);
    this.setTab = this.setTab.bind(this);
    this.editName = this.editName.bind(this);
    this.handleChartTimeRangeSelectionButtonClick = this.handleChartTimeRangeSelectionButtonClick.bind(this);
    this.handleChartContextMenuSelection = this.handleChartContextMenuSelection.bind(this);
    this.handleChartHoverSelection = this.handleChartHoverSelection.bind(this);
    this.showHomeTab = this.showHomeTab.bind(this);
    this.showNFTTab = this.showNFTTab.bind(this);
    this.showHistoryTab = this.showHistoryTab.bind(this);
    this.getHomeTitle = this.getHomeTitle.bind(this);
    this.handleDetailsCustodianChange = this.handleDetailsCustodianChange.bind(this);
    this.getCustodianChartData = this.getCustodianChartData.bind(this);
    this.handleReconnectDialogPositiveButtonClick = this.handleReconnectDialogPositiveButtonClick.bind(this);
    this.handleReconnectDialogNegativeButtonClick = this.handleReconnectDialogNegativeButtonClick.bind(this);
    this.handleReconnectDialogDismissButtonClick = this.handleReconnectDialogDismissButtonClick.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);

    this.state = {
      isShowingContextMenu: false,
      isRequestPending: false,
      showUnlinkAllConnectionsDialog: false,
      tabIndex: 0,
      currentChartDataPointHoverIndex: null,
      lineChartData: null,
      custodianChartDataError: null,
      custodianChartData: null,
      custodianChartRequestPending: false,
      loadDelayedHoldings: false,
      removeRowDialogCount: 0,
      showReconnectDialog: false,
      isRemoveFlow: false
    };

    if (!props.defaultTab === false) {
      const tabs = this.getTabs();
      const tabIndex = tabs.findIndex(tab => tab.key === props.defaultTab);
      if (tabIndex !== -1) {
        this.state.tabIndex = tabIndex;
      }
    }

    this.shouldShowHoldingsBadge =
      this.isReadOnly() === false &&
      shouldShowHoldingsExpansionTipForCustodian(store.getState(), this.props.custodianId);

    this.nameRef = React.createRef();
    this.contextMenuRef = React.createRef();
    this.chartContextMenuRef = React.createRef();
    this.prevOwnershipVal = React.createRef();
    this.manualOverrideToolTipRef = React.createRef();
    this.deleteConfirmationPromise = new DeferredPromise();
    this.scrollTop = React.createRef();
    this.detailsOverlayRef = React.createRef();

    this.setCustodianValueBasedOnHoldings();
  }

  componentDidUpdate(oldProps, oldState) {
    this.onComponentUpdateMaintainScroll(oldProps, oldState);
    if (oldProps.custodianId !== this.props.custodianId) {
      const urlHashParams = getHashParams(this.props.location);
      const id = urlHashParams[hashParams.ID];
      if (this.props.previousDetailsState?.details?.info?.id === id) {
        this.props.setCurrentStateAction(this.props.previousDetailsState);
      } else {
        this.props.setInnerDetailsStateAction(this.scrollTop.current);
      }
      if (id === this.props.custodianId) {
        this.onMount(1);
      }
    }
    if (
      oldState.custodianChartData !== this.state.custodianChartData ||
      oldProps.selectedChartTimeRange !== this.props.selectedChartTimeRange ||
      this.prevOwnershipVal.current !== this.detailsInfo.ownership ||
      (this.props.isCustodianChartFetchPending === false && oldProps.isCustodianChartFetchPending === true)
    ) {
      this.setState({ lineChartData: this.getLineChartData(this.state.custodianChartData) });
    }

    if (oldProps.isFetchPending === true && this.props.isFetchPending === false) {
      const holdingIdToFlash = getHashParams(window.location)[hashParams.HOLDING_ID];
      if (!holdingIdToFlash === false) {
        setTimeout(() => {
          document.flashElement(holdingIdToFlash);
        }, 200);
      }
    }

    const urlHashParams = getHashParams(this.props.location);
    const tabIndexValue = urlHashParams[hashParams.TAB_INDEX];
    const tabs = this.getTabs();
    const tabIndexFound = tabs.findIndex(tab => tab.key === tabIndexValue);
    const tabIndex = tabIndexFound !== -1 ? tabIndexFound : 0;
    if (tabIndex === -1 && tabIndex !== this.state.tabIndex) {
      this.setState({
        tabIndex: 0
      });
    } else if (tabIndex !== this.state.tabIndex) {
      this.setState({
        tabIndex
      });
    }

    this.prevOwnershipVal.current = this.detailsInfo.ownership;
  }

  componentDidMount() {
    this.onMount();

    if (this.detailsOverlayRef.current) {
      this.detailsOverlayRef.current.addEventListener("scroll", this.handleScroll, { passive: true });
    }
  }

  componentWillUnmount() {
    if (this.detailsOverlayRef.current) {
      this.detailsOverlayRef.current.removeEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll = () => {
    const scrollTop = this.detailsOverlayRef.current?.scrollTop;
    this.scrollTop.current = scrollTop ? scrollTop : 0;
  };

  onMount = async force => {
    this.setDefaultTab();
    await this.fillEmptyColumns();
    await getDetailsBlockPromise();
    this.getCustodianDetails(force);

    setTimeout(() => {
      this.setState({
        loadDelayedHoldings: true
      });
    }, 500);
  };

  onComponentUpdateMaintainScroll = (oldProps, _) => {
    if (this.props.details?.info && oldProps.details?.info?.id !== this.props.details.info.id) {
      // On coming back to the previous details screen maintain scroll position
      const urlHashParams = getHashParams(this.props.location);
      const id = urlHashParams[hashParams.ID];
      if (this.props.details.info.id === id) {
        const scrollTop = this.props.custodianDetailsScrollTop;
        this.detailsOverlayRef.current.scrollTop = scrollTop;
      }
    }
  };

  fillEmptyColumns = () => {
    return new Promise(resolve => {
      if (!("name" in this.detailsInfo && "value" in this.detailsInfo)) {
        const portfolioTicker = getTickerUsingShortName(this.props.currentPortfolio.currency);
        this.props.updateCustodian(
          true,
          this.detailsInfo.id,
          {
            name: i18n.t("custodianDetails.emptyTitle").replace("%category%", this.category),
            value: 0,
            valueTickerId: portfolioTicker.id,
            valueExchangeRate: getExchangeRate(
              getTickerUsingId(portfolioTicker.id).shortName,
              this.props.currentPortfolio.currency
            ),
            ...this.detailsInfo
          },
          true,
          () => {
            resolve();
          }
        );
      } else if (
        ("name" in this.detailsInfo && !this.detailsInfo.name) ||
        ("value" in this.detailsInfo && !this.detailsInfo.value && this.detailsInfo.value !== 0)
      ) {
        const portfolioTicker = getTickerUsingShortName(this.props.currentPortfolio.currency);
        const tickerId = this.detailsInfo.valueTickerId || portfolioTicker.id;
        this.props.updateCustodian(
          true,
          this.detailsInfo.id,
          {
            name: this.detailsInfo.name || i18n.t("custodianDetails.emptyTitle").replace("%category%", this.category),
            value: this.detailsInfo.value || 0,
            valueTickerId: tickerId,
            valueExchangeRate: getExchangeRate(
              getTickerUsingId(tickerId).shortName,
              this.props.currentPortfolio.currency
            )
          },
          true,
          () => {
            resolve();
          }
        );
      } else {
        resolve();
      }
    });
  };

  setDefaultTab = () => {
    if (getHashParams(window.location)[hashParams.DEFAULT_TAB] === modalValues.DOCUMENTS) {
      const tabs = this.getTabs();
      this.setTab(tabs.length - 1);
    } else if (getHashParams(window.location)[hashParams.DEFAULT_TAB] === detailsTabs.ASSORTED) {
      const tabs = this.getTabs();
      const tabIndex = tabs.findIndex(tab => tab.key === detailsTabs.ASSORTED);
      this.setTab(tabIndex);
    }
  };

  setCustodianValueBasedOnHoldings() {
    // For crypto linked items the parent custodian value can go
    // out of sync from the total of child custodians as the
    // child custodian's value is calculated using the latest ticker rate
    // whereas the parent custodian's value is fetched by the server
    // periodically. This fix handles that
    if (
      this.props.custodian &&
      !this.props.custodian.parentId === false &&
      isCryptoLinkingService(this.props.custodian.linkType)
    ) {
      this.props.updateParentCustodianValueWithHoldingsTotal(this.props.custodian.parentId);
    }
  }

  get detailsInfo() {
    if (this.props.details) {
      let info = {
        ...this.props.custodian,
        ...this.props.details.info
      };

      if (this.props.custodian && this.props.custodian.tsModified - this.props.details.info.tsModified > 0) {
        info = {
          ...this.props.details.info,
          ...this.props.custodian
        };
      }

      if (info.irrType !== this.props.details.info.irrType) {
        info.irrType = this.props.details.info.irrType;
      }

      return info;
    }

    return {
      ...this.props.custodian
    };
  }

  get isAssetMovedToDebt() {
    const { linkType, linkContainer } = this.detailsInfo;
    return this.category === category.DEBT && isAssetCustodian(linkContainer, linkType);
  }

  get category() {
    return this.props.category || (this.props.details && this.props.details.meta.category) || category.ASSET;
  }

  get isTabsLoading() {
    const { details } = this.props;

    const selectedTab = this.getTabs()[this.state.tabIndex];

    if (
      selectedTab.key === detailsTabs.PARENT_DETAILS ||
      selectedTab.key === detailsTabs.HOME ||
      selectedTab.key === detailsTabs.LINKED_PORTFOLIO
    ) {
      return false;
    }

    if (!details || (this.shouldShowHoldings() && !this.state.loadDelayedHoldings)) {
      return true;
    }

    return false;
  }

  get holdingsData() {
    const { details } = this.props;
    let holdings = [];
    let total = 0;
    let holdingsWithUnMatchValue = null;
    if (details && details.history && details.history.length > 0 && this.state.loadDelayedHoldings) {
      holdings = details.holdings;
      for (const holding of holdings) {
        holding.symbol = holding.symbol ? holding.symbol.replace(/\.CC$/, "") : holding.symbol;
        holding.valueInPortfolioCurrency = convertCurrency(
          holding.value,
          getTickerUsingId(holding.valueTickerId).shortName,
          this.props.currentPortfolio.currency
        );

        total += holding.valueInPortfolioCurrency;

        if (holding.value !== holding.sourceValue) {
          holdingsWithUnMatchValue = holdingsWithUnMatchValue || {};
          holdingsWithUnMatchValue[holding.id] = holding.sourceValue;
        }
      }
    }

    return {
      holdings,
      total,
      holdingsWithUnMatchValue
    };
  }

  get showManualOverrideBadge() {
    const isNFTDetails =
      [
        accountLinkingService.ZERION,
        accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
        accountLinkingService.IN_HOUSE_CRYPTO_API
      ].includes(this.detailsInfo.linkType) && this.detailsInfo.linkContainer === accountLinkingContainers.NFT;
    const isManualOverriddenForNonNft =
      [accountLinkingService.ZILLOW, accountLinkingService.DOMAINS, accountLinkingService.CARS].includes(
        this.detailsInfo.linkType
      ) && this.detailsInfo.sourceValue !== this.detailsInfo.value;
    const isManualOverridden =
      isNFTDetails &&
      this.detailsInfo.sourceValue &&
      (this.holdingsData.holdingsWithUnMatchValue ||
        (this.detailsInfo.parentId !== null && this.detailsInfo.sourceValue !== this.detailsInfo.value));

    return isManualOverridden || isManualOverriddenForNonNft || this.state.isRefreshPending;
  }

  canShowChart() {
    return this.category === category.ASSET;
  }

  getCustodianChartData() {
    this.setState({ custodianChartRequestPending: true, custodianChartDataError: null });

    if (this.props.custodianId) {
      this.props.getCustodianChart(
        this.props.custodianId,
        chartData => {
          this.setState({
            custodianChartRequestPending: false,
            custodianChartData: chartData,
            lineChartData: this.getLineChartData(chartData)
          });
        },
        error => {
          this.setState({ custodianChartRequestPending: false, custodianChartDataError: error });
        }
      );
    }
  }

  getCustodianDetails(force = 0) {
    if (force === 1 || this.props.details === null || this.detailsInfo.id !== this.props.custodianId) {
      this.props.fetchDetails(this.props.custodianId, force === 1);

      this.getCustodianChartData();
    }
  }

  handleLoaderRefreshClick(e) {
    this.getCustodianDetails();
  }

  handleDescriptionChange(value) {
    const newDetails = this.props.details;

    if (!newDetails) {
      return;
    }

    this.handleCustodianUpdate(
      {
        description: value
      },
      true
    );

    this.props.updateDashboard([this.detailsInfo.id]);
  }

  handleDescriptionKeyDown(e) {
    if (e.key === "Enter") {
      e.target.blur();
    }
  }

  isCustodianLinked() {
    return (this.props.accountSubscriptionIsActive || this.isReadOnly()) && !this.detailsInfo.linkType === false;
  }

  handleOptionsClick(e) {
    if (!this.props.details) {
      return;
    }

    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }

    const menu = (() => {
      let removeOption = isCustodianAddedToday(this.detailsInfo.id)
        ? contextMenuItemType.REMOVE
        : contextMenuItemType.ARCHIVE;
      removeOption = {
        ...removeOption,
        description: GridRowData.linkedAccountRemoveDescription(this.props.custodian)
      };
      let menuItems = [[contextMenuItemType.MOVE_ROW, contextMenuItemType.RENAME, removeOption]];
      const connectivityCenterDataForCurrentPortfolio = connectivityCenterDataForPortfolioSelector(
        store.getState(),
        this.props.currentPortfolio.id
      );
      const connectivityData =
        connectivityCenterDataForCurrentPortfolio &&
        connectivityCenterDataForCurrentPortfolio.find(
          data => data.providerAccountId === this.detailsInfo.linkProviderAccountId
        );

      if (this.props.custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
        menuItems.push([
          {
            ...contextMenuItemType.UNLINK,
            label: i18n.t("unlinkPortfolio"),
            description: this.props.custodian.linkAccountName
          }
        ]);
        return menuItems;
      }

      if (
        this.detailsInfo.status === 1 &&
        ["ITEM_LOGIN_REQUIRED", "REAL_TIME_MFA_REQUIRED", "ADDL_AUTHENTICATION_REQUIRED"].includes(
          this.detailsInfo.statusInfo
        )
      ) {
        if (connectivityData && (connectivityData.reconnectInfo || connectivityData.disconnect)) {
          if (!connectivityData.reconnectInfo) {
            // show Reconnect if there is not active recommendation to reconnect already
            menuItems.unshift([contextMenuItemType.RECONNECT]);
          }
          if (!connectivityData.disconnect && !this.detailsInfo.parentId) {
            menuItems.unshift([contextMenuItemType.TRACK_MANUALLY]);
          }
          menuItems.unshift([
            getStartOverContextMenuItem(this.detailsInfo.linkProviderAccountId, connectivityData, true)
          ]);
        } else {
          if (!this.detailsInfo.parentId) {
            menuItems.unshift([contextMenuItemType.TRACK_MANUALLY]);
          }
          menuItems.unshift([contextMenuItemType.RECONNECT]);
        }
        menuItems.unshift([
          getUpdateValuesContextMenuItem(
            getCustodianLastUpdateDetails(this.detailsInfo.id),
            { is2FAError: true },
            connectivityData
          )
        ]);
        menuItems.push([
          {
            ...contextMenuItemType.UNLINK,
            label: !isCustodianAddedToday(this.detailsInfo.id) ? "Disconnect & Archive" : "Disconnect & Remove",
            description: GridRowData.linkedAccountDescription(this.props.custodian)
          }
        ]);
        if (isAppInWhiteLabelMode()) {
          menuItems.splice(1, 0, [getManagedAssetContextMenuItem(!this.props.details.info.aum === false)]);
        }
        return menuItems;
      }

      const isErrorCellShown =
        this.props.accountSubscriptionIsActive && shouldShowLinkingErrorForCustodian(this.props.custodian) === true;
      if (isErrorCellShown) {
        const badConnectionContextMenuItems = [
          {
            ...contextMenuItemType.BAD_CONNECTION,
            descriptionText: GridRowData.linkedAccountDescription(this.props.custodian),
            lastUpdatedDetails: getCustodianLastUpdateDetails(this.detailsInfo.id)
          }
        ];
        const activeRecommendationContextMenuItem = [];
        const passiveRecommendationReconnectContextMenuItem = [];
        const passiveRecommendationTrackManuallyContextMenuItem = [];
        if (connectivityData && (connectivityData.reconnectInfo || connectivityData.disconnect)) {
          // Active Recommendation
          if (!isCryptoLinkingService(this.detailsInfo.linkType)) {
            activeRecommendationContextMenuItem.push(
              getStartOverContextMenuItem(this.detailsInfo.linkProviderAccountId, connectivityData, false)
            );
            if (!connectivityData.reconnectInfo) {
              // show Reconnect if there is not active recommendation to reconnect already
              passiveRecommendationReconnectContextMenuItem.push({
                ...contextMenuItemType.RECONNECT,
                doNotShowReconnectOptionsDialog: true
              });
            }
          }
          // show Track Manually if there is not active recommendation to disconnect already
          if (!connectivityData.disconnect && !this.detailsInfo.parentId) {
            passiveRecommendationTrackManuallyContextMenuItem.push(contextMenuItemType.TRACK_MANUALLY);
          }
        } else {
          // passive Recommendation when no active recommendation is present
          if (!isCryptoLinkingService(this.detailsInfo.linkType)) {
            passiveRecommendationReconnectContextMenuItem.push({
              ...contextMenuItemType.RECONNECT,
              doNotShowReconnectOptionsDialog: true
            });
          }
          if (!this.detailsInfo.parentId) {
            passiveRecommendationTrackManuallyContextMenuItem.push(contextMenuItemType.TRACK_MANUALLY);
          }
        }
        menuItems.unshift(
          badConnectionContextMenuItems,
          activeRecommendationContextMenuItem,
          passiveRecommendationReconnectContextMenuItem,
          passiveRecommendationTrackManuallyContextMenuItem
        );

        menuItems.push([
          {
            ...contextMenuItemType.UNLINK,
            label: !isCustodianAddedToday(this.detailsInfo.id) ? "Disconnect & Archive" : "Disconnect & Remove",
            description: GridRowData.linkedAccountDescription(this.props.custodian)
          }
        ]);
        return menuItems;
      }

      const linkCrypto =
        this.category === category.ASSET
          ? [
              contextMenuItemType.LINK_CRYPTO,
              contextMenuItemType.CONNECT_STOCK,
              contextMenuItemType.CONNECT_CRYPTO,
              contextMenuItemType.CONNECT_METAL,
              contextMenuItemType.CONNECT_EXTRAS,
              contextMenuItemType.CONNECT_PORTFOLIOS,
              ...(this.detailsInfo.valueTickerId === 171
                ? [contextMenuItemType.ENTER_MANUALLY]
                : [contextMenuItemType.ENTER_QTY_PRICE])
            ]
          : [contextMenuItemType.CONNECT_PORTFOLIOS];

      if (this.isArchived()) {
        menuItems = [[contextMenuItemType.UNARCHIVE, contextMenuItemType.DELETE_PERMANENTLY]];
      } else if (this.category === category.INSURANCE) {
        menuItems = [[contextMenuItemType.RENAME, contextMenuItemType.DELETE]];
      } else if (this.category !== category.INSURANCE) {
        const isCustodianLinked = this.isCustodianLinked();
        const { linkType } = this.props.custodian;
        const linkedAccountDescription = GridRowData.linkedAccountDescription(this.props.custodian);

        if (
          [
            accountLinkingService.ZERION,
            accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
            accountLinkingService.IN_HOUSE_CRYPTO_API,
            accountLinkingService.IN_HOUSE_OAUTH
          ].includes(linkType)
        ) {
          const linkingMenu =
            isCustodianLinked === true
              ? !this.detailsInfo.parentId
                ? [
                    getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(this.detailsInfo.id)),
                    contextMenuItemType.TRACK_MANUALLY,
                    {
                      ...contextMenuItemType.UNLINK_NON_ACCOUNTS,
                      label: !isCustodianAddedToday(this.detailsInfo.id)
                        ? "Disconnect & Archive"
                        : "Disconnect & Remove",
                      description: linkedAccountDescription
                    }
                  ]
                : [
                    getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(this.detailsInfo.id)),
                    {
                      ...contextMenuItemType.UNLINK_NON_ACCOUNTS,
                      label: !isCustodianAddedToday(this.detailsInfo.id)
                        ? "Disconnect & Archive"
                        : "Disconnect & Remove",
                      description: linkedAccountDescription
                    }
                  ]
              : [getLinkContextMenuItem(this.category), ...linkCrypto];
          menuItems.unshift(linkingMenu);
        } else if ([accountLinkingService.ZILLOW].includes(linkType)) {
          const updateValueContextMenuItem = getUpdateValuesContextMenuItem(
            getCustodianLastUpdateDetails(this.detailsInfo.id)
          );
          const linkingMenu =
            isCustodianLinked === true
              ? [
                  { ...updateValueContextMenuItem, label: "Sync Value" },
                  {
                    ...contextMenuItemType.UNLINK_NON_ACCOUNTS,
                    label: "Track Manually",
                    description: linkedAccountDescription
                  }
                ]
              : [getLinkContextMenuItem(this.category), ...linkCrypto];
          menuItems.unshift(linkingMenu);
        } else if ([accountLinkingService.CARS].includes(linkType)) {
          const updateValueContextMenuItem = getUpdateValuesContextMenuItem(
            getCustodianLastUpdateDetails(this.detailsInfo.id)
          );
          const linkingMenu =
            isCustodianLinked === true
              ? [
                  { ...updateValueContextMenuItem, label: "Sync Value" },
                  {
                    ...contextMenuItemType.UNLINK_NON_ACCOUNTS,
                    label: "Track Manually",
                    description: linkedAccountDescription
                  }
                ]
              : [getLinkContextMenuItem(this.category), ...linkCrypto];
          menuItems.unshift(linkingMenu);
        } else if ([accountLinkingService.DOMAINS].includes(linkType)) {
          const updateValueContextMenuItem = getUpdateValuesContextMenuItem(
            getCustodianLastUpdateDetails(this.detailsInfo.id)
          );
          const linkingMenu =
            isCustodianLinked === true
              ? [
                  { ...updateValueContextMenuItem, label: "Sync Value" },
                  {
                    ...contextMenuItemType.UNLINK_NON_ACCOUNTS,
                    label: "Track Manually",
                    description: linkedAccountDescription
                  }
                ]
              : [getLinkContextMenuItem(this.category), ...linkCrypto];
          menuItems.unshift(linkingMenu);
        } else if (
          [
            accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
            accountLinkingService.IN_HOUSE_CRYPTO_API,
            accountLinkingService.IN_HOUSE_OAUTH
          ].includes(linkType)
        ) {
          menuItems.unshift(
            [getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(this.detailsInfo.id))],
            [
              {
                ...contextMenuItemType.UNLINK,
                label: !isCustodianAddedToday(this.detailsInfo.id) ? "Disconnect & Archive" : "Disconnect & Remove",
                description: linkedAccountDescription
              }
            ]
          );
        } else {
          let isLinkedSpecialCase = false;
          const valueTicker = getTickerUsingShortName(getTickerUsingId(this.detailsInfo.valueTickerId).shortName);

          if (
            [
              tickerTypes.FUND,
              tickerTypes.STOCK,
              tickerTypes.CRYPTO,
              tickerTypes.BOND,
              tickerTypes.DERIVATIVE,
              tickerTypes.INDEX
            ].includes(valueTicker.type) ||
            valueTicker.subType === tickerSubTypes.PRECIOUS_METALS
          ) {
            isLinkedSpecialCase = true;
          }
          if (isCustodianLinked) {
            menuItems.push([getUpdateValuesContextMenuItem(getCustodianLastUpdateDetails(this.detailsInfo.id))]);
            menuItems.push([contextMenuItemType.RECONNECT]);
            if (!this.detailsInfo.parentId) {
              menuItems.push([contextMenuItemType.TRACK_MANUALLY]);
            }
            menuItems.push([
              {
                ...contextMenuItemType.UNLINK,
                label: !isCustodianAddedToday(this.detailsInfo.id) ? "Disconnect & Archive" : "Disconnect & Remove",
                description: linkedAccountDescription
              },
              getLinkedAccountDescriptionMenuItem(this.detailsInfo.linkType)
            ]);
          } else if (isLinkedSpecialCase) {
            menuItems.push([getTickerDescriptionContextMenuItem(valueTicker)]);
          } else {
            if (this.category === category.ASSET) {
              linkCrypto.shift();
            }
            menuItems.push([...linkCrypto]);
          }

          if (this.category === category.ASSET && this.props.isReadOnlyWlClient === false) {
            if (!this.props.details.info.type === true && isAppInWhiteLabelMode() === true) {
              menuItems.unshift([getManagedAssetContextMenuItem(!this.props.details.info.aum === false)]);
            }
          }
        }
      }

      // Remove connection options for read only WLK clients
      if (this.props.isReadOnlyWlClient) {
        if (this.category === category.ASSET) {
          menuItems[1].shift();
        } else if (this.category === category.DEBT) {
          menuItems.pop();
        }
      }

      return menuItems;
    })();

    const targetPosition = e.target.getBoundingClientRect();
    this.contextMenuRef.current.show(
      menu,
      targetPosition.left + targetPosition.width,
      targetPosition.top,
      false,
      e.target
    );
    this.setState({ ...this.state, isShowingContextMenu: true });
  }

  handleContextMenuSelection(item) {
    if (item.id === contextMenuItemType.RENAME.id) {
      this.editName();
    } else if (item.id === contextMenuItemType.LINK.id) {
      LinkAccountComponentExports.linkAccount(this.props.history, this.props.location, this.detailsInfo.id);
    } else if (item.id === contextMenuItemType.UNLINK.id) {
      const dialogExceptionList = [
        accountLinkingService.ZILLOW,
        accountLinkingService.CARS,
        accountLinkingService.DOMAINS
      ];
      const custodiansWithSameAccount = custodiansLinkedToSameAccount(
        this.detailsInfo.id,
        false,
        true,
        item.isRemoveFlow
      ).custodians.length;
      if (dialogExceptionList.includes(this.detailsInfo.linkType) === false && custodiansWithSameAccount > 0) {
        this.setState({
          showUnlinkAllConnectionsDialog: true,
          unlinkDialogCount: custodiansWithSameAccount,
          isRemoveFlow: item.isRemoveFlow
        });
      } else {
        this.props.unlinkAccountWithCustodian(this.detailsInfo.id, true, () => {
          this.props.getConnectivityCenterData();
        });
      }
    } else if (item.id === contextMenuItemType.UNARCHIVE.id) {
      this.unarchiveCustodian();
    } else if (item.id === contextMenuItemType.DELETE_PERMANENTLY.id) {
      this.permanentlyDeleteCustodian();
    } else if (item.id === contextMenuItemType.RECONNECT.id) {
      LinkAccountComponentExports.reconnect(
        this.props.history,
        this.props.location,
        this.detailsInfo.id,
        item.doNotShowReconnectOptionsDialog
      );
    } else if (item.id === contextMenuItemType.REAUTH.id) {
      LinkAccountComponentExports.linkAccount(
        this.props.history,
        this.props.location,
        this.detailsInfo.id,
        linkAccountMode.EDIT
      );
    } else if (item.id === contextMenuItemType.UPDATE_VALUES.id) {
      this.refreshCustodian(this.detailsInfo.id);
    } else if (item.id === contextMenuItemType.LINK_ACCOUNT_PICK_COUNTRY.id) {
      PickLinkAccountCountry.show(this.props.history, this.props.location, this.detailsInfo.id);
    } else if (item.id === contextMenuItemType.REMOVE.id || item.id === contextMenuItemType.ARCHIVE.id) {
      if (!isCustodianAddedToday(this.detailsInfo.id) || !this.detailsInfo.parentId === false) {
        return this.handleRemoveCompletedRow(() => {
          this.handleOverlayDismiss();
          setTimeout(() => {
            this.props.archiveCustodian(this.detailsInfo.sectionId, this.detailsInfo.id, false, {
              isArchiveOnDelete: true
            });
            this.props.updateDashboard([this.detailsInfo.id]);
          }, 20);
        });
      } else {
        return this.handleRemoveCompletedRow(() => {
          this.handleOverlayDismiss();

          setTimeout(() => {
            this.props.deleteCustodian(this.detailsInfo.id, false);
            this.props.updateDashboard([this.detailsInfo.id]);
          }, 20);
        });
      }
    } else if (item.id === contextMenuItemType.LINK_AUTH_ERROR.id) {
      LinkAccountComponentExports.resolveLinkError(this.props.history, this.props.location, this.detailsInfo.id);
    } else if (item.id === contextMenuItemType.MANAGED_ASSET.id) {
      const newDetails = this.props.details;
      newDetails.info.aum = !this.props.details.info.aum === true ? 1 : 0;
      this.props.updateCustodianDetails(newDetails);
      this.props.updateCustodian(false, this.detailsInfo.id, {
        aum: newDetails.info.aum
      });
      this.props.updateDashboard([this.detailsInfo.id]);
    } else {
      this.props.handleRowContextMenuSelection(item);
    }
  }

  handleRemoveCompletedRow = (callback = () => null) => {
    const shouldDisconnect = () => {
      const disconnectExceptionList = [
        accountLinkingService.ZILLOW,
        accountLinkingService.CARS,
        accountLinkingService.DOMAINS
      ];

      if (
        !this.detailsInfo.linkType === false &&
        disconnectExceptionList.includes(this.detailsInfo.linkType) === false &&
        isCryptoLinkingService(this.detailsInfo.linkType) === true &&
        this.detailsInfo.linkContainer !== "nft"
      ) {
        return true;
      }
      return false;
    };
    if (shouldDisconnect()) {
      this.deleteConfirmationPromise = new DeferredPromise();
      setTimeout(() => {
        this.deleteConfirmationPromise.resolve(false);
        this.handleContextMenuSelection({ ...contextMenuItemType.UNLINK, isRemoveFlow: true });
      }, 0);
      return this.deleteConfirmationPromise;
    }

    setTimeout(async () => {
      var deleteType = this.isCustodianLinked() ? 2 : 1;
      const rowNameToDelete =
        !this.props.custodian.parentId === false
          ? custodianSelector(store.getState(), this.props.custodian.parentId).name
          : this.detailsInfo.name;
      var numberOfRowsToDelete = 1;

      if (!this.props.custodian.parentId === false) {
        deleteType = 3;

        numberOfRowsToDelete = custodiansWithSameParentIdSelector(
          store.getState(),
          this.props.currentPortfolio.id,
          this.props.custodian.parentId
        ).length;
      } else if (this.detailsInfo.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
        deleteType = 4;
        numberOfRowsToDelete = custodiansLinkedToSameAccount(this.props.custodian.id).portfolioCustodiansMap[
          this.props.currentPortfolio.id
        ].length;
      }

      this.setState({
        deleteType: deleteType,
        numberOfRowsToDelete: numberOfRowsToDelete,
        rowNameToDelete: rowNameToDelete
      });
      const isConfirm = await this.deleteConfirmationPromise.catch(err => null);
      if (isConfirm) {
        callback();
      }
    });

    this.deleteConfirmationPromise = new DeferredPromise();
    return this.deleteConfirmationPromise;
  };

  handleDeleteConfirmPositiveButtonClick = () => {
    this.setState({
      deleteType: 0,
      numberOfRowsToDelete: 0,
      rowNameToDelete: ""
    });
    this.deleteConfirmationPromise.resolve(true);
  };

  handleDeleteConfirmNegativeButtonClick = () => {
    this.setState({
      deleteType: 0,
      numberOfRowsToDelete: 0,
      rowNameToDelete: ""
    });
    this.deleteConfirmationPromise.resolve(false);
  };

  refreshCustodian(custodianId) {
    if (this.detailsInfo.sourceValue && this.detailsInfo.sourceValue !== this.detailsInfo.value) {
      this.props.updateCustodian(false, this.detailsInfo.id, {
        value: this.detailsInfo.sourceValue
      });
    }
    const isRefreshing = LinkAccountComponentExports.refreshAccountValues(
      this.props.history,
      this.props.location,
      custodianId,
      custodian => {
        this.setState({ ...this.state, isRequestPending: false });

        if (custodian.id === this.props.custodianId) {
          const newDetails = this.props.details;
          newDetails.info = custodian;
          this.props.updateCustodianDetails(newDetails);
        }
      },
      error => {
        this.setState({ ...this.state, isRequestPending: false });
      },
      {
        force: 1
      }
    );

    if (isRefreshing === true) {
      var showReconnectDialog = false;
      if (
        this.detailsInfo.linkType !== accountLinkingService.PLAID &&
        !isCryptoLinkingService(this.detailsInfo.linkType) &&
        this.lastRefreshRequestTs &&
        new Date().getTime() - this.lastRefreshRequestTs <= 2 * 60 * 1000
      ) {
        showReconnectDialog = true;
        this.lastRefreshRequestTs = 0;
      } else {
        this.lastRefreshRequestTs = new Date().getTime();
      }

      this.setState({ ...this.state, isRequestPending: true, showReconnectDialog: showReconnectDialog });
    }
  }

  unarchiveCustodian() {
    this.props.unarchiveCustodian(this.props.currentPortfolio.id, this.detailsInfo.id);
    this.handleOverlayDismiss();
  }

  permanentlyDeleteCustodian() {
    this.props.deleteCustodianPermanently(this.detailsInfo.id);
    this.handleOverlayDismiss();
  }

  handleContextMenuDismiss() {
    this.setState({ ...this.state, isShowingContextMenu: false });
  }

  handleNameChange(value) {
    const newDetails = this.props.details;
    newDetails.info.name = value;
    this.props.updateCustodianDetails(newDetails);

    this.props.updateCustodian(false, this.detailsInfo.id, {
      name: value
    });
    this.props.updateDashboard([this.detailsInfo.id]);
  }

  handleNotesChange(value) {
    const newDetails = this.props.details;
    newDetails.info.note = value;
    this.props.updateCustodianDetails(newDetails);
    this.props.updateCustodian(false, this.detailsInfo.id, {
      note: value
    });
  }

  handleCustodianUpdate(propertiesToUpdate, shouldFireCustodianUpdate = false, onCompletion = null) {
    const newDetails = this.props.details;
    const newInfo = { ...newDetails.info, ...propertiesToUpdate };
    newDetails.info = newInfo;

    this.props.updateCustodianDetails(newDetails);

    if (shouldFireCustodianUpdate === true) {
      this.props.updateCustodian(false, this.detailsInfo.id, propertiesToUpdate, true, onCompletion);
    } else {
      this.props.updateDashboard([this.detailsInfo.id]);
    }
  }

  handleLinkingErrorButtonClick(e) {
    const menuItems = [[contextMenuItemType.INACTIVE_CONNECTION, contextMenuItemType.LINK_AUTH_ERROR]];

    const targetPosition = e.target.getBoundingClientRect();
    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top,
      false,
      e.target
    );
  }

  handleOverlayDismiss() {
    DialogOverlay.dismiss(this.props.history, this.props.location);

    if (this.detailsInfo.irrType === irrTypes.COSTBASIS && !this.detailsInfo.cost === true) {
      if (!this.props.custodian === false && !this.props.custodian.previousIrr === false) {
        this.props.updateCustodianInBulkAction(this.props.currentPortfolio.id, [
          {
            ...this.props.custodian,
            ...{ irr: this.props.custodian.previousIrr, irrType: irrTypes.CASHFLOW, previousIrr: null }
          }
        ]);
        this.props.updateCustodianOnDashboard(this.props.custodianId);
      }
    }
    if (
      this.detailsInfo.irrType === irrTypes.CASHFLOW &&
      this.props.details &&
      this.props.details.cashFlow &&
      this.props.details.cashFlow.length === 0
    ) {
      if (!this.props.custodian === false && !this.props.custodian.previousIrr === false) {
        this.props.updateCustodianInBulkAction(this.props.currentPortfolio.id, [
          {
            ...this.props.custodian,
            ...{ irr: this.props.custodian.previousIrr, irrType: irrTypes.COSTBASIS, previousIrr: null }
          }
        ]);
        this.props.updateCustodianOnDashboard(this.props.custodianId);
      }
    }

    if (this.shouldShowHoldingsBadge === true) {
      this.props.updateUserPreferences({
        isHoldingsExpansionTipShownForCustodian: [
          ...this.props.userPreferences.isHoldingsExpansionTipShownForCustodian,
          this.props.custodianId
        ],
        isRevertHoldingsTipShown: this.detailsInfo.id === this.props.userPreferences.showRevertHoldingsTipForCustodianId
      });
      this.props.updateCustodianOnDashboard(this.props.custodianId);
    } else if (this.detailsInfo.id === this.props.userPreferences.showRevertHoldingsTipForCustodianId) {
      this.props.updateUserPreferences({
        isRevertHoldingsTipShown: this.detailsInfo.id === this.props.userPreferences.showRevertHoldingsTipForCustodianId
      });
      this.props.updateCustodianOnDashboard(this.props.custodianId);
    }
  }

  handleUnlinkAllConnectionsDialogPositiveButtonClick(e) {
    this.handleOverlayDismiss();

    setTimeout(() => {
      this.props.unlinkAllConnectedCustodians(this.detailsInfo.id, { isRemoveFlow: this.state.isRemoveFlow });
      this.setState({ isRemoveFlow: false });
    }, 20);
  }

  handleUnlinkAllConnectionsDialogOnDismiss() {
    this.setState({ showUnlinkAllConnectionsDialog: false, isRemoveFlow: false });
  }

  handleReconnectDialogPositiveButtonClick() {
    if (
      this.detailsInfo.linkType !== accountLinkingService.ZILLOW &&
      this.detailsInfo.linkType !== accountLinkingService.CARS &&
      this.detailsInfo.linkType !== accountLinkingService.DOMAINS
    ) {
      LinkAccountComponentExports.reconnect(this.props.history, this.props.location, this.detailsInfo.id);
    }
    this.handleReconnectDialogDismissButtonClick();
  }

  handleReconnectDialogNegativeButtonClick() {
    this.refreshCustodian(this.detailsInfo.id);
    this.handleReconnectDialogDismissButtonClick();
  }

  handleReconnectDialogDismissButtonClick() {
    this.setState({ showReconnectDialog: false });
  }

  handleDetailsCustodianChange(fieldsToUpdate, isAfterCustodianServerUpdate) {
    this.props.updateCustodianInBulkAction(this.props.currentPortfolio.id, [
      {
        ...this.props.custodian,
        ...fieldsToUpdate
      }
    ]);

    if (isAfterCustodianServerUpdate) {
      this.getCustodianChartData();
    }
  }

  getHomeTitle() {
    const { linkType, valueTickerId } = this.detailsInfo;
    const ticker = getTickerUsingId(valueTickerId);

    switch (true) {
      case linkType === accountLinkingService.CARS || this.detailsInfo.subType === tickerSubTypes.CARS:
        return i18n.t("custodianDetails.carsTabTitle");
      case linkType === accountLinkingService.ZILLOW:
        return i18n.t("custodianDetails.homeTabTitle");
      case linkType === accountLinkingService.DOMAINS || this.detailsInfo.subType === tickerSubTypes.DOMAINS:
        return i18n.t("custodianDetails.domainTabTitle");
      case ticker.type === tickerTypes.STOCK:
        return i18n.t("custodianDetails.stockTabTitle");
      case ticker.type === tickerTypes.CRYPTO:
        return i18n.t("custodianDetails.cryptoTabTitle");
      case ticker.type === tickerTypes.FUND:
        return i18n.t("custodianDetails.fundTabTitle");
      case ticker.type === tickerTypes.BOND:
        return i18n.t("custodianDetails.bondTabTitle");
      case ticker.type === tickerTypes.DERIVATIVE:
        return i18n.t("custodianDetails.derivativeTabTitle");
      case ticker.type === tickerTypes.INDEX:
        return i18n.t("custodianDetails.indexTabTitle");
      case ticker.subType === tickerSubTypes.PRECIOUS_METALS:
        return i18n.t("custodianDetails.metalTabTitle");
      default:
        return i18n.t("custodianDetails.homeTabTitle");
    }
  }

  getTabs = () => {
    const { details } = this.props;
    const documents = custodianDocumentsSelector(store.getState(), this.props.custodianId);
    const ownershipPanel = (
      <OwnerShipPanel
        details={details}
        custodianId={this.detailsInfo.id}
        detailsInfo={this.detailsInfo}
        ownershipValue={this.detailsInfo.ownership}
        isReadOnly={this.isReadOnly()}
        category={this.category}
        chartStartDate={this.getChartStartDate()}
        getCustodianChartData={this.getCustodianChartData}
      />
    );
    const assortedFlagsPanel = (
      <AssortedFlagsPanel
        details={details}
        custodianId={this.detailsInfo.id}
        detailsInfo={this.detailsInfo}
        ownershipValue={this.detailsInfo.ownership}
        isReadOnly={this.isReadOnly()}
        currency={this.props.currentPortfolio.currency}
        onCustodianUpdate={this.handleCustodianUpdate}
        onTabChange={this.handleTabChange}
        category={this.category}
        chartStartDate={this.getChartStartDate()}
        getCustodianChartData={this.getCustodianChartData}
      />
    );
    const reportingPanel = (
      <ReportingPanel
        details={details}
        custodianId={this.detailsInfo.id}
        detailsInfo={this.detailsInfo}
        ownershipValue={this.detailsInfo.ownership}
        isReadOnly={this.isReadOnly()}
        currency={this.props.currentPortfolio.currency}
        onCustodianUpdate={this.handleCustodianUpdate}
        onTabChange={this.handleTabChange}
        category={this.category}
        chartStartDate={this.getChartStartDate()}
        userPreferences={this.props.userPreferences}
      />
    );
    const homePanel = (
      <HomePanel
        isReadOnly={this.isReadOnly()}
        isLoading={!this.props.details}
        detailsInfo={this.detailsInfo}
        portfolio={this.props.currentPortfolio}
        handleDetailsCustodianChange={this.handleDetailsCustodianChange}
      />
    );
    const nftPanel = details && <NFTPanel isLoading={!this.props.details} detailsInfo={this.detailsInfo} />;
    const linkedPortfolioPanel = (
      <LinkedPortfolioPanel
        portfolioId={this.detailsInfo.linkProviderAccountId}
        details={details}
        custodianId={this.detailsInfo.id}
        detailsInfo={this.detailsInfo}
        ownershipValue={this.detailsInfo.ownership}
        isReadOnly={this.isReadOnly()}
        currency={this.props.currentPortfolio.currency}
        onCustodianUpdate={this.handleCustodianUpdate}
        onTabChange={this.handleTabChange}
        category={this.category}
        chartStartDate={this.getChartStartDate()}
      />
    );
    const notesPanel = details && (
      <NotesPanel
        isReadOnly={this.isReadOnly()}
        value={this.detailsInfo.note}
        description={i18n.t("custodianDetails.assetNotesDescription")}
        placeholder={i18n.t("custodianDetails.assetNotesPlaceholder")}
        onChange={this.handleNotesChange}
      />
    );
    const insuranceDetailsPanel = details && (
      <InsuranceDetailsPanel
        isReadOnly={this.isReadOnly()}
        details={this.props.details}
        currency={this.props.currentPortfolio.currency}
        description={i18n.t("custodianDetails.assetNotesDescription")}
        placeholder={i18n.t("custodianDetails.notesPlaceholder")}
        onChange={this.handleNotesChange}
      />
    );
    const documentsPanel = details && (
      <DocumentsPanel isReadOnly={this.isReadOnly()} category={this.category} custodian={this.detailsInfo} />
    );
    const assetsValuePanel = details && details.history && (
      <AssetValuePanel
        isReadOnly={this.isReadOnly() || this.props.custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO}
        currency={this.props.currentPortfolio.currency}
        lastUpdateTs={this.detailsInfo.tsLastUpdateCheck}
        history={this.props.details.history}
        isHistoryLoaded={this.props.details.isHistoryLoaded}
        isFetchPending={this.props.isFetchPending}
        custodianId={this.detailsInfo.id}
        sectionId={this.detailsInfo.sectionId}
        onCustodianUpdate={this.handleCustodianUpdate}
        cost={this.detailsInfo.cost}
        costExchangeRate={this.detailsInfo.costExchangeRate}
        costTickerId={this.detailsInfo.costTickerId}
        value={this.detailsInfo.value}
        valueInPortfolioCurrency={this.valueForCustodian(false)}
        previousDayValue={this.getPreviousDaysValue()}
        valueTickerId={this.detailsInfo.valueTickerId}
        isCustodianLinked={this.isCustodianLinked()}
        canShowChart={this.canShowChart()}
        currentChartTimeRangeText={this.getCurrentlySelectedChartTimeRangeText()}
        lineChartData={this.state.lineChartData}
        chartStartValue={this.getStartValueForCustodianForSelectedTimeRange()}
        custodianChartRequestPending={this.state.custodianChartRequestPending}
        handleChartHoverSelection={this.handleChartHoverSelection}
        handleChartTimeRangeSelectionButtonClick={this.handleChartTimeRangeSelectionButtonClick}
        ownership={this.props.details.ownership}
        rate={this.props.details.rate}
        detailsInfo={this.detailsInfo}
        custodianChartData={this.props.chartDataForCustodian}
        getCustodianChartData={this.getCustodianChartData}
      />
    );
    const assetsReturnsPanel = details && details.history && (
      <AssetReturnsPanel
        isReadOnly={this.isReadOnly()}
        currency={this.props.currentPortfolio.currency}
        custodianId={this.detailsInfo.id}
        parentId={this.props.custodian?.parentId}
        custodianName={this.detailsInfo.name}
        sectionId={this.detailsInfo.sectionId}
        cost={this.detailsInfo.cost}
        costExchangeRate={this.detailsInfo.costExchangeRate}
        costTickerId={this.detailsInfo.costTickerId}
        value={this.detailsInfo.value}
        valueTickerId={this.detailsInfo.valueTickerId}
        isCustodianLinked={this.isCustodianLinked()}
        history={this.props.details.costHistory}
        isHistoryLoaded={this.props.details.isCostHistoryLoaded}
        isFetchPending={this.props.isFetchPending}
        costType={this.detailsInfo.costType}
        irr={this.detailsInfo.irr}
        irrType={this.detailsInfo.irrType}
        cashflows={this.props.details.cashFlow || []}
        onCustodianUpdate={this.handleCustodianUpdate}
        irrChangeTs={this.props.custodian.irrChangeTs}
        detailsInfo={this.detailsInfo}
        onUpdateCustodianSuccess={custodianId => {
          this.refreshCustodian(custodianId);
        }}
        hasHoldings={details.holdings?.length > 0}
      />
    );

    const debtsHistoryPanel = details && details.history && (
      <DebtHistoryPanel
        isReadOnly={this.isReadOnly() || this.props.custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO}
        currency={this.props.currentPortfolio.currency}
        lastUpdateTs={this.detailsInfo.tsLastUpdateCheck}
        history={this.props.details.history}
        isHistoryLoaded={this.props.details.isHistoryLoaded}
        custodianId={this.detailsInfo.id}
        sectionId={this.detailsInfo.sectionId}
        onCustodianUpdate={this.handleCustodianUpdate}
        valueMultiplier={this.isAssetMovedToDebt ? -1 : 1}
        value={this.detailsInfo.value}
        valueTickerId={this.detailsInfo.valueTickerId}
        isCustodianLinked={this.isCustodianLinked()}
        isFetchPending={this.props.isFetchPending}
        ownership={this.props.details.ownership}
        rate={this.props.details.rate}
        details={this.detailsInfo}
      />
    );

    const holdingsPanel = (() => {
      if (
        details &&
        details.info.id === this.props.custodianId &&
        details.history &&
        details.history.length > 0 &&
        this.state.loadDelayedHoldings
      ) {
        return (
          <HoldingsPanel
            isReadOnly={this.isReadOnly()}
            totalValue={this.detailsInfo.value}
            lastUpdateTs={this.detailsInfo.linkAccountTsCreated}
            currency={this.props.currentPortfolio.currency}
            holdings={this.holdingsData.holdings}
            linkType={this.detailsInfo.linkType}
            custodianId={this.detailsInfo.id}
            irr={this.detailsInfo.irr}
            irrType={this.detailsInfo.irrType}
            valueMultiplier={this.isAssetMovedToDebt ? -1 : 1}
            category={this.props.category}
            onHoldingsExpanded={this.handleOverlayDismiss}
            showHoldingsBadge={this.shouldShowHoldingsBadge}
            ownership={this.detailsInfo.ownership}
            isSameAsHoldingsTotal={this.holdingsData.total === this.detailsValueInCurrency}
            showPoweredByCartaLogo={
              this.props.custodian.linkType === accountLinkingService.IN_HOUSE_OAUTH &&
              this.props.custodian.linkProviderId === "carta"
            }
          />
        );
      }

      return null;
    })();

    const getTabNameFromHoldingsSubType = holdingsSubType => {
      if (holdingsSubType === "other" || holdingsSubType === "other2") {
        return "other";
      } else if (holdingsSubType === "fixed income") {
        return "bond";
      } else if (holdingsSubType === "home") {
        return "real estate";
      } else {
        return holdingsSubType;
      }
    };

    const parentDetailsPanel = (
      <ParentDetailsPanel
        details={details}
        isReadOnly={this.isReadOnly()}
        custodianId={this.detailsInfo.id}
        parentId={this.props.custodian?.parentId}
        portfolioCurrency={this.props.currentPortfolio.currency}
        isAssetMovedToDebt={this.isAssetMovedToDebt}
        category={this.props.category}
        onHoldingsCollapsed={this.handleOverlayDismiss}
        showBadgeTip={
          this.detailsInfo.id === this.props.userPreferences.showRevertHoldingsTipForCustodianId &&
          this.props.userPreferences.isRevertHoldingsTipShown === false
        }
        detailsInfo={this.detailsInfo}
        onCustodianUpdate={this.handleCustodianUpdate}
        portfolio={this.props.currentPortfolio}
      />
    );

    const pvstDetailsPanel = (this.detailsInfo.rate
      ? !!this.detailsInfo.rate && details
      : this.detailsInfo?.valueTickerId === 171) && (
      <PVSTDetailsPanel
        details={details}
        isReadOnly={this.isReadOnly()}
        custodianId={this.detailsInfo.id}
        sectionId={this.detailsInfo.sectionId}
        parentId={this.props.custodian.parentId}
        portfolioCurrency={this.props.currentPortfolio.currency}
        isAssetMovedToDebt={this.isAssetMovedToDebt}
        category={this.props.category}
        onHoldingsCollapsed={this.handleOverlayDismiss}
        showBadgeTip={
          this.detailsInfo.id === this.props.userPreferences.showRevertHoldingsTipForCustodianId &&
          this.props.userPreferences.isRevertHoldingsTipShown === false
        }
        detailsInfo={this.detailsInfo}
        onCustodianUpdate={this.handleCustodianUpdate}
        portfolio={this.props.currentPortfolio}
        history={details?.rate}
        chartStartDate={this.getChartStartDate()}
        getCustodianChartData={this.getCustodianChartData}
        isLinkedAccount={this.isCustodianLinked()}
        isConvertibleNote={false}
        custodianValue={this.valueForCustodian()}
      />
    );

    const convertibleDetailsPanel = details && (
      <PVSTDetailsPanel
        details={details}
        isReadOnly={this.isReadOnly()}
        custodianId={this.detailsInfo.id}
        sectionId={this.detailsInfo.sectionId}
        parentId={this.props.custodian.parentId}
        portfolioCurrency={this.props.currentPortfolio.currency}
        isAssetMovedToDebt={this.isAssetMovedToDebt}
        category={this.props.category}
        onHoldingsCollapsed={this.handleOverlayDismiss}
        showBadgeTip={
          this.detailsInfo.id === this.props.userPreferences.showRevertHoldingsTipForCustodianId &&
          this.props.userPreferences.isRevertHoldingsTipShown === false
        }
        detailsInfo={this.detailsInfo}
        onCustodianUpdate={this.handleCustodianUpdate}
        portfolio={this.props.currentPortfolio}
        history={details?.rate}
        chartStartDate={this.getChartStartDate()}
        isLinkedAccount={this.isCustodianLinked()}
        isConvertibleNote={true}
        custodianValue={this.valueForCustodian()}
        getCustodianChartData={this.getCustodianChartData}
      />
    );

    var canShowDocuments = !this.props.wlClientContext === true || this.props.wlClientContext.collaborateDocument === 1;

    if (!this.props.wlClientContext === true) {
      canShowDocuments = this.props.siteConfig.showDocument !== "hide";
    }

    var canShowNotes = true;
    if (isAppInViewMode()) {
      const capabilities = JSON.parse(this.props.siteConfig.capability);
      const documentCapabilityAllowed =
        capabilities.includes(shareCapabilities.ALL) || capabilities.includes(shareCapabilities.DOCUMENT);
      canShowDocuments =
        this.props.siteConfig.showDocument !== "hide" && documentCapabilityAllowed && documents.length > 0;
      canShowNotes = documentCapabilityAllowed && !this.detailsInfo.note === false;
    }

    switch (this.category) {
      case category.ASSET:
        const assetTabs = [];
        if (this.props.custodian.linkType !== accountLinkingService.KUBERA_PORTFOLIO) {
          assetTabs.push({
            title: i18n.t("custodianDetails.reportingTabTitle"),
            key: detailsTabs.REPORTING,
            panelComponent: reportingPanel
          });
          if (this.detailsInfo.hidden !== 1) {
            assetTabs.push({
              title: i18n.t("custodianDetails.assortedTabTitle"),
              key: detailsTabs.ASSORTED,
              panelComponent: assortedFlagsPanel
            });
          }
        }
        if (canShowNotes === true) {
          assetTabs.push({
            title: i18n.t("custodianDetails.notesTabTitle"),
            key: detailsTabs.NOTES,
            panelComponent: notesPanel
          });
        }

        if (canShowDocuments) {
          assetTabs.push({
            title: i18n.t("custodianDetails.documentsTabTitle"),
            key: detailsTabs.DOCUMENTS,
            panelComponent: documentsPanel
          });
        }

        assetTabs.unshift({
          title: i18n.t("custodianDetails.returnsTabTitle"),
          key: detailsTabs.RETURNS,
          panelComponent: assetsReturnsPanel
        });

        if (this.showHistoryTab()) {
          assetTabs.unshift({
            title: i18n.t("custodianDetails.valueTabTitle"),
            key: detailsTabs.ASSET_VALUE,
            panelComponent: assetsValuePanel
          });
        }

        if (this.shouldShowHoldings() === true) {
          assetTabs.unshift({
            title: i18n.t("custodianDetails.holdingsTabTitle"),
            key: detailsTabs.HOLDINGS,
            panelComponent: holdingsPanel
          });
        }

        if (this.showHomeTab()) {
          assetTabs.unshift({ title: this.getHomeTitle(), key: detailsTabs.HOME, panelComponent: homePanel });
        }

        if (this.shouldShowParentCustodianTab()) {
          assetTabs.unshift({
            title: getTabNameFromHoldingsSubType(this.detailsInfo.holdingSubType),
            key: detailsTabs.PARENT_DETAILS,
            panelComponent: parentDetailsPanel
          });
        }

        if (this.shouldShowPVSTTab()) {
          assetTabs.unshift({
            title:
              this.detailsInfo?.holdingSubType && this.props.custodian.linkType === accountLinkingService.IN_HOUSE_OAUTH
                ? this.detailsInfo.holdingSubType.replace("_", " ")
                : i18n.t("privateStock"),
            key: detailsTabs.PVST_DETAILS,
            panelComponent: this.isConvertibleNote ? convertibleDetailsPanel : pvstDetailsPanel
          });
        }

        if (this.showNFTTab()) {
          assetTabs.unshift({ title: i18n.t("custodianDetails.nftTitle"), panelComponent: nftPanel });
        }

        if (
          this.isReadOnly() === false &&
          this.props.custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO &&
          this.props.portfolios.find(item => item.id === this.detailsInfo.linkProviderAccountId)
        ) {
          assetTabs.unshift({
            title: i18n.t("portfolio"),
            key: detailsTabs.LINKED_PORTFOLIO,
            panelComponent: linkedPortfolioPanel
          });
        }
        return assetTabs;
      case category.DEBT:
        const debtTabs = [
          {
            title: i18n.t("custodianDetails.debtHistoryTabTitle"),
            key: detailsTabs.DEBT_HISTORY,
            panelComponent: debtsHistoryPanel
          }
        ];
        if (this.props.custodian.linkType !== accountLinkingService.KUBERA_PORTFOLIO) {
          debtTabs.push({
            title: i18n.t("custodianDetails.ownershipTabTitle"),
            key: detailsTabs.OWNERSHIP,
            panelComponent: ownershipPanel
          });
        }
        if (canShowNotes === true) {
          debtTabs.push({
            title: i18n.t("custodianDetails.notesTabTitle"),
            key: detailsTabs.NOTES,
            panelComponent: notesPanel
          });
        }

        if (canShowDocuments) {
          debtTabs.push({
            title: i18n.t("custodianDetails.documentsTabTitle"),
            key: detailsTabs.DOCUMENTS,
            panelComponent: documentsPanel
          });
        }

        if (this.shouldShowHoldings() === true) {
          debtTabs.unshift({
            title: i18n.t("custodianDetails.holdingsTabTitle"),
            key: detailsTabs.HOLDINGS,
            panelComponent: holdingsPanel
          });
        }

        if (this.shouldShowParentCustodianTab()) {
          debtTabs.unshift({
            title: i18n.t("custodianDetails.parentDetailsTabTitle"),
            key: detailsTabs.PARENT_DETAILS,
            panelComponent: parentDetailsPanel
          });
        }
        if (this.shouldShowPVSTTab()) {
          debtTabs.unshift({
            title: i18n.t("privateStock"),
            key: detailsTabs.PVST_DETAILS,
            panelComponent: pvstDetailsPanel
          });
        }
        if (
          this.isReadOnly() === false &&
          this.props.custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO &&
          this.props.portfolios.find(item => item.id === this.detailsInfo.linkProviderAccountId)
        ) {
          debtTabs.unshift({
            title: i18n.t("portfolio"),
            key: detailsTabs.LINKED_PORTFOLIO,
            panelComponent: linkedPortfolioPanel
          });
        }
        return debtTabs;
      case category.INSURANCE:
        const insuranceTabs = [
          {
            title: i18n.t("custodianDetails.insuranceDetailsTabTitle"),
            key: detailsTabs.INSURANCE,
            panelComponent: insuranceDetailsPanel
          }
        ];

        if (!this.props.wlClientContext === true || this.props.wlClientContext.collaborateDocument === 1) {
          if ((this.isReadOnly() === false || documents.length > 0) && this.props.siteConfig.showDocument !== "hide") {
            insuranceTabs.push({
              title: i18n.t("custodianDetails.documentsTabTitle"),
              key: detailsTabs.DOCUMENTS,
              panelComponent: documentsPanel
            });
          }
        }
        return insuranceTabs;
      default:
        return [];
    }
  };

  showHomeTab() {
    const { linkType, valueTickerId, subType } = this.detailsInfo;
    const ticker = getTickerUsingId(valueTickerId);
    if (
      !this.shouldShowParentCustodianTab() &&
      !this.shouldShowPVSTTab() &&
      ([accountLinkingService.ZILLOW, accountLinkingService.CARS, accountLinkingService.DOMAINS].includes(linkType) ||
        [
          tickerTypes.STOCK,
          tickerTypes.FUND,
          tickerTypes.CRYPTO,
          tickerTypes.BOND,
          tickerTypes.DERIVATIVE,
          tickerTypes.INDEX
        ].includes(ticker.type) ||
        ticker.subType === tickerSubTypes.PRECIOUS_METALS ||
        subType === tickerSubTypes.HOME ||
        subType === tickerSubTypes.CARS ||
        subType === tickerSubTypes.DOMAINS)
    ) {
      return true;
    }

    return false;
  }

  showNFTTab() {
    const { extra, linkContainer } = this.detailsInfo;

    if (linkContainer === accountLinkingContainers.NFT && extra) {
      return true;
    }

    return false;
  }

  showHistoryTab() {
    return true;
  }

  shouldShowHoldings() {
    const { linkType, linkContainer } = this.detailsInfo;

    if (
      this.category !== category.ASSET &&
      linkType !== accountLinkingService.ZERION &&
      linkType !== accountLinkingService.IN_HOUSE_CRYPTO_API
    ) {
      return false;
    }

    // Don't show holdings for custodians that are created
    // due to expanded holdings
    if (this.shouldHideHomeTabIfInside || this.shouldShowParentCustodianTab() === true || this.shouldShowPVSTTab()) {
      return false;
    }

    if (
      linkType === accountLinkingService.IN_HOUSE_OAUTH ||
      isCryptoLinkingService(linkType) ||
      linkType === accountLinkingService.FLINKS_INVESTMENT ||
      (linkContainer === "investment" &&
        linkType !== accountLinkingService.SALTEDGE &&
        linkType !== accountLinkingService.SALTEDGE_EU)
    ) {
      return true;
    }

    return false;
  }

  get shouldHideHomeTabIfInside() {
    const ticker = getTickerUsingId(this.detailsInfo.valueTickerId);
    if (
      this.detailsInfo.hidden === 1 &&
      ![
        tickerTypes.STOCK,
        tickerTypes.FUND,
        tickerTypes.CRYPTO,
        tickerTypes.BOND,
        tickerTypes.DERIVATIVE,
        tickerTypes.INDEX
      ].includes(ticker.type)
    ) {
      return true;
    }

    return false;
  }

  shouldShowParentCustodianTab() {
    if (this.shouldHideHomeTabIfInside) {
      return false;
    }
    return (
      !this.props.custodian.parentId === false &&
      this.props.custodian.valueTickerId !== 171 &&
      !custodianSelector(store.getState(), this.props.custodian.parentId) === false
    );
  }

  get isConvertibleNote() {
    return holdingSubTypes.CONVERTIBLE_NOTE === this.detailsInfo.holdingSubType;
  }

  shouldShowPVSTTab() {
    return this.category === category.ASSET && (this.props.custodian.valueTickerId === 171 || this.isConvertibleNote);
  }

  isArchived() {
    return this.detailsInfo.tsArchive !== null;
  }

  isReadOnly() {
    return (
      this.isArchived() ||
      isAppInViewMode() === true ||
      this.props.maskAllValues === true ||
      this.props.currentPortfolio.write === 0
    );
  }

  get inHouseOauthHasAvailableCredit() {
    return (
      this.props.custodian?.linkType === accountLinkingService.IN_HOUSE_OAUTH && !!this.props.custodian.availableCredit
    );
  }

  showDetailsBadge() {
    return (
      this.props.custodian &&
      (!this.props.getUnfundedCommitmentForCustodian(this.props.custodian) === false ||
        this.inHouseOauthHasAvailableCredit)
    );
  }

  showPotentialTaxBadge(isCustodianMarkedAsTaxFree) {
    const unrealizedGain = this.props.getUnrealizedGainForACustodian(
      this.props.custodian,
      this.category,
      (this.props.details && this.props.details.holdings) || []
    );
    const potentialTax = Math.kuberaFloor(
      getEstimatedTaxOnUnrealizedGainsForCustodian(this.props.custodian, unrealizedGain)
    );
    return (
      !checkIfACustodianIsCash(this.detailsInfo) &&
      this.category === category.ASSET &&
      potentialTax > 0 &&
      !isCustodianMarkedAsTaxFree &&
      !this.props.custodian.parentId
    );
  }

  getDetailsBadgeString() {
    if (this.inHouseOauthHasAvailableCredit) {
      return (
        <span>
          {this.props.custodian.availableCredit}
          <br />
          <BadgeLabel>{i18n.t("unvested")}</BadgeLabel>
        </span>
      );
    }
    if (this.props.custodian && !this.props.custodian.cmtdCap === false) {
      const commitment = Math.kuberaFloor(this.props.getUnfundedCommitmentForCustodian(this.props.custodian));
      if (!commitment === false) {
        return (
          <span>
            {formatNumberWithCurrency(commitment, this.props.currentPortfolio.currency, true)}
            <br />
            <BadgeLabel>{i18n.t("unfunded")}</BadgeLabel>
          </span>
        );
      }
    }
    return "";
  }

  getPotentialTaxBadgeString() {
    const unrealizedGain = this.props.getUnrealizedGainForACustodian(
      this.props.custodian,
      this.category,
      (this.props.details && this.props.details.holdings) || []
    );
    const potentialTax = Math.kuberaFloor(
      getEstimatedTaxOnUnrealizedGainsForCustodian(this.props.custodian, unrealizedGain)
    );
    if (!potentialTax === false) {
      return (
        <span>
          {formatNumberWithCurrency(potentialTax, this.props.currentPortfolio.currency, true)}
          <br />
          <BadgeLabel>Est. Tax</BadgeLabel>
        </span>
      );
    }
  }

  handleTabChange(tabKey) {
    const tabs = this.getTabs();
    const tabIndex = tabs.findIndex(tab => tab.key === tabKey);
    if (tabIndex !== -1) {
      this.setTab(tabIndex);
    }
  }

  setTab(tabIndex) {
    const tabs = this.getTabs();
    const tab = tabs[tabIndex];
    if (tab) {
      CustodianDetailsComponentExports.show(
        this.props.history,
        this.props.location,
        this.props.custodianId,
        true,
        undefined,
        {
          hash: `${hashParams.TAB_INDEX}=${tab.key}`
        }
      );
    }
    this.setState({
      tabIndex
    });
  }

  editName() {
    if (this.nameRef.current !== null) {
      this.nameRef.current.edit();
    }
  }

  getChartTimeRangeMenuItems() {
    return [
      [
        { id: chartTimeRange.WEEKLY, label: "1 Week" },
        { id: chartTimeRange.MONTHLY, label: "1 Month" },
        { id: chartTimeRange.QUARTERLY, label: "3 Months" },
        { id: chartTimeRange.YEARLY, label: "1 Year" },
        { id: chartTimeRange.YTD, label: "YTD" },
        { id: chartTimeRange.ALL, label: "All-time" }
      ]
    ];
  }

  getCurrentlySelectedChartTimeRangeText() {
    const menuItems = this.getChartTimeRangeMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === this.props.selectedChartTimeRange) {
        return item.label;
      }
    }
  }

  getLabelDateString(date) {
    return `${date.getDate()} ${getMonthFromDate(date)} ${date.getFullYear()}`.toUpperCase();
  }

  getLineChartData(apiChartData) {
    if (!apiChartData === true || !apiChartData.chart === true) {
      return null;
    }

    const chartData = apiChartData.chart[this.props.selectedChartTimeRange];
    const chartDataPoints = chartData.data;
    if (chartDataPoints.length < MIN_CHART_DATA_POINTS) {
      return null;
    }

    const portfolioTicker = this.props.currentPortfolio.currency;
    const data = {
      labels: chartDataPoints.map((dataPoint, index) => {
        return this.getLabelDateString(getDateInKuberaFormat(dataPoint.date));
      }),
      datasets: [
        {
          data: chartDataPoints.map((dataPoint, index) => {
            let dataPointVal =
              index < chartDataPoints.length - 1 ? dataPoint.value : Math.kuberaFloor(this.valueForCustodian(false));
            return convertCurrency(dataPointVal, chartData.currency, portfolioTicker);
          })
        }
      ]
    };
    return data;
  }

  getPreviousDaysValue() {
    if (!this.state.custodianChartData === true || !this.state.custodianChartData.chart === true) {
      return null;
    }
    const weeklyData = this.state.custodianChartData.chart[chartTimeRange.WEEKLY];
    if (!weeklyData === true || weeklyData.data.length < 2) {
      return null;
    }
    return convertCurrency(
      weeklyData.data[weeklyData.data.length - 2].value,
      weeklyData.currency,
      this.props.currentPortfolio.currency
    );
  }

  handleChartTimeRangeSelectionButtonClick(e) {
    const menuItems = this.getChartTimeRangeMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === this.props.selectedChartTimeRange) {
        item.selected = true;
      }
    }

    const targetPosition = e.target.getBoundingClientRect();
    this.chartContextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      e.target
    );
  }

  handleChartContextMenuSelection(item) {
    this.props.updateUserPreferences({
      custodianValueChartTimeRange: item.id
    });
  }

  handleChartHoverSelection(index) {
    const dataPoints = this.state.custodianChartData.chart[this.props.selectedChartTimeRange].data;
    let hoverIndex = index;

    if (index === dataPoints.length - 1) {
      hoverIndex = null;
    }
    this.setState({ currentChartDataPointHoverIndex: hoverIndex });
  }

  getCurrentlySelectedChartDataPoint = () => {
    if (this.state.currentChartDataPointHoverIndex === null) {
      return null;
    }

    const dataPoints = this.state.custodianChartData.chart[this.props.selectedChartTimeRange].data;
    return dataPoints[this.state.currentChartDataPointHoverIndex];
  };

  get detailsValueInCurrency() {
    const valueCurrency =
      !this.detailsInfo.valueTickerId === true
        ? this.props.currentPortfolio.currency
        : getTickerUsingId(this.detailsInfo.valueTickerId).shortName;

    if (this.detailsInfo.valueTickerId === 171 && this.detailsInfo.rate) {
      const rateParsed = JSON.parse(this.detailsInfo.rate);
      const value = this.detailsInfo.value * rateParsed.p;
      return !isNaN(value)
        ? convertCurrency(value, getTickerUsingId(rateParsed.t).shortName, this.props.currentPortfolio.currency)
        : 0;
    }

    return convertCurrency(this.detailsInfo.value, valueCurrency, this.props.currentPortfolio.currency);
  }

  valueForCustodian = (considerChartSelection = true) => {
    const currentlySelectedChartDataPoint = this.getCurrentlySelectedChartDataPoint();

    if (considerChartSelection === true && !currentlySelectedChartDataPoint === false) {
      const chartData = this.state.custodianChartData.chart[this.props.selectedChartTimeRange];
      return convertCurrency(
        currentlySelectedChartDataPoint.value,
        chartData.currency,
        this.props.currentPortfolio.currency
      );
    } else {
      const useHoldingsTotal = true;
      // const useHoldingsTotal = [
      //   accountLinkingService.ZERION,
      //   accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
      //   accountLinkingService.IN_HOUSE_CRYPTO_API
      // ].includes(this.detailsInfo.linkType);

      return calcCustodianOwnershipValue(
        this.holdingsData.total && useHoldingsTotal === true ? this.holdingsData.total : this.detailsValueInCurrency,
        this.detailsInfo.ownership
      );
    }
  };

  getStartValueForCustodianForSelectedTimeRange() {
    if (
      !this.state.lineChartData === true ||
      !this.state.custodianChartData.chart[this.props.selectedChartTimeRange].data[0]
    ) {
      return null;
    }
    const timeRangeDataPoint = this.state.custodianChartData.chart[this.props.selectedChartTimeRange];
    return convertCurrency(
      timeRangeDataPoint.data[0].value,
      timeRangeDataPoint.currency,
      this.props.currentPortfolio.currency
    );
  }

  getChartStartDate() {
    if (!this.state.custodianChartData === true || !this.state.custodianChartData.chart === true) {
      return null;
    }
    const chartData = this.state.custodianChartData.chart[chartTimeRange.ALL];
    return chartData && chartData.data[0] && chartData.data[0].date;
  }

  shouldShowChartChange(lineChartData) {
    if (!lineChartData === true || !this.state.currentChartDataPointHoverIndex === true) {
      return false;
    }
    const dataPoints = lineChartData.datasets[0].data;
    var currentDataPoint = null;

    for (const dataPoint of dataPoints) {
      if (!currentDataPoint === true) {
        currentDataPoint = dataPoint;
      } else if (currentDataPoint !== dataPoint) {
        return true;
      }
    }
    return false;
  }

  handleOverrideTooltipDismiss = () => {
    this.props.updateUserPreferences({
      isOverrideTooltipShow: true
    });
  };

  handleResumeAutoUpdateDismiss = () => {
    this.setState({
      showResumeAutoUpdate: false
    });
  };

  handleResumeAutoUpdate = async () => {
    this.handleResumeAutoUpdateDismiss();
    if (this.holdingsData.holdingsWithUnMatchValue) {
      const custodiansToBulkUpdate = Object.keys(this.holdingsData.holdingsWithUnMatchValue).map(holdingId => {
        return { id: holdingId, value: this.holdingsData.holdingsWithUnMatchValue[holdingId] };
      });

      this.setState({
        isRefreshPending: true
      });
      await this.props.updateCustodianBulk({
        list: custodiansToBulkUpdate
      });
      this.props.fetchDetails(this.props.custodianId);
      this.props.refreshCustodian(
        this.props.custodianId,
        () => {
          this.setState({
            isRefreshPending: false
          });
        },
        undefined,
        true,
        undefined,
        { force: 1 }
      );
    } else {
      this.props.updateCustodian(
        false,
        this.detailsInfo.id,
        {
          value: this.detailsInfo.sourceValue
        },
        true
      );
    }
  };

  handleManualOverideDismiss = () => {
    this.setState({
      showResumeAutoUpdate: true
    });
  };

  getReconnectDialogDescription(linkType) {
    if (linkType === accountLinkingService.ZILLOW) {
      return i18n.t("reconnectDialog.homes.description");
    } else if (linkType === accountLinkingService.CARS) {
      return i18n.t("reconnectDialog.cars.description");
    } else if (linkType === accountLinkingService.DOMAINS) {
      return i18n.t("reconnectDialog.domains.description");
    } else {
      return i18n.t("reconnectDialog.description");
    }
  }

  render() {
    const isPending = this.props.isFetchPending;
    const details = this.props.details;
    const error = this.props.fetchError;
    const dialogWidth = this.category === category.ASSET ? 970 : 650;
    const archiveOrRemove = isCustodianAddedToday(this.detailsInfo.id) ? "Remove" : "Archive";
    const isCustodianMarkedAsTaxFree = this.props.custodianTaxType === custodianTaxTypes.TAX_FREE;
    window.renderlog("Render: CustodianDetailsComponent");

    if (
      (!this.props.custodian && !details && isPending === true) ||
      this.detailsInfo.id !== this.props.custodianId
      // ||
      // this.props.isCustodianChartFetchPending
    ) {
      return (
        <DetailsDialogOverlay onDismiss={this.handleOverlayDismiss}>
          <DetailsDialog width={dialogWidth}>
            <DetailsContainer>
              <Loader />
            </DetailsContainer>
          </DetailsDialog>
        </DetailsDialogOverlay>
      );
    }

    if (error !== null && error !== false) {
      return (
        <DetailsDialogOverlay onDismiss={this.handleOverlayDismiss}>
          <DetailsDialog width={dialogWidth}>
            <DetailsContainer>
              <Loader errorMessage={error.errorMessage} onRefresh={this.handleLoaderRefreshClick} />
            </DetailsContainer>
          </DetailsDialog>
        </DetailsDialogOverlay>
      );
    }

    if (this.props.currentPortfolio) {
      const isRequestPending = this.state.isRequestPending;
      const isRefreshPending = this.state.isRefreshPending;
      const isReadOnly = this.isReadOnly();
      const tabs = this.getTabs();
      const isLinkedAccount = this.isCustodianLinked();
      const portfolioCurrency = this.props.currentPortfolio.currency;
      const value = this.valueForCustodian();
      const showUnlinkAllConnectionsDialog = this.state.showUnlinkAllConnectionsDialog;

      var linkedAccountDescription = null;
      if (!this.props.custodian === false) {
        const { linkType, linkProviderName, linkAccountName, linkAccountMask } = this.props.custodian;
        linkedAccountDescription = GridRowData.linkedAccountDescription({
          linkType,
          linkProviderName,
          linkAccountName,
          linkAccountMask
        });
      }
      const toolTip = linkedAccountDescription ? `Connected: ${linkedAccountDescription}` : null;

      const lineChartData = this.state.lineChartData;
      const shouldShowValueChange = this.shouldShowChartChange(lineChartData);
      const chartStartValue = this.getStartValueForCustodianForSelectedTimeRange();

      return (
        <DetailsDialogOverlay ref={this.detailsOverlayRef} onDismiss={this.handleOverlayDismiss}>
          <DetailsDialog width={dialogWidth}>
            <DetailsContainer isCustodianMarkedAsTaxFree={isCustodianMarkedAsTaxFree}>
              {(isRequestPending === true || isRefreshPending) && <DetailsLoader />}
              <TitleContainer>
                <TitleDetailsContainer>
                  <NameContainer onClick={this.editName}>
                    <Name
                      type="text"
                      ref={this.nameRef}
                      value={this.detailsInfo.name}
                      onChange={this.handleNameChange}
                      disabled={isReadOnly === true || !this.props.details}
                    />
                    {isLinkedAccount === true && <LinkedAccountIcon title={toolTip} />}
                  </NameContainer>
                  <ValueAndOptionsContainer>
                    <ValueContainer>
                      <Value
                        data-cy="custodianDetailTotal"
                        maxLongFormatValue={99999}
                        value={this.isAssetMovedToDebt ? -1 * value : value}
                        roundDown={true}
                        currency={portfolioCurrency}
                        abbreviate={false}
                        fontSize={22}
                        height="27px"
                        useScrollAnimation
                      />
                      {shouldShowValueChange === true && (
                        <ValueChangeLabel
                          currency={portfolioCurrency}
                          startValue={chartStartValue}
                          endValue={Math.floor(value)}
                          isAnimationAllowed={false}
                        />
                      )}
                    </ValueContainer>
                    <AllBadgesContainer>
                      {details && this.showPotentialTaxBadge(isCustodianMarkedAsTaxFree) === true && (
                        <BadgeContainer>
                          <BadgeDetails>{this.getPotentialTaxBadgeString()}</BadgeDetails>
                        </BadgeContainer>
                      )}
                      {details && this.showDetailsBadge() === true && (
                        <BadgeContainer>
                          <BadgeDetails>{this.getDetailsBadgeString()}</BadgeDetails>
                        </BadgeContainer>
                      )}
                      {this.props.custodian.linkType === accountLinkingService.IN_HOUSE_OAUTH &&
                        this.props.custodian.linkProviderId === "carta" && (
                          <BadgeContainer marginLeft="10px">
                            <PoweredByCartaLogoComponent />
                          </BadgeContainer>
                        )}
                    </AllBadgesContainer>
                    {this.props.accountSubscriptionIsActive &&
                    shouldShowLinkingErrorForCustodian(this.props.custodian) === true ? (
                      <LinkingErrorButton onClick={this.handleOptionsClick}>
                        <LinkAccountErrorIcon />
                      </LinkingErrorButton>
                    ) : (
                      this.isReadOnly() === false &&
                      this.props.maskAllValues === false && (
                        <OptionsWrapper>
                          {this.showManualOverrideBadge && (
                            <ManualOverride>
                              <ManualOverideBadge>
                                <ManualOverideBadgeTxt>MANUALLY OVERRIDDEN VALUE</ManualOverideBadgeTxt>
                                <CloseBtn onClick={this.handleManualOverideDismiss}>
                                  <CloseIconComponent />
                                </CloseBtn>
                              </ManualOverideBadge>
                              {this.props.userPreferences.isOverrideTooltipShow !== true && (
                                <ManualOverideToolTip>
                                  <ManualOverideToolTipTxt>
                                    The asset value is manually overridden. Click on x to resume auto-update.
                                  </ManualOverideToolTipTxt>
                                  <GotItBtn onClick={this.handleOverrideTooltipDismiss}>GOT IT</GotItBtn>
                                </ManualOverideToolTip>
                              )}
                            </ManualOverride>
                          )}
                          <OptionsButton
                            data-cy="detailsOptionsButton"
                            onClick={this.handleOptionsClick}
                            isShowingContextMenu={this.state.isShowingContextMenu}
                          />
                        </OptionsWrapper>
                      )
                    )}
                  </ValueAndOptionsContainer>
                  <CustodianDetailsDesc
                    data-cy="detailsDescription"
                    readOnly={isReadOnly}
                    value={this.detailsInfo.description}
                    placeholder={isReadOnly ? "" : i18n.t("custodianDetails.descriptionPlaceholder")}
                    onChange={this.handleDescriptionChange}
                    onKeyDown={this.handleDescriptionKeyDown}
                  />
                </TitleDetailsContainer>
              </TitleContainer>
              <DetailsTabs
                selectedTabClassName="is-selected"
                selectedTabPanelClassName="is-selected"
                selectedIndex={this.state.tabIndex}
                onSelect={this.setTab}
              >
                <DetailsTabList>
                  {tabs.map((tab, index) => (
                    <DetailsTab data-cy={"detailsTab" + index} key={index}>
                      {tab.title}
                      {tab.key === detailsTabs.ASSORTED &&
                        !this.props.custodian.cmtdCap === false &&
                        this.props.userPreferences.isFundScheduleTipShown === false && <FundScheduleRedDot />}
                      {tab.key === detailsTabs.REPORTING &&
                        this.props.userPreferences.isReportingTabTipShown === false &&
                        (this.props.custodian.isManual === true || this.props.custodian.valueTickerId === 171) && (
                          <ReportingTabRedDot />
                        )}
                    </DetailsTab>
                  ))}
                </DetailsTabList>
                {tabs.map((tab, index) => (
                  <DetailsTabPanel key={`detailsTabPanel${index}`}>{tab.panelComponent}</DetailsTabPanel>
                ))}
                {this.isTabsLoading && (
                  <DetailsTabLoader>
                    <Loader />
                  </DetailsTabLoader>
                )}
              </DetailsTabs>
            </DetailsContainer>
            <ContextMenu
              ref={this.contextMenuRef}
              width={233}
              onSelection={this.handleContextMenuSelection}
              onDismiss={this.handleContextMenuDismiss}
            />
            <ContextMenu
              ref={this.chartContextMenuRef}
              width={150}
              onSelection={this.handleChartContextMenuSelection}
            />
            {showUnlinkAllConnectionsDialog === true && (
              <ConfirmationDialog
                canUserDismiss={true}
                title={
                  this.props.custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO
                    ? `${i18n.t("unlinkPortfolio")}`
                    : i18n.t("unlinkAllConnectionsDialog.title")
                }
                description={
                  this.props.custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO
                    ? i18n.t("unlinkPortfolioDescription")
                    : this.state.unlinkDialogCount > 1
                    ? i18n.t("unlinkAllConnectionsDialog.multiple")
                    : i18n.t("unlinkAllConnectionsDialog.single")
                }
                positiveButtonTitle={
                  this.props.custodian.linkType === accountLinkingService.KUBERA_PORTFOLIO
                    ? i18n.t("unlink")
                    : this.state.unlinkDialogCount > 1
                    ? i18n.t("unlinkAllConnectionsDialog.primaryButton") + ` ${this.state.unlinkDialogCount} rows`
                    : i18n.t("unlinkAllConnectionsDialog.primaryButton")
                }
                negativeButtonTitle={i18n.t("cancel")}
                onDismiss={this.handleUnlinkAllConnectionsDialogOnDismiss}
                handlePositiveButtonClick={this.handleUnlinkAllConnectionsDialogPositiveButtonClick}
                handleNegativeButtonClick={this.handleUnlinkAllConnectionsDialogOnDismiss}
              />
            )}
            {this.state.showResumeAutoUpdate === true && (
              <ConfirmationDialog
                canUserDismiss={true}
                title={i18n.t("custodianDetails.dialogResumeAutoUpdateTitle")}
                description={i18n.t("custodianDetails.dialogResumeAutoUpdateDesc")}
                positiveButtonTitle={i18n.t("custodianDetails.dialogResumeAutoUpdatePositiveBtn")}
                negativeButtonTitle={i18n.t("custodianDetails.dialogResumeAutoUpdateNegativeBtn")}
                onDismiss={this.handleResumeAutoUpdateDismiss}
                handlePositiveButtonClick={this.handleResumeAutoUpdate}
                handleNegativeButtonClick={this.handleResumeAutoUpdateDismiss}
              />
            )}
            {this.state.showReconnectDialog === true && (
              <ConfirmationDialog
                canUserDismiss={true}
                title={i18n.t("reconnectDialog.title")}
                description={this.getReconnectDialogDescription(this.detailsInfo.linkType)}
                positiveButtonTitle={
                  this.detailsInfo.linkType === accountLinkingService.ZILLOW ||
                  this.detailsInfo.linkType === accountLinkingService.CARS ||
                  this.detailsInfo.linkType === accountLinkingService.DOMAINS
                    ? i18n.t("ok")
                    : i18n.t("reconnect")
                }
                negativeButtonTitle={
                  this.detailsInfo.linkType === accountLinkingService.ZILLOW ||
                  this.detailsInfo.linkType === accountLinkingService.CARS ||
                  this.detailsInfo.linkType === accountLinkingService.DOMAINS
                    ? null
                    : i18n.t("updateValues")
                }
                tertiaryButtonTitle={
                  this.detailsInfo.linkType === accountLinkingService.ZILLOW ||
                  this.detailsInfo.linkType === accountLinkingService.CARS ||
                  this.detailsInfo.linkType === accountLinkingService.DOMAINS
                    ? null
                    : i18n.t("goBackLink")
                }
                onDismiss={this.handleUnlinkAllConnectionsDialogOnDismiss}
                handlePositiveButtonClick={this.handleReconnectDialogPositiveButtonClick}
                handleNegativeButtonClick={this.handleReconnectDialogNegativeButtonClick}
                handleTertiaryButtonClick={this.handleReconnectDialogDismissButtonClick}
              />
            )}
            {this.state.deleteType === 1 && (
              <ConfirmationDialog
                title={i18n
                  .t("removeRowTitle")
                  .replace(/%category%/, this.category)
                  .replace("Remove", archiveOrRemove)}
                description={i18n
                  .t("removeCompleteDesc")
                  .replace(/%custodian_name%/, this.state.rowNameToDelete)
                  .replace("remove", archiveOrRemove.toLowerCase())}
                positiveButtonTitle={i18n.t("remove").replace("Remove", archiveOrRemove)}
                negativeButtonTitle={i18n.t("cancel")}
                handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
                handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
              />
            )}
            {this.state.deleteType === 2 && (
              <ConfirmationDialog
                title={i18n
                  .t("removeRowTitle")
                  .replace(/%category%/, this.category)
                  .replace("Remove", archiveOrRemove)}
                description={i18n
                  .t("removeLinkedDesc")
                  .replace(/%custodian_name%/, this.state.rowNameToDelete)
                  .replace("remove", archiveOrRemove.toLowerCase())}
                positiveButtonTitle={i18n.t("remove").replace("Remove", archiveOrRemove)}
                negativeButtonTitle={i18n.t("cancel")}
                handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
                handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
              />
            )}
            {this.state.deleteType === 3 && (
              <ConfirmationDialog
                title={
                  i18n
                    .t("removeRows")
                    .replace(/%s1%/, this.state.numberOfRowsToDelete)
                    .replace("Remove", archiveOrRemove) + "?"
                }
                description={i18n
                  .t("removeHoldingRow.description")
                  .replace(/%s1%/, this.state.numberOfRowsToDelete)
                  .replace(/%s2%/, this.state.rowNameToDelete)
                  .replace("remove", archiveOrRemove.toLowerCase())}
                positiveButtonTitle={i18n
                  .t("removeRows")
                  .replace(/%s1%/, this.state.numberOfRowsToDelete)
                  .replace("Remove", archiveOrRemove)}
                negativeButtonTitle={i18n.t("cancel")}
                handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
                handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
              />
            )}
            {this.state.deleteType === 4 && (
              <ConfirmationDialog
                title={i18n.t("removeLinkedPortfolioRows").replace("Remove", archiveOrRemove)}
                description={i18n
                  .t("removeLinkedPortfolioRows.description")
                  .replace("remove", archiveOrRemove.toLowerCase())}
                positiveButtonTitle={i18n.t("remove").replace("Remove", archiveOrRemove)}
                negativeButtonTitle={i18n.t("cancel")}
                handleNegativeButtonClick={this.handleDeleteConfirmNegativeButtonClick}
                handlePositiveButtonClick={this.handleDeleteConfirmPositiveButtonClick}
              />
            )}
          </DetailsDialog>
        </DetailsDialogOverlay>
      );
    }

    return null;
  }
}

const mapStateToProps = (state, props) => ({
  isFetchPending: custodianDetailsIsPendingSelector(state),
  fetchError: custodianDetailsErrorSelector(state),
  details: custodianDetailsSelector(state),
  currentPortfolio: currentPortfolioSelector(state),
  portfolios: portfoliosSelector(state),
  custodian: custodianSelector(state, props.custodianId),
  accountCurrentTs: accountCurrentTsSelector(state),
  accountEndTs: accountEndTsSelector(state),
  accountGraceTs: accountGraceTsSelector(state),
  accountStartTs: accountStartTsSelector(state),
  accountSubscriptionStatus: accountSubscriptionStatusSelector(state),
  accountSubscriptionIsActive: accountSubscriptionIsActiveSelector(state),
  userPreferences: userPreferencesSelector(state),
  selectedChartTimeRange: getCustodianValueChartTimeRange(state),
  wlClientContext: wlClientContextSelector(state),
  siteConfig: siteConfigSelector(state),
  isReadOnlyWlClient: isReadOnlyWlClient(state),
  maskAllValues: userMaskAllValuesSelector(state),
  getUnrealizedGainForACustodian: getUnrealizedGainForACustodian.bind(state),
  getUnfundedCommitmentForCustodian: getUnfundedCommitmentForCustodian.bind(state),
  custodianTaxType: getTaxableTypeForCustodian(state),
  previousDetailsState: previousDetailsStateSelector(state),
  custodianDetailsScrollTop: custodianDetailsScrollTopSelector(state),
  chartDataForCustodian: custodianChartDataSelector(state),
  isCustodianChartFetchPending: custodianChartIsPendingSelector(state)
});

const mapDispatchToProps = {
  updateCustodian: updateCustodian,
  updateCustodianDetails: updateCustodianDetailsAction,
  fetchDetails: fetchCustodianDetails,
  fetchDetailsHistory: fetchCustodianDetailsHistory,
  updateDashboard: updateDashboardAction,
  archiveCustodian: archiveCustodian,
  deleteCustodian: deleteCustodian,
  unlinkAccountWithCustodian: unlinkAccountWithCustodian,
  unlinkAllConnectedCustodians: unlinkAllConnectedCustodians,
  unarchiveCustodian: unarchiveCustodian,
  deleteCustodianPermanently: deleteCustodianPermanently,
  updateUserPreferences: updateUserPreferences,
  updateCustodianOnDashboard: updateCustodianOnDashboard,
  refreshCustodian: refreshCustodian,
  getCustodianChart: getCustodianChart,
  updateParentCustodianValueWithHoldingsTotal: updateParentCustodianValueWithHoldingsTotal,
  updateCustodianInBulkAction: updateCustodianInBulkAction,
  showToastTip: showToastTip,
  dismissToast: dismissToastAction,
  updateCustodianBulk: updateCustodianBulk,
  getConnectivityCenterData: getConnectivityCenterData,
  fetchCustodianDetailsSuccessAction: fetchCustodianDetailsSuccessAction,
  setInnerDetailsStateAction: setInnerDetailsStateAction,
  setCurrentStateAction: setCurrentStateAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustodianDetailsComponent));
